import { AppsViewType } from "../constants/ActionTypes";
import {
  favoritesIcon,
  personalIcon,
  companyIcon
} from "../components/apps/common/TabIcons";
import { defineMessages } from "react-intl";

import { commonMessages } from "../translations/commonMessages";

const messages = defineMessages({
  frequents: {
    id: "frequents",
    defaultMessage: `Frequents`
  },
  companyTabIcon: {
    id: "companyTabIcon",
    defaultMessage: `Company Tab Icon`
  }
});

export const getMenuItems = (customTabs, handler) => {
  return (customTabs || []).map(tab => {
    return {
      id: `${tab.id}`, //menuItem awaits string
      text: tab.name,
      onClick: event => {
        handler(tab.id);
      }
    };
  });
};

export const computeTabs = ({
  userInitials,
  showPersonalTab,
  intl,
  customTabs,
  onCustomTabChange,
  companyText,
  customTabsEnabled
}) => {
  const frequentsText = intl.formatMessage(messages.frequents);
  const personalText = intl.formatMessage(commonMessages.personal);

  let tabs = [
    {
      text: frequentsText,
      type: AppsViewType.FAVORITE_ONLY,
      icon: favoritesIcon()
    },
    {
      text: personalText,
      type: AppsViewType.PERSONAL,
      icon: personalIcon(userInitials)
    },
    {
      text: companyText,
      type: AppsViewType.COMPANY,
      icon: companyIcon(intl.formatMessage(messages.companyTabIcon)),
      menuItems:
        (customTabsEnabled && getMenuItems(customTabs, onCustomTabChange)) || []
    }
  ];

  if (!showPersonalTab) {
    tabs = tabs.filter(tab => tab.type !== AppsViewType.PERSONAL);
  }

  return tabs;
};
