import { omit, filter, reduce } from "ramda";
import {
  computeSearchKeywords,
  normalizeId,
  normalizeIcons,
  normalizeParams,
  normalizeName
} from "./commonNormalizer";

export const extractFrequents = apps => {
  return reduce(
    (acc, app) => {
      acc[normalizeId(app)] = app.frequencyScore;
      return acc;
    },
    {},
    filter(app => app.frequencyScore, apps)
  );
};

export const normalizeApp = app => {
  return omit(["_id"], {
    ...app,
    id: normalizeId(app),
    icons: normalizeIcons(app),
    name: normalizeName(app),
    parameters: normalizeParams(app)
  });
};

export const normalizeApps = (apps, commonWords) => {
  return reduce(
    (acc, app) => {
      acc[normalizeId(app)] = omit(["_id", "frequencyScore"], {
        ...app,
        id: normalizeId(app),
        searchKeywords: computeSearchKeywords(app, commonWords),
        icons: normalizeIcons(app),
        name: normalizeName(app)
      });
      return acc;
    },
    {},
    apps
  );
};
