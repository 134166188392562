import * as navConsts from "../constants/NavigationConstants";
import { NAVIGATE_TO, NAVIGATE_TO_RESET } from "../constants/ActionTypes";

export const gotoAppEdit = appId => ({
  type: NAVIGATE_TO,
  path: `${navConsts.ROOT_PATH}/apps/edit/${appId}`
});

export const gotoApps = () => ({
  type: NAVIGATE_TO,
  path: navConsts.ROOT_PATH
});

export const resetRedirectPath = () => ({
  type: NAVIGATE_TO_RESET
});

export const gotoAppStore = () => ({
  type: NAVIGATE_TO,
  path: navConsts.APP_STORE_PATH
});

export const openExtensionInstall = () => (dispatch, getState) => {
  const state = getState();
  window.open && window.open(state.extension.browserExtensionUrl, "_blank");
};
