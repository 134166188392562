import { defineMessages } from "react-intl";
import stopwords from "stopwords-json";

import * as types from "../constants/ActionTypes";
import * as appsServiceApi from "./../api/appsServiceApi.js";
import topConnectors from "../store/topConnectors";
import {
  normalizeConnectors,
  normalizeAllConnectors
} from "../utils/normalize/connectorNormalizer";
import { getCommonWords } from "../utils/normalize/commonNormalizer";

const messages = defineMessages({
  fetchConnectorsFailed: {
    id: "fetchConnectorsFailed",
    defaultMessage: `Failed to fetch available connectors. Please try again later.`
  }
});

const computeTopConnectors = connectors => {
  return connectors
    .filter(connector => topConnectors[connector.id])
    .sort((a, b) => topConnectors[a.id] - topConnectors[b.id]);
};

const sortByName = (a, b) => a.name.localeCompare(b.name);

export const fetchConnectors = () => (dispatch, getState) => {
  dispatch({
    type: types.FETCH_CONNECTORS_REQUEST
  });

  let apiPromise;
  const commonWords = getCommonWords(stopwords, getState().user.locale);
  if (getState().featureFlags.allConnectorsStore) {
    apiPromise = appsServiceApi
      .fetchAllConnectors()
      .then(connectors => normalizeAllConnectors(connectors, commonWords));
  } else {
    apiPromise = appsServiceApi
      .fetchFormBasedConnectors()
      .then(connectors => normalizeConnectors(connectors, commonWords));
  }

  return apiPromise.then(
    connectors => {
      const sortedConnectors = connectors.sort(sortByName);

      dispatch({
        type: types.FETCH_CONNECTORS_RECEIVED,
        connectors: sortedConnectors,
        topConnectors: computeTopConnectors(sortedConnectors)
      });
    },
    error => {
      dispatch({
        type: types.FETCH_CONNECTORS_ERROR,
        messageDescriptor: messages.fetchConnectorsFailed
      });
    }
  );
};
