import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl, defineMessages } from "react-intl";
import { LinkButton, Loading } from "@onelogin/react-components";
import { withRouter } from "react-router-dom";

import SearchField from "../../SearchField";
import Message from "../../Message";
import KeyboardNavigator from "../KeyboardNavigator";
import TabsSwitcher from "../common/TabsSwitcher";
import StoreAppsList from "./StoreAppsList";
import StoreCreateAppRow from "./StoreCreateAppRow";
import FooterNavigation from "../common/FooterNavigation";
import PortalRedirect from "../../PortalRedirect";
import { ROOT_PATH } from "../../../constants/NavigationConstants";

import "./StoreView.css";
import ErrorBoundary from "../../ErrorBoundary";
import ResponsiveBlock from "../../ResponsiveBlock";

import { fetchConnectors } from "../../../actions/appStoreActions";
import { fetchGenericConnector } from "../../../actions/connectorsActions";
import { select as selectConnectors } from "../../../selectors/connectorsSelector";
import { searchableAndSelectable } from "../hoc/searchableAndSelectable";
import { getIsNarrowView } from "../../../selectors/environmentSelectors";

const MAX_CONNECTORS_TO_SHOW = 50;
const messages = defineMessages({
  storeSearchPlaceholder: {
    id: "storeSearchPlaceholder",
    defaultMessage: `Search for an app to add`
  },
  returnToPortal: {
    id: "returnToPortal",
    defaultMessage: `Return to Portal`
  },
  topAppsTab: {
    id: "topAppsTab",
    defaultMessage: `Top 10 Apps`
  },
  doneBrowsing: {
    id: "doneBrowsing",
    defaultMessage: `Done browsing?`
  },
  failedFetchConnectors: {
    id: "failedFetchConnectors",
    defaultMessage: `Failed to load list of applications!`
  },
  thereAreMoreResults: {
    id: "thereAreMoreResults",
    defaultMessage: `Showing only {matchesCount} matches. Use a more specific search term, please.`
  }
});

export class StoreView extends Component {
  componentDidMount() {
    this.props.fetchConnectors();
    this.props.fetchGenericConnector();
  }

  setViewType(viewType) {
    //NOTE: not neccessery to set as we have only 1 type now
  }

  onAppsClick = () => {
    this.props.history.push(ROOT_PATH);
  };

  gotoAddApp = connectorId => {
    this.props.history.push(`${ROOT_PATH}/apps/add/${connectorId}`);
  };

  onCreateAppClick = async () => {
    const { searchTerm, genericConnectorId, history } = this.props;
    history.push(`${ROOT_PATH}/apps/add/${genericConnectorId}/${searchTerm}`);
  };

  render() {
    const {
      connectors,
      connectorsCount,
      loading,
      error,
      searchTerm,
      onSearchTermChange,
      onSelectedIndexChange,
      isTouchDevice,
      keysDisabled,
      isNarrowView,
      blurSearchField,
      setSearchFocused,
      searchShouldHaveFocus,
      leaveSearchFieldIfPossible,
      focusEl,
      selectFirstApp,
      resetSearch,
      selectedIndex,
      redirectPath,
      intl,
      genericConnectorId
    } = this.props;

    if (redirectPath) {
      return <PortalRedirect path={redirectPath} />;
    }

    if (loading && connectors.length === 0) {
      return <Loading />;
    }

    if (error) {
      return (
        <Message msg={intl.formatMessage(messages.failedFetchConnectors)} />
      );
    }

    const hasAllMatches = connectorsCount <= MAX_CONNECTORS_TO_SHOW;

    return (
      <div id="store-view-container">
        {isTouchDevice ? null : (
          <KeyboardNavigator
            columnsCount={1}
            selectedIndex={selectedIndex}
            itemsCount={connectors.length}
            handlerDisabled={keysDisabled || searchShouldHaveFocus}
            onSelectionChange={onSelectedIndexChange}
            resetSearch={resetSearch}
            setSearchFocused={setSearchFocused}
          />
        )}
        <div className="search-and-tabs-wrap">
          <ErrorBoundary>
            <SearchField
              onChange={onSearchTermChange}
              searchTerm={searchTerm}
              isTouchDevice={isTouchDevice}
              leaveSearchFieldIfPossible={leaveSearchFieldIfPossible}
              onBlur={blurSearchField}
              onSearchEnter={selectFirstApp}
              shouldHaveFocus={searchShouldHaveFocus}
              focusEl={focusEl}
              placeholder={intl.formatMessage(messages.storeSearchPlaceholder)}
              onFocus={setSearchFocused}
            />
          </ErrorBoundary>
          {isNarrowView ? null : (
            <ErrorBoundary>
              <ResponsiveBlock>
                <TabsSwitcher
                  tabs={
                    searchTerm
                      ? []
                      : [
                          {
                            text: intl.formatMessage(messages.topAppsTab),
                            type: "TOP_APPS_VIEW",
                            icon: () => null,
                            tabIndex: -1
                          }
                        ]
                  }
                  link={{
                    text: intl.formatMessage(messages.returnToPortal),
                    onClick: this.onAppsClick
                  }}
                  onTabChange={this.setViewType}
                  activeTabType="TOP_APPS_VIEW"
                  showMobileView={false}
                  isTouchDevice={false}
                />
              </ResponsiveBlock>
            </ErrorBoundary>
          )}
        </div>
        <div className="scrollable-content">
          <div className="content store-container">
            <ErrorBoundary>
              <ResponsiveBlock noPaddings>
                <StoreAppsList
                  connectors={connectors}
                  searchTerm={searchTerm}
                  selectedIndex={selectedIndex}
                  onAddAppClick={this.gotoAddApp}
                  onSelectedIndexChange={onSelectedIndexChange}
                  focusEl={focusEl}
                />
                {searchTerm && hasAllMatches && genericConnectorId && (
                  <ResponsiveBlock>
                    <StoreCreateAppRow
                      onClick={this.onCreateAppClick}
                      intl={intl}
                    />
                  </ResponsiveBlock>
                )}
                {searchTerm && !hasAllMatches && (
                  <Message
                    msg={intl.formatMessage(messages.thereAreMoreResults, {
                      matchesCount: MAX_CONNECTORS_TO_SHOW
                    })}
                  />
                )}
                {!isNarrowView && (
                  <FooterNavigation
                    text={intl.formatMessage(messages.doneBrowsing)}
                    link={
                      <LinkButton onClick={this.onAppsClick}>
                        {intl.formatMessage(messages.returnToPortal)}
                      </LinkButton>
                    }
                  />
                )}
              </ResponsiveBlock>
            </ErrorBoundary>
          </div>
        </div>
      </div>
    );
  }
}

StoreView.propTypes = {
  connectors: PropTypes.array,
  connectorsCount: PropTypes.number,
  loading: PropTypes.bool,
  error: PropTypes.object,
  searchTerm: PropTypes.string,
  redirectPath: PropTypes.string,
  fetchConnectors: PropTypes.func.isRequired,
  fetchGenericConnector: PropTypes.func.isRequired,
  genericConnectorId: PropTypes.number.isRequired,
  isNarrowView: PropTypes.bool.isRequired,
  onSearchTermChange: PropTypes.func.isRequired,
  onSelectedIndexChange: PropTypes.func.isRequired,
  selectFirstApp: PropTypes.func.isRequired,
  blurSearchField: PropTypes.func.isRequired,
  setSearchFocused: PropTypes.func.isRequired,
  leaveSearchFieldIfPossible: PropTypes.func.isRequired,
  focusEl: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired,
  resetSelectedIndex: PropTypes.func.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  keysDisabled: PropTypes.bool.isRequired,
  searchShouldHaveFocus: PropTypes.bool,
  isTouchDevice: PropTypes.bool.isRequired
};

const mapStateToProps = (
  { appStore, environment, navigator, connectors },
  ownProps
) => {
  const selectedConnnectors = selectConnectors(
    appStore.connectors,
    appStore.topConnectors,
    ownProps.searchTerm
  );
  return {
    connectors: selectedConnnectors.slice(0, MAX_CONNECTORS_TO_SHOW),
    connectorsCount: selectedConnnectors.length,
    loading: appStore.loading,
    error: appStore.error,
    activeViewType: appStore.activeViewType,
    isNarrowView: getIsNarrowView(environment),
    isTouchDevice: environment.isTouchDevice,
    redirectPath: navigator.redirectPath,
    genericConnectorId: connectors.genericConnector.id
  };
};

const mapDispatchToProps = dispatch => ({
  fetchConnectors: () => {
    dispatch(fetchConnectors());
  },
  fetchGenericConnector: async () => {
    return dispatch(fetchGenericConnector);
  }
});

export default connect(state => ({
  isTouchDevice: state.environment.isTouchDevice
}))(
  searchableAndSelectable(
    withRouter(
      connect(mapStateToProps, mapDispatchToProps)(injectIntl(StoreView))
    )
  )
);
