import * as types from "../constants/ActionTypes";
import { getUniqueId } from "../utils/uniqueIdGenerator";

const SAVE_ERROR_TIMEOUT = 5000;

export const notify = notification => {
  //save notification to redux store, there could be already queue of previous notifications waiting to be displayed
  return {
    type: types.PUSH_NOTIFICATION_TO_QUEUE,
    notification: {
      ...notification,
      id: getUniqueId()
    }
  };
};

export const notifyError = notification => {
  return {
    type: types.PUSH_NOTIFICATION_TO_QUEUE,
    notification: {
      ...notification,
      type: "error",
      dismissDelay: SAVE_ERROR_TIMEOUT,
      id: getUniqueId()
    }
  };
};

export const clearNotification = id => ({
  type: types.REMOVE_NOTIFICATION_FROM_QUEUE,
  id
});
