import * as constants from "../constants/ActionTypes";
import * as localStorageConstants from "../constants/localStorageConstants";

export const WEEK = 7 * 24 * 60 * 60 * 1000;

/*
 * Please always wrap access to native localStorage object into try-catch block!
 * https://mathiasbynens.be/notes/localstorage-pattern#comment-9
 */

const getItem = key => {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    return null;
  }
};

const setItem = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch (e) {}
};

const removeItem = key => {
  try {
    localStorage.removeItem(key);
  } catch (e) {}
};

const getBoolFlag = name => getItem(name) === "true";

const getParsedItem = key => {
  try {
    return JSON.parse(getItem(key));
  } catch (e) {}
  return null;
};

export const saveCache = data => {
  const cache = getCache();
  setItem(
    localStorageConstants.DATA_CACHE,
    JSON.stringify({
      ...cache,
      ...data
    })
  );
};

export const getCache = () => {
  return getParsedItem(localStorageConstants.DATA_CACHE) || {};
};

export const saveCustomTabId = id => {
  setItem(localStorageConstants.CUSTOM_TAB, id);
};

export const getCustomTabId = () => {
  return getParsedItem(localStorageConstants.CUSTOM_TAB);
};

export const setExtensionInstallSkipped = () => {
  setItem(constants.EXTENSION_INSTALL_SKIPPED, "true");
};

export const getExtensionInstallSkipped = () => {
  return getBoolFlag(constants.EXTENSION_INSTALL_SKIPPED);
};

export const setExtensionDetected = detected => {
  setItem(constants.EXTENSION_DETECTED, detected);
};

export const getExtensionDetected = () => {
  return getBoolFlag(constants.EXTENSION_DETECTED);
};

export const getProceedUnsupportedBrowser = () => {
  return getParsedItem(localStorageConstants.PROCEED_UNSUPPORTED);
};

export const getNextDeprecatedWarningDate = () => {
  return getParsedItem(localStorageConstants.NEXT_DEPRECATED_WARNING_DATE);
};

export const setProceedUnsupportedBrowser = isDeprecatedBrowser => {
  setItem(localStorageConstants.PROCEED_UNSUPPORTED, true);

  if (isDeprecatedBrowser) {
    setItem(
      localStorageConstants.NEXT_DEPRECATED_WARNING_DATE,
      +new Date() + WEEK
    );
  }
};

export const clearCache = () => {
  removeItem(localStorageConstants.DATA_CACHE);
  removeItem(localStorageConstants.ACCOUNT);
  removeItem(localStorageConstants.APPS);
  removeItem(localStorageConstants.BRANDING);
  removeItem(localStorageConstants.FREQUENTS);
  removeItem(localStorageConstants.TRANSLATIONS);
};
