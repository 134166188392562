import {
  getTranslations,
  resolveBestLanguage
} from "@onelogin/react-components";

import csCZ from "../translations/languages/cs-CZ.json";
import da from "../translations/languages/da.json";
import de from "../translations/languages/de.json";
import en from "../translations/languages/en.json";
import es from "../translations/languages/es.json";
import fi from "../translations/languages/Finland.json";
import fr from "../translations/languages/fr.json";
import hiIN from "../translations/languages/hi-IN.json";
import huHU from "../translations/languages/hu-HU.json";
import it from "../translations/languages/it.json";
import ja from "../translations/languages/ja.json";
import ko from "../translations/languages/ko.json";
import msMY from "../translations/languages/ms-MY.json";
import nl from "../translations/languages/nl.json";
import no from "../translations/languages/no-norway.json";
import plPL from "../translations/languages/pl-PL.json";
import pt from "../translations/languages/pt.json";
import ruRU from "../translations/languages/ru-RU.json";
import skSK from "../translations/languages/sk-SK.json";
import sv from "../translations/languages/Sweeden.json";
import thTH from "../translations/languages/th-TH.json";
import trTR from "../translations/languages/tr-TR.json";
import { updateIntl } from "react-intl-redux";
import viVN from "../translations/languages/vi-VN.json";
import zh from "../translations/languages/zh.json";
import zhTW from "../translations/languages/zh-TW.json";

const LANGUAGES_MAP = {
  "cs-CZ": csCZ,
  da: da,
  de: de,
  en: en,
  es: es,
  fi: fi,
  fr: fr,
  "hi-IN": hiIN,
  "hu-HU": huHU,
  it: it,
  ja: ja,
  ko: ko,
  "ms-MY": msMY,
  nb: no,
  nl: nl,
  nn: no,
  no: no,
  "pl-PL": plPL,
  pt: pt,
  "ru-RU": ruRU,
  sk: skSK,
  sv: sv,
  th: thTH,
  "tr-TR": trTR,
  "vi-VN": viVN,
  zh: zh,
  "zh-TW": zhTW
};

const getLanguage = language => LANGUAGES_MAP[language];

export const loadTranslations = userLanguage => (dispatch, getState) => {
  const { languageTag, primaryLanguageTag } = resolveBestLanguage({
    userLanguage
  });

  const messages = {
    ...getTranslations(languageTag),
    ...getLanguage(languageTag)
  };

  if (messages) {
    dispatch(updateIntl({ locale: primaryLanguageTag, messages }));
  }
};
