import * as types from "../constants/ActionTypes";

export const initialState = {
  accountFrequents: {},
  userFrequents: {},
  loaded: undefined
};

function frequents(state = initialState, action) {
  switch (action.type) {
    case types.SET_FREQUENTS:
      const { accountFrequents, userFrequents } = action;
      return {
        ...state,
        accountFrequents,
        userFrequents,
        loaded: true
      };

    case types.FREQUENTS_NOT_AVAILABLE:
      return {
        ...state,
        loaded: true
      };

    default:
      return state;
  }
}

export default frequents;
