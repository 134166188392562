import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { isEmpty } from "ramda";
import stopwords from "stopwords-json";

import {
  normalizeApps,
  normalizeApp,
  extractFrequents
} from "../utils/normalize/appNormalizer";
import { getCommonWords } from "../utils/normalize/commonNormalizer";
import * as types from "../constants/ActionTypes";
import * as formServiceApi from "../api/formServiceApi";
import * as eventsEmitter from "../utils/eventsEmitter";
import { saveCache, saveCustomTabId } from "../storage/localStorage";
import * as localStorageConstants from "../constants/localStorageConstants";
import { notifyError } from "../actions/notificationsActions";
import { gotoApps } from "../actions/navigatorActions";

let appFetchRequest;

const messages = defineMessages({
  appLoadFailed: {
    id: "appLoadFailed",
    defaultMessage: `App does not exist or you don't have permissions to see it.`
  },
  appsFetchFailed: {
    id: "appsFetchFailed",
    defaultMessage: `We couldn’t load your apps.`
  }
});

export const computeHasPersonalApp = apps => {
  apps = apps || [];
  return apps.some(app => app.isPrivate);
};

export const setAppsViewType = viewType => ({
  type: types.SET_APPS_VIEW_TYPE,
  viewType
});

export const setCustomTabId = tabId => {
  saveCustomTabId(tabId);
  return {
    type: types.SET_CUSTOM_TAB_ID,
    tabId
  };
};

export const fetchApps = airbrake => (dispatch, getState) => {
  return formServiceApi
    .fetchApps()
    .then(apps => {
      apps = apps || [];
      const { featureFlags, user, frequents } = getState(),
        hasPersonalApps = computeHasPersonalApp(apps),
        frequentsMap = extractFrequents(apps),
        frequentsAvailable = !isEmpty(frequentsMap),
        entities = normalizeApps(apps, getCommonWords(stopwords, user.locale)),
        tabIds = apps.reduce((acc, app) => {
          if (app.tabId) {
            acc[app.tabId] = true;
          }
          return acc;
        }, {});
      const normalizedData = { entities, tabIds, hasPersonalApps };

      dispatch({
        type: types.GET_APPS_DATA_RECEIVED,
        ...normalizedData
      });

      if (frequentsAvailable) {
        // due to timeout on MR for fetching frequents it could happen that frequents are not available
        // in that case we don't want to override frequents in store (loaded from local storage)
        const frequentsData = { userFrequents: frequentsMap };
        dispatch({
          type: types.SET_FREQUENTS,
          ...frequentsData
        });

        if (featureFlags.persistStore) {
          saveCache({
            [localStorageConstants.FREQUENTS]: { ...frequentsData }
          });
        }
      } else if (!isEmpty(frequents.userFrequents)) {
        dispatch({
          type: types.FREQUENTS_AVAILABLE_IN_LS
        });
      } else {
        dispatch({
          type: types.FREQUENTS_NOT_AVAILABLE
        });
      }

      if (featureFlags.persistStore) {
        saveCache({
          [localStorageConstants.APPS]: normalizedData
        });
      }

      eventsEmitter.appsFetched();
    })
    .catch(error => {
      // dispatch fatal error only if there are no apps in store yet
      airbrake.notify(error);
      if (isEmpty(getState().apps.entities)) {
        dispatch({
          type: types.FATAL_NO_APPS,
          messageDescriptor: messages.appsFetchFailed
        });
      }
    });
};

export const getPassword = appId => (dispatch, getState) => {
  const state = getState();

  if (
    state.apps.entities[appId] &&
    state.apps.entities[appId].passwordFetching
  ) {
    return;
  }

  dispatch({
    type: types.GET_PASSWORD_REQUEST,
    appId
  });

  return formServiceApi
    .getPassword({
      appId
    })
    .then(
      result => {
        dispatch({
          type: types.GET_PASSWORD_RECEIVED,
          appId: appId,
          password: result.value
        });
      },
      error => {
        dispatch({
          type: types.GET_PASSWORD_ERROR,
          appId
        });
      }
    );
};

export const fetchApp = appId => (dispatch, getState) => {
  const state = getState();

  if (state.apps.entities[appId] && state.apps.entities[appId].fetching) {
    return appFetchRequest;
  }

  dispatch({
    type: types.FETCH_APP_REQUEST,
    appId
  });

  return (appFetchRequest = formServiceApi
    .fetchApp({
      appId
    })
    .then(
      app => {
        dispatch({
          type: types.FETCH_APP_RECEIVED,
          app: normalizeApp(app),
          appId
        });

        return app;
      },
      error => {
        dispatch({
          type: types.FETCH_APP_ERROR,
          messageDescriptor: messages.appLoadFailed,
          appId
        });

        dispatch(
          notifyError({
            message: error.message ? (
              error.message
            ) : (
              <FormattedMessage {...messages.appLoadFailed} />
            )
          })
        );

        dispatch(gotoApps());
      }
    ));
};
