import client from "./httpClient";
import * as apiConstants from "../constants/Api";
import { getErrorHandler, normalizeError } from "./apiErrorHandling";

const errorHandler = getErrorHandler({});

const handleFormServiceError = async error => {
  const normalized = await normalizeError(error);
  errorHandler(normalized, () => {
    // we can't stop execution even if error was handled so we rethrow
    throw normalized;
  });
};

export const fetchMe = async () => {
  const user = await client
    .get(`${apiConstants.SERVER_URL}/form-service/me`)
    .json()
    .catch(handleFormServiceError);

  if (user && user.status === apiConstants.STATUS_EXPIRED_PASSWORD) {
    window.location.replace(apiConstants.PASSWORD_CHANGE_URL);
  }

  return user;
};

export const fetchAccount = () => {
  return client
    .get(apiConstants.SERVER_URL + "/form-service/company")
    .json()
    .catch(handleFormServiceError);
};

export const fetchApps = () => {
  return client
    .get(`${apiConstants.SERVER_URL}/form-service/logins`)
    .json()
    .catch(handleFormServiceError);
};

export const fetchApp = ({ appId }) => {
  return client
    .get(`${apiConstants.SERVER_URL}/form-service/portal/logins/${appId}`)
    .json()
    .catch(handleFormServiceError);
};

export const fetchConnector = ({ connectorId }) => {
  return client
    .get(`${apiConstants.SERVER_URL}/apps-service/connectors/${connectorId}`)
    .json()
    .catch(handleFormServiceError);
};

export const saveApp = ({ body, appId }) => {
  return client
    .put(`${apiConstants.SERVER_URL}/form-service/portal/logins/${appId}`, {
      json: body
    })
    .json()
    .catch(handleFormServiceError);
};

export const createApp = ({ body }) => {
  return client
    .post(`${apiConstants.SERVER_URL}/form-service/portal/logins`, {
      json: body
    })
    .json()
    .catch(handleFormServiceError);
};

export const getPassword = ({ appId }) => {
  return client
    .get(
      `${apiConstants.SERVER_URL}/form-service/portal/logins/${appId}/password`
    )
    .json()
    .catch(handleFormServiceError);
};

export const removeApp = ({ appId }) => {
  return client
    .delete(`${apiConstants.SERVER_URL}/form-service/portal/logins/${appId}`)
    .json()
    .catch(handleFormServiceError);
};
