import { pipe, split, nth, lte } from "ramda";
const MIN_EXT_VERSION = 3;

export const compatibleVersion = extension_version => {
  if (extension_version) {
    const compatible = pipe(
      split("_"),
      nth(-1),
      parseInt,
      lte(MIN_EXT_VERSION)
    );
    return compatible(extension_version);
  } else {
    return false;
  }
};
