import * as types from "../constants/ActionTypes";

const initialState = {
  saving: undefined
};

//TODO: in next phase of form refactoring, this could be merged to appFormReducer and used for both addApp and appEdit
function addApp(state = initialState, action) {
  switch (action.type) {
    case types.CREATE_APP_REQUEST:
      return {
        ...state,
        saving: true
      };

    case types.CREATE_APP_FINISHED:
      return {
        ...state,
        saving: false
      };

    case types.CREATE_APP_ERROR:
      return {
        ...state,
        saving: false
      };

    default:
      return state;
  }
}

export default addApp;
