import React from "react";
import PropTypes from "prop-types";
import { injectIntl, defineMessages } from "react-intl";

import Message from "../../Message";
import StoreRow from "./StoreRow";

const messages = defineMessages({
  noAppsInStore: {
    id: "noAppsInStore",
    defaultMessage: `No apps available in store right now.`
  }
});

export const StoreAppsList = ({
  connectors,
  searchTerm,
  selectedIndex,
  onAddAppClick,
  onSelectedIndexChange,
  focusEl,
  intl
}) => {
  if (!searchTerm && connectors.length === 0) {
    return <Message msg={intl.formatMessage(messages.noAppsInStore)} />;
  }

  return (
    <div className="store-app-list">
      {connectors.map((connector, index) => (
        <StoreRow
          key={connector.id}
          id={connector.id}
          name={connector.name}
          description={connector.description}
          icons={connector.icons || {}}
          isSelected={selectedIndex === index}
          isAddEnabled={connector.addEnabled}
          onFocus={() => {
            onSelectedIndexChange(index);
          }}
          onClick={onAddAppClick}
          focusEl={focusEl}
          intl={intl}
        />
      ))}
    </div>
  );
};

StoreAppsList.propTypes = {
  connectors: PropTypes.array.isRequired,
  searchTerm: PropTypes.string.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  onAddAppClick: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  focusEl: PropTypes.func.isRequired,
  onSelectedIndexChange: PropTypes.func.isRequired
};

export default injectIntl(StoreAppsList);
