import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { omit } from "ramda";
import { Input, TextField, Dropdown } from "@onelogin/react-components";

import PasswordField from "./PasswordField";
import FormFieldLabel from "./FormFieldLabel";
import PrivacySelectorFormField from "../addApp/PrivacySelectorFormField";

import "./FormField.css";

const FIELD_MAX_LENGTH = 255;
const TEXTAREA_MAX_LENGTH = 200; //default based on 'description' field requirements; feel free to redefine

const getReactHandler = (handler, id, maxLength) => {
  if (!handler) {
    return null;
  }

  return event =>
    handler({
      id,
      maxLength,
      value: event.target.value
    });
};

const InputField = (field, info) => (
  <Fragment>
    <FormFieldLabel
      label={field.label}
      forId={field.id}
      isRequired={field.required}
      info={info}
    />
    <Input
      {...field}
      onChange={getReactHandler(field.onChange, field.id, field.maxLength)}
      onBlur={getReactHandler(field.onBlur, field.id, field.maxLength)}
    />
  </Fragment>
);

const InfoTextField = field => (
  <Fragment>
    <FormFieldLabel label={field.label} />
    <TextField>{field.value}</TextField>
  </Fragment>
);

const PasswordInputField = (field, info) => (
  <PasswordField
    field={{
      ...field,
      onChange: value => {
        field.onChange &&
          field.onChange({
            id: field.id,
            maxLength: field.maxLength,
            value
          });
      },
      onBlur: value => {
        field.onBlur &&
          field.onBlur({
            id: field.id,
            maxLength: field.maxLength,
            value
          });
      }
    }}
    info={info}
  />
);

const CheckboxField = field => (
  <div className="ui checkbox">
    <input
      {...field}
      type="checkbox"
      className="hidden"
      readOnly
      tabIndex={0}
      value="on"
      onChange={getReactHandler(field.onChange, field.id)}
    />
    <label htmlFor={field.id} className="form-field-label">
      {field.label}
    </label>
  </div>
);

//TODO: use RC's checkbox field instead of input using semantic-ui styles
//I was in the middle of transition to use RC's checkbox when I realized I don't know what to save in case of checkbox value OR checked state.
//update of RC is needed, as the component doesn't return whether checkbox is checked or not; nor it passes `ref` to that field, so here's no chance to get the value
//Seems checkbox is not used in any active connector as I believe the legacy version doesn't work as expected (but I may be wrong). Investigate further
//https://onelogin2.atlassian.net/browse/PORTAL-3211
/*const RCCheckboxField = field => (
  <Checkbox
    label={field.label}
    id={field.id}
    value={field.value}
    checked={field.value === "on"}
    onChange={data => {
      field.onChange && field.onChange({ value: data.checked ? "on" : "off" });
    }}
  />
);*/

const DropdownField = field => [
  <FormFieldLabel
    key={"label_" + field.id}
    label={field.label}
    forId={field.id}
    isRequired={field.required}
  />,
  <Dropdown
    {...field}
    key={"input_" + field.id}
    placeholder="Select"
    list={field.options || []}
    onChange={value => {
      field.onChange &&
        field.onChange({
          value
        });
    }}
  />
];

const MultiSelect = field => [
  <FormFieldLabel
    key={"label_" + field.id}
    label={field.label}
    forId={field.id}
    isRequired={field.required}
  />,
  <select
    {...omit(["options", "invalid"], field)}
    key={"input_" + field.id}
    multiple
    onChange={event => {
      //IE browsers doesn't support event.target.selectedOptions, and for some reason also event.target.options in IE points to very same multiselect element 🤷‍♂️
      //so iterate over children elements to get the selected options
      let value = [];
      for (let i = 0; i < event.target.children.length; i++) {
        if (event.target.children[i].selected) {
          value.push(event.target.children[i].value);
        }
      }

      field.onChange &&
        field.onChange({
          value
        });
    }}
  >
    {field.options &&
      field.options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.text}
        </option>
      ))}
  </select>
];

const TextAreaField = (field, info) => [
  <FormFieldLabel
    key={"label_" + field.id}
    label={field.label}
    forId={field.id}
    info={info}
    isRequired={field.required}
  />,
  <textarea
    {...omit(["invalid"], field)}
    key={"textarea_" + field.id}
    rows={field.rows || 2}
    onChange={event => {
      field.onChange &&
        field.onChange({
          id: field.id,
          maxLength: field.maxLength,
          value: event.target.value
        });
    }}
  />
];

//checkbox class by purpose (based on semantic-ui styles)
const RadioField = field => (
  <div className="ui radio checkbox">
    <input
      {...omit(["description"], field)}
      type="radio"
      className="hidden"
      readOnly
      tabIndex={0}
      onChange={getReactHandler(field.onChange, field.id)}
    />
    <label htmlFor={field.id} className="form-field-label">
      {field.label}
    </label>
  </div>
);

const PrivacySelectorField = field => (
  <PrivacySelectorFormField
    id={field.id}
    isPrivate={field.value}
    onChange={field.onChange}
  />
);

export const supportedTypes = [
  "text",
  "string",
  "password",
  "username",
  "boolean",
  "list",
  "multi_select",
  "textarea",
  "listedvalues",
  "radio",
  "privacySelector",
  "infoText"
];

const FormFieldWithLabel = ({ formFieldProps, info }) => {
  switch (formFieldProps.type) {
    case "password":
      return PasswordInputField(
        {
          ...formFieldProps,
          maxLength: formFieldProps.maxLength || FIELD_MAX_LENGTH
        },
        info
      );

    case "boolean":
      return CheckboxField({
        ...formFieldProps
      });

    case "text":
    case "string":
    case "username":
      return InputField(
        {
          ...formFieldProps,
          type: "text",
          maxLength: formFieldProps.maxLength || FIELD_MAX_LENGTH
        },
        info
      );

    case "list":
      return DropdownField({
        ...formFieldProps
      });

    case "textarea":
      return TextAreaField(
        {
          ...formFieldProps,
          maxLength: formFieldProps.maxLength || TEXTAREA_MAX_LENGTH
        },
        info
      );

    case "infoText":
      return InfoTextField(formFieldProps);

    case "multi_select":
      return MultiSelect({
        ...formFieldProps,
        value: formFieldProps.value || []
      });

    case "radio":
      return RadioField(formFieldProps);

    case "privacySelector":
      return PrivacySelectorField(formFieldProps);

    default:
      return null;
  }
};

FormFieldWithLabel.propTypes = {
  formFieldProps: PropTypes.object.isRequired,
  info: PropTypes.string
};

export default FormFieldWithLabel;
