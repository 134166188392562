import React from "react";
import PropTypes from "prop-types";
import { defineMessages } from "react-intl";

import plusIcon from "../../../svg/plus.svg";

import "./StoreCreateAppRow.css";

const messages = defineMessages({
  dontSeeWhatYouNeed: {
    id: "dontSeeWhatYouNeed",
    defaultMessage: `Don't see what you need?`
  },
  createApp: {
    id: "createApp",
    defaultMessage: `New`
  }
});

const StoreCreateAppRow = ({ onClick, intl }) => (
  <button className="store-new-row" onClick={onClick}>
    <div className="snr-name">
      {intl.formatMessage(messages.dontSeeWhatYouNeed)}
    </div>
    <div className="snr-action">
      <img
        src={plusIcon}
        height="18"
        width="18"
        alt={intl.formatMessage(messages.createApp)}
      />
      <span className="snr-action-description">
        {intl.formatMessage(messages.createApp)}
      </span>
    </div>
  </button>
);

StoreCreateAppRow.propTypes = {
  onClick: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired
};

export default StoreCreateAppRow;
