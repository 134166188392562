import React, { useEffect, useRef, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { injectIntl, defineMessages } from "react-intl";

import "./AppCell.css";
import AppTileLogo from "./AppTileLogo";

const messages = defineMessages({
  capitalizedEdit: {
    id: "capitalizedEdit",
    defaultMessage: `Edit`
  },
  launchApp: {
    id: "launchApp",
    defaultMessage: `Launch {appName}`
  }
});

// AppCellFooter depends on css styles from AppCell
const AppCellFooter = ({
  app,
  onEditClick,
  isTouchDevice,
  intl,
  footerRef
}) => {
  if (isTouchDevice) {
    // on touch screen, edit action is always visible
    return (
      <div className="app-cell-footer-mobile">
        {app.isEditable && (
          <div onClick={onEditClick} className="app-cell-edit">
            <div className="app-cell-edit-icon" />
          </div>
        )}
        <div className="app-cell-appname" ref={footerRef}>
          {app.name}
        </div>
      </div>
    );
  }

  const appCellFooter = classNames({
    "app-cell-footer": true,
    "editable-app": app.isEditable && !isTouchDevice
  });

  return (
    <div className={appCellFooter}>
      <div className="app-cell-description">
        <div className="app-cell-appname" ref={footerRef}>
          {app.name}
        </div>
      </div>
      <div onClick={onEditClick} className="app-cell-edit">
        <div className="app-cell-edit-icon" />
        <div className="app-cell-edit-description">
          {intl.formatMessage(messages.capitalizedEdit)}
        </div>
      </div>
    </div>
  );
};

AppCellFooter.propTypes = {
  app: PropTypes.object.isRequired,
  onEditClick: PropTypes.func.isRequired,
  isTouchDevice: PropTypes.bool.isRequired
};

const AppCell = ({
  app,
  isSelected,
  needAttention,
  isTouchDevice,
  onFocus,
  focusEl,
  intl,
  onAppClick,
  onEditClick
}) => {
  const [title, setTitle] = React.useState();

  const ref = useRef(null);
  const footerRef = useRef(null);

  //useLayouteffect
  useLayoutEffect(() => {
    if (
      footerRef.current &&
      footerRef.current.clientWidth < footerRef.current.scrollWidth
    ) {
      setTitle(app.name);
    }
  }, [app.name]);

  const onClick = event => {
    onAppClick({ event, app });
  };

  const onEditClickHandler = event => {
    event.preventDefault();
    event.stopPropagation();
    onEditClick(app.id);
  };

  const appCellStyle = classNames({
    "app-cell": true,
    "need-attention": needAttention
  });

  useEffect(() => {
    if (isSelected && ref.current) {
      focusEl(ref.current);
    }
  });

  //NOTE: looks like tabIndex NEEDS to be set explicitely to make focusing work properly (in chrome); even though 0 is default for anchors
  return (
    <div className="app-cell-wrapper">
      <a
        href={app.startUrl}
        target={!app.gdtRequired && "_blank"}
        rel={!app.gdtRequired && "noopener noreferrer"}
        onClick={onClick}
        className={appCellStyle}
        ref={ref}
        title={title}
        onFocus={onFocus}
        tabIndex={0}
        aria-label={intl.formatMessage(messages.launchApp, {
          appName: app.name
        })}
      >
        <div className="centered-image">
          <AppTileLogo icons={app.icons} appName={app.name} />
        </div>
        <AppCellFooter
          app={app}
          footerRef={footerRef}
          onEditClick={onEditClickHandler}
          isTouchDevice={isTouchDevice}
          intl={intl}
        />
      </a>
    </div>
  );
};

AppCell.propTypes = {
  app: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onAppClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  needAttention: PropTypes.bool.isRequired,
  isTouchDevice: PropTypes.bool.isRequired,
  focusEl: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired
};

export default injectIntl(AppCell);
