import React from "react";
import PropTypes from "prop-types";

import "./FooterNavigation.css";

const FooterNavigation = ({ text, link }) => (
  <div className="footer-nav">
    {text} {link}
  </div>
);

FooterNavigation.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.element.isRequired
};

export default FooterNavigation;
