import {
  extensionDetected,
  extensionNotDetected
} from "../actions/otherActions";
import { getExtensionVersion } from "../utils/eventsEmitter";

const EXTENSION_DETECTION_TIMEOUT = 1000;

export const setupExtensionDetection = ({ store }) => {
  const extensionDetectionTimeout = setTimeout(() => {
    clearInterval(extensionDetectionInterval);
    store.dispatch(extensionNotDetected());
  }, EXTENSION_DETECTION_TIMEOUT);

  //new way of checking extension -> new extension supports this way
  const extensionDetectionInterval = setInterval(() => {
    getExtensionVersion(response => {
      clearInterval(extensionDetectionInterval);
      clearTimeout(extensionDetectionTimeout);
      store.dispatch(extensionDetected(response.versionString));
    });
  }, 100);

  //but portal needs to support also old extensions
  //safely remove with legacy portal removal
  window.create_plugin_detected_div = plugin_version => {
    clearTimeout(extensionDetectionTimeout);
    store.dispatch(extensionDetected(plugin_version));
  };
};
