export const loadAnalytics = (mainCode, secondaryCode) => {
  if (!mainCode) {
    return;
  }

  let script = document.createElement("script");
  script.src = "//www.google-analytics.com/analytics.js";
  document.head.appendChild(script);

  window.ga =
    window.ga ||
    function() {
      (window.ga.q = window.ga.q || []).push(arguments);
    };
  window.ga.l = +new Date();

  window.ga("create", mainCode, "auto");
  window.ga("send", "pageview");

  if (secondaryCode) {
    window.ga("create", secondaryCode, "auto", "secondaryTracker");
    window.ga("secondaryTracker.send", "pageview");
  }
};
