import { Component } from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";

class ScreenSizeWatcher extends Component {
  componentDidMount = () => {
    window.addEventListener("resize", this.onResize);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.onResize);
  };

  // https://medium.com/gitconnected/debounce-react-and-redux-code-for-improved-performance-4b8d3c19e305
  onResize = debounce(() => {
    const { innerWidth, innerHeight } = window;

    if (this.props.width !== innerWidth || this.props.height !== innerHeight) {
      this.props.setSize(innerWidth, innerHeight);
    }
  }, 50);

  render = () => null;
}

ScreenSizeWatcher.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  setSize: PropTypes.func.isRequired
};

export default ScreenSizeWatcher;
