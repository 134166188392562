export const SET_APPS_VIEW_TYPE = "SET_APPS_VIEW_TYPE";
export const SET_CUSTOM_TAB_ID = "SET_CUSTOM_TAB_ID";
export const SET_SCREEN_SIZE = "SET_SCREEN_SIZE";
export const SET_FREQUENTS = "SET_FREQUENTS";
export const FREQUENTS_NOT_AVAILABLE = "FREQUENTS_NOT_AVAILABLE";
export const FREQUENTS_AVAILABLE_IN_LS = "FREQUENTS_AVAILABLE_IN_LS";

export const HELP_SUBMIT_REQUEST = "HELP_SUBMIT_REQUEST";
export const HELP_SUBMIT_RECEIVED = "HELP_SUBMIT_RECEIVED";

//one app
export const FETCH_APP_REQUEST = "FETCH_APP_REQUEST";
export const FETCH_APP_RECEIVED = "FETCH_APP_RECEIVED";
export const FETCH_APP_ERROR = "FETCH_APP_ERROR";

export const SAVE_APP_REQUEST = "SAVE_APP_REQUEST";
export const SAVE_PERSONAL_APP_FINISHED = "SAVE_PERSONAL_APP_FINISHED";
export const SAVE_COMPANY_APP_FINISHED = "SAVE_COMPANY_APP_FINISHED";
export const SAVE_APP_ERROR = "SAVE_APP_ERROR";
export const CREATE_APP_REQUEST = "CREATE_APP_REQUEST";
export const CREATE_APP_FINISHED = "CREATE_APP_FINISHED";
export const CREATE_APP_ERROR = "CREATE_APP_ERROR";
export const REMOVE_APP_REQUEST = "REMOVE_APP_REQUEST";
export const REMOVE_APP_FINISHED = "REMOVE_APP_FINISHED";
export const REMOVE_APP_ERROR = "REMOVE_APP_ERROR";
export const RESET_APP_REMOVE = "RESET_APP_REMOVE";
export const RESET_APP_FORM = "RESET_APP_FORM";
export const FETCH_CONNECTOR_REQUEST = "FETCH_CONNECTOR_REQUEST";
export const FETCH_CONNECTOR_RECEIVED = "FETCH_CONNECTOR_RECEIVED";
export const FETCH_GENERIC_CONNECTOR_REQUEST =
  "FETCH_GENERIC_CONNECTOR_REQUEST";
export const FETCH_GENERIC_CONNECTOR_RECEIVED =
  "FETCH_GENERIC_CONNECTOR_RECEIVED";

export const GET_PASSWORD_REQUEST = "GET_PASSWORD_REQUEST";
export const GET_PASSWORD_RECEIVED = "GET_PASSWORD_RECEIVED";
export const GET_PASSWORD_ERROR = "GET_PASSWORD_ERROR";

export const GET_APPS_DATA_RECEIVED = "GET_APPS_DATA_RECEIVED";

export const FETCH_BRANDING_RECEIVED = "FETCH_BRANDING_RECEIVED";
export const FETCH_BRANDING_ERROR = "FETCH_BRANDING_ERROR";

export const MISSING_AUTHORIZATION = "MISSING_AUTHORIZATION";

export const EXTENSION_DETECTED = "EXTENSION_DETECTED";
export const EXTENSION_NOT_DETECTED = "EXTENSION_NOT_DETECTED";
export const EXTENSION_SKIP_INSTALL = "EXTENSION_SKIP_INSTALL";
export const EXTENSION_INSTALL_SKIPPED = "EXTENSION_INSTALL_SKIPPED";
export const PROCEED_UNSUPPORTED_BROWSER = "PROCEED_UNSUPPORTED_BROWSER";

export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER_RECEIVED = "FETCH_USER_RECEIVED";

export const FETCH_TRANSLATIONS_REQUEST = "FETCH_TRANSLATIONS_REQUEST";
export const FETCH_TRANSLATIONS_RECEIVED = "FETCH_TRANSLATIONS_RECEIVED";
export const FETCH_TRANSLATIONS_ERROR = "FETCH_TRANSLATIONS_ERROR";

export const FETCH_CONNECTORS_RECEIVED = "FETCH_CONNECTORS_RECEIVED";
export const FETCH_CONNECTORS_ERROR = "FETCH_CONNECTORS_ERROR";
export const FETCH_CONNECTORS_REQUEST = "FETCH_CONNECTORS_REQUEST";

export const FETCH_ACCOUNT_RECEIVED = "FETCH_ACCOUNT_RECEIVED";
export const FETCH_ACCOUNT_ERROR = "FETCH_ACCOUNT_ERROR";

export const PUSH_NOTIFICATION_TO_QUEUE = "PUSH_NOTIFICATION_TO_QUEUE";
export const REMOVE_NOTIFICATION_FROM_QUEUE = "REMOVE_NOTIFICATION_FROM_QUEUE";

export const FATAL_NO_USER = "FATAL_NO_USER";
export const FATAL_NO_APPS = "FATAL_NO_APPS";

export const NAVIGATE_TO = "NAVIGATE_TO";
export const NAVIGATE_TO_RESET = "NAVIGATE_TO_RESET";

export const AppsViewType = {
  PERSONAL: "PERSONAL",
  COMPANY: "COMPANY",
  FAVORITE_ONLY: "FAVORITE_ONLY"
};

export const StoreViewType = {
  TOP_10_APPS: "TOP_10_APPS"
};
