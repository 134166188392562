import {
  map,
  omit,
  isEmpty,
  join,
  pipe,
  toLower,
  split,
  uniq,
  difference
} from "ramda";

import * as apiConstants from "../../constants/Api";

export const normalizeParams = ({ parameters }) => {
  if (!parameters) {
    return [];
  }

  return map(parameter => {
    return Object.assign(
      omit(["option_values"], parameter),
      { id: `${apiConstants.PARAMETER_ID_PREFIX}${parameter.id}` },
      parameter.option_values
        ? {
            options: map(
              ({ value, label }) => ({ value, text: label }),
              parameter.option_values
            )
          }
        : undefined
    );
  }, parameters);
};

export const normalizeName = ({ name }) => name || "";

export const normalizeId = ({ id, _id }) => id || _id;

export const normalizeIcons = ({ icons }) => {
  if (!icons || isEmpty(icons)) {
    return {
      square_48x48: apiConstants.SQUARE_IMG_FALLBACK_URL,
      square_100x100: apiConstants.SQUARE_IMG_FALLBACK_URL,
      rectangle_264x100: apiConstants.RECT_IMG_FALLBACK_URL
    };
  }

  return icons;
};

export const getCommonWords = (stopwords, locale) => {
  let commonWords = stopwords["en"];
  if (locale && locale !== "en") {
    commonWords = commonWords.concat(stopwords[locale] || []);
  }
  return commonWords;
};

export const computeSearchKeywords = (
  { name, description, username },
  commonWords
) => {
  const lowSplit = pipe(toLower, split(" "));
  let keywords = lowSplit(name || "");
  if (description) {
    keywords = keywords.concat(difference(lowSplit(description), commonWords));
  }
  if (username) {
    keywords = keywords.concat(toLower(username));
  }
  return join(" ", uniq(keywords));
};
