import * as apiConstants from "../constants/Api";

const isPendingTermsAndConditionsError = ({ status, body }) =>
  status === apiConstants.UNPROCESSABLE_ENTITY_CODE &&
  body.name === "PendingTermsAndConditionsError";

const isExpiredSession = ({ status }) =>
  status === apiConstants.UNAUTHORIZED_CODE;

const redirectToRoot = () => window.location.replace("/");

export const getErrorHandler =
  ({ onExpiredSession = redirectToRoot, onPendingTerms = redirectToRoot }) =>
  (normalizedError, uncaughtException) => {
    const { normalized } = normalizedError;

    if (normalized && isExpiredSession(normalized)) {
      onExpiredSession();
      return;
    }

    if (normalized && isPendingTermsAndConditionsError(normalized)) {
      onPendingTerms();
      return;
    }

    uncaughtException();
  };

/*
 * Methods below were design to work for Fetch HTTP client
 */

const normalizeJsonResponse = async response => {
  const { message, name } = await response.json();
  return {
    status: response.status,
    body: {
      name,
      message
    }
  };
};

const normalizeNonJsonResponse = response => ({
  status: response.status,
  body: {
    name: response.statusText,
    message: ""
  }
});

const isJsonResponse = response => {
  if (response.headers) {
    const contentType = (
      response.headers.get("Content-Type") || ""
    ).toLowerCase();
    return contentType === "application/json";
  }
  return false;
};

export const normalizeError = async httpError => {
  const { response } = httpError;
  // when response is not present => networking error => no response from server
  if (response) {
    httpError.normalized = isJsonResponse(response)
      ? await normalizeJsonResponse(response)
      : normalizeNonJsonResponse(response);
  }

  return httpError;
};

// Example of error response from Monorail
// =======================================
// HTTP status: 403
// body: {
//   status: {
//     type: "Forbidden",
//     error: true,
//     message: "forbidden",
//     code: 403
//   }
// };

export const normalizeMonorailError = async httpError => {
  const { response } = httpError;
  // when response is not present => networking error => no response from server
  if (response) {
    const { status } = await response.json();
    httpError.normalized = {
      status: response.status,
      body: {
        name: status.type,
        message: status.message
      }
    };
  }

  return httpError;
};
