import { values } from "ramda";

import { AppsViewType } from "../constants/ActionTypes";
import { ALL_APPS_CUSTOM_TAB_ID } from "../constants/Apps";

const sortByName = (appA, appB) => {
  const compare = appA.name.localeCompare(appB.name);
  if (compare === 0) {
    return appA.isPrivate - appB.isPrivate;
  }
  return compare;
};

const sortByFrequency = (userFrequents, accountFrequents) => (appA, appB) => {
  // userFrequents: { loginId -> score}; accountFrequents: { appId -> score }
  const freqA = userFrequents[appA.id] || accountFrequents[appA.appId] || 0,
    freqB = userFrequents[appB.id] || accountFrequents[appB.appId] || 0,
    compare = freqB - freqA;

  if (compare === 0) {
    return sortByName(appA, appB);
  }

  return compare;
};

export const selectSortedFilteredList = maxFrequentsToShow => (
  { entities, appsViewType },
  userFrequents = {},
  accountFrequents = {},
  searchKeyword,
  customTabId
) => {
  const apps = values(entities);
  if (searchKeyword && searchKeyword.length > 0) {
    const normKeywords = searchKeyword.toLowerCase().split(" ");
    return apps
      .filter(app =>
        normKeywords.every(word => app.searchKeywords.includes(word))
      )
      .sort(sortByFrequency(userFrequents, accountFrequents));
  } else if (appsViewType === AppsViewType.FAVORITE_ONLY) {
    return apps
      .filter(app => userFrequents[app.id] || accountFrequents[app.appId])
      .sort(sortByFrequency(userFrequents, accountFrequents))
      .slice(0, maxFrequentsToShow)
      .sort(sortByName);
  } else if (appsViewType === AppsViewType.COMPANY) {
    let companyApps = apps.filter(entry => !entry.isPrivate);
    if (customTabId !== undefined && customTabId !== ALL_APPS_CUSTOM_TAB_ID) {
      companyApps = companyApps.filter(app => app.tabId === customTabId);
    }
    return companyApps.sort(sortByName);
  } else if (appsViewType === AppsViewType.PERSONAL) {
    return apps.filter(entry => entry.isPrivate).sort(sortByName);
  } else if (appsViewType === undefined) {
    return [];
  }
};
