import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { injectIntl, defineMessages } from "react-intl";
import { Input } from "@onelogin/react-components";

import { generatePassword } from "../../utils/generatePassword";
import FormFieldLabel from "./FormFieldLabel";
import LinkButton from "../LinkButton";

import "./PasswordField.css";

const messages = defineMessages({
  show: {
    id: "show",
    defaultMessage: `Show`
  },
  hide: {
    id: "hide",
    defaultMessage: `Hide`
  },
  generate: {
    id: "generate",
    defaultMessage: `Generate`
  }
});

export class PasswordField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputVisible: false,
      revealed: false
    };
  }

  toggleInputVisibility = () => {
    if (!this.props.field.disabled) {
      this.setState({ inputVisible: !this.state.inputVisible });
    }
  };

  toggleReveal = event => {
    if (!this.props.field.disabled) {
      this.setState({ revealed: !this.state.revealed });
    }
  };

  handleChange = event => {
    this.props.field.onChange(event.target.value);
  };

  onBlur = event => {
    this.toggleInputVisibility();
    this.props.field.onBlur(event.target.value);
  };

  onGeneratePassword = event => {
    event.preventDefault();
    this.props.field.onChange(generatePassword());
    this.setState({ revealed: true });
  };

  render() {
    const { field, info, intl } = this.props;

    const placeholder = this.state.revealed
      ? field.value
      : field.value.replace(/./g, "*");
    const placeholderClassname = classNames({
      [field.className]: true,
      "password-field-placeholder": true,
      revealed: this.state.revealed,
      invalid: field.invalid
    });

    return (
      <div className="password-field">
        <FormFieldLabel
          label={field.label}
          forId={field.id}
          action={
            !field.disabled
              ? {
                  title: intl.formatMessage(messages.generate),
                  handler: this.onGeneratePassword
                }
              : undefined
          }
          isRequired={field.required}
          info={info}
        />
        {this.state.inputVisible ? (
          <Input
            {...field}
            onChange={this.handleChange}
            type={this.state.revealed ? "text" : "password"}
            onBlur={this.onBlur}
            autoFocus
          />
        ) : (
          <div
            className={classNames(placeholderClassname, {
              "password-field-disabled": field.disabled
            })}
            tabIndex={field.disabled ? -1 : 0}
            onClick={this.toggleInputVisibility}
            onFocus={this.toggleInputVisibility}
          >
            {placeholder}
          </div>
        )}
        {!field.disabled && field.value && (
          <LinkButton
            className="password-field-reveal"
            onClick={this.toggleReveal}
            text={
              this.state.revealed
                ? intl.formatMessage(messages.hide)
                : intl.formatMessage(messages.show)
            }
          />
        )}
      </div>
    );
  }
}

PasswordField.propTypes = {
  field: PropTypes.object.isRequired,
  info: PropTypes.string
};

export default injectIntl(PasswordField);
