import * as eventsEmitter from "../utils/eventsEmitter";

import {
  APP_STORE_PATH,
  HELP_PATH,
  ROOT_PATH
} from "../constants/NavigationConstants";

import { FormattedMessage } from "react-intl";
import React from "react";
import { getIsNarrowView } from "./environmentSelectors";
import { isAppStoreEnabled } from "./featureFlagsSelectors";

export const canAddPersonalApp = ({ user }) => user.allowAddPersonalApp;
export const canAddCompanyApp = ({ user }) => user.allowAddCompanyApp;

export const canSeeAppStore = state =>
  !!(
    isAppStoreEnabled(state) &&
    (canAddPersonalApp(state) || canAddCompanyApp(state))
  );

export const getMenus = (state, isOnAppStore) => {
  const isNarrowView = getIsNarrowView(state.environment);
  const showAppStore = canSeeAppStore(state) && isNarrowView && !isOnAppStore;

  const showPortalLink = isNarrowView && isOnAppStore;
  const assumedByAdmin = state.user.assumedByAdmin;
  const assumedBySuperadmin = state.user.assumedBySuperadmin;
  const assumedBySandbox = state.user.assumedBySandbox;

  const secureNoteAccess =
    state.account.secureNotes &&
    !(assumedByAdmin || assumedBySuperadmin || assumedBySandbox);

  const showHelpLink = state.account.helpEnabled;

  const showAdminLink =
    state.user.isAdmin || state.user.hasAnyDelegatedAdminPrivileges;

  const showManagedAssets = state.account.enableOnePAM && state.user.privilegedAccessApplications?.portal;

  return [
    showManagedAssets ? {
      title: (
        <FormattedMessage
          defaultMessage="Applications"
          id="applications"
        />
      ),
      href: ROOT_PATH
    } : undefined,
    showManagedAssets ? {
      title: (
        <FormattedMessage
          defaultMessage="Managed Infrastructure"
          id="managedInfrastructure"
        />
      ),
      target: "_self",
      href: `/start/${state.user.privilegedAccessApplications?.portal}`
    } : undefined,
    showAdminLink
      ? {
          title: (
            <FormattedMessage
              defaultMessage="Administration"
              id="administration"
            />
          ),
          align: "right",
          href: "/admin"
        }
      : undefined,
    {
      initials: state.user.initials,
      name: state.user.username,
      picture: state.user.profilePicture,
      align: "right",
      links: [
        showAppStore
          ? {
              href: APP_STORE_PATH,
              title: (
                <FormattedMessage defaultMessage="App Store" id="appStore" />
              )
            }
          : undefined,
        showPortalLink
          ? {
              href: ROOT_PATH,
              title: <FormattedMessage defaultMessage="Portal" id="portal" />
            }
          : undefined,
        {
          href: "/profile2",
          title: <FormattedMessage defaultMessage="Profile" id="profile" />
        },
        secureNoteAccess
          ? {
              href: "/notes",
              title: (
                <FormattedMessage
                  defaultMessage="Secure Notes"
                  id="secureNotes"
                />
              )
            }
          : undefined,
        showHelpLink
          ? {
              href: HELP_PATH,
              title: <FormattedMessage defaultMessage="Help" id="helpLink" />
            }
          : undefined,
        assumedByAdmin
          ? {
              href: "/sessions/revert",
              rel: "noopener noreferrer",
              title: (
                <FormattedMessage
                  defaultMessage="Revert to Admin"
                  id="revertToAdmin"
                />
              )
            }
          : undefined,
        assumedBySuperadmin
          ? {
              href: "/admin/accounts/revert",
              rel: "noopener noreferrer",
              title: (
                <FormattedMessage
                  defaultMessage="Revert to OneLogin Admin"
                  id="revertToSuperadmin"
                />
              )
            }
          : undefined,
        assumedBySandbox
          ? {
              href: "/sandboxes/revert",
              rel: "noopener noreferrer",
              title: (
                <FormattedMessage
                  defaultMessage="Revert to Admin"
                  id="revertToAdmin"
                />
              )
            }
          : undefined,
        {
          href: "/logout",
          title: <FormattedMessage defaultMessage="Logout" id="logout" />,
          onClick: () => {
            eventsEmitter.userLogout();
          }
        }
      ].filter(item => item !== undefined)
    }
  ].filter(item => item !== undefined);
};
