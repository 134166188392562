import React from "react";
import { injectIntl, defineMessages } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";

import { commonMessages } from "../../translations/commonMessages";
import ResponsiveBlock from "../ResponsiveBlock";

const messages = defineMessages({
  installExtension: {
    id: "installExtension",
    defaultMessage: `You must install the OneLogin Browser Extension for this site to work properly`
  },
  upgradeExtension: {
    id: "upgradeExtension",
    defaultMessage: `You must upgrade the OneLogin Browser Extension for this site to work properly`
  },
  upgradeNow: {
    id: "upgradeNow",
    defaultMessage: `Upgrade now`
  },
  mobileExtension: {
    id: "mobileExtension",
    defaultMessage: `Sign in to this app is not possible from a mobile browser. Please use a desktop browser.`
  }
});

const Bar = styled.div`
  background-color: #faa005;
  position: relative;
  min-height: 56px;
  color: #3f4040;
  font-size: 16px;
`;

const BarContent = styled.div`
  display: flex;
  padding: 9px 0;
  height: 100%;
  justify-content: center;
`;

const BarText = styled.div`
  flex: 1 1 auto;
  align-self: center;
`;

const InstallButton = styled.a`
  border: 1px solid #585959;
  border-radius: 2px;
  height: 38px;
  padding: 11px 16px;
  white-space: nowrap;
  color: #3f4040;
  line-height: 1;
  display: block;
  font-size: 14px;
  background-color: transparent;
  margin-left: 16px;

  &:hover {
    color: white;
    background-color: #585959;
  }

  &:active {
    color: white;
    background-color: #2d2e2e;
    border-color: #2d2e2e;
  }
`;

export const ExtensionBar = ({
  shouldInstall,
  shouldUpgrade,
  extensionUrl,
  isTouchDevice,
  intl
}) => {
  let message, buttonText;

  if (isTouchDevice) {
    message = messages.mobileExtension;
  } else if (shouldUpgrade) {
    message = messages.upgradeExtension;
    buttonText = messages.upgradeNow;
  } else if (shouldInstall) {
    message = messages.installExtension;
    buttonText = commonMessages.installNow;
  }

  return message ? (
    <Bar>
      <ResponsiveBlock>
        <BarContent>
          <BarText className="text">{intl.formatMessage(message)}</BarText>
          {buttonText && (
            <InstallButton
              href={extensionUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {intl.formatMessage(buttonText)}
            </InstallButton>
          )}
        </BarContent>
      </ResponsiveBlock>
    </Bar>
  ) : null;
};

ExtensionBar.propTypes = {
  shouldInstall: PropTypes.bool,
  shouldUpgrade: PropTypes.bool,
  extensionUrl: PropTypes.string,
  isTouchDevice: PropTypes.bool
};

export default injectIntl(ExtensionBar);
