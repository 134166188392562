import React from "react";
import PropTypes from "prop-types";

import AppsList from "./AppsList";
import MobileAppsList from "./MobileAppsList";
import KeyboardNavigator from "./KeyboardNavigator";

const AppsContent = ({
  data,
  resolveAttentionNeeded,
  showMobileView,
  selectedIndex,
  onEditClick,
  onAppLaunch,
  columnsCount,
  fetchPassword,
  isTouchDevice,
  focusEl,
  setSearchFocused,
  keysDisabled,
  resetSearch,
  onSelectedIndexChange
}) => {
  return (
    <>
      {!isTouchDevice ? (
        <KeyboardNavigator
          columnsCount={columnsCount}
          selectedIndex={selectedIndex}
          itemsCount={data.length}
          handlerDisabled={keysDisabled}
          onSelectionChange={onSelectedIndexChange}
          setSearchFocused={setSearchFocused}
          resetSearch={resetSearch}
        />
      ) : null}
      {showMobileView ? (
        <MobileAppsList
          apps={data}
          onAppClick={onAppLaunch}
          resolveAttentionNeeded={resolveAttentionNeeded}
          selectedIndex={selectedIndex}
          onEditClick={onEditClick}
          fetchPassword={fetchPassword}
          onSelectedIndexChange={onSelectedIndexChange}
          focusEl={focusEl}
        />
      ) : (
        <AppsList
          apps={data}
          onAppClick={onAppLaunch}
          resolveAttentionNeeded={resolveAttentionNeeded}
          selectedIndex={selectedIndex}
          onEditClick={onEditClick}
          isTouchDevice={isTouchDevice}
          onSelectedIndexChange={onSelectedIndexChange}
          focusEl={focusEl}
        />
      )}
    </>
  );
};

AppsContent.propTypes = {
  data: PropTypes.array.isRequired,
  resolveAttentionNeeded: PropTypes.func.isRequired,
  onSelectedIndexChange: PropTypes.func.isRequired,
  showMobileView: PropTypes.bool.isRequired,
  keysDisabled: PropTypes.bool.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  columnsCount: PropTypes.number.isRequired,
  onAppLaunch: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  fetchPassword: PropTypes.func.isRequired,
  focusEl: PropTypes.func.isRequired,
  setSearchFocused: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired,
  isTouchDevice: PropTypes.bool.isRequired
};

export default AppsContent;
