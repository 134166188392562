import { combineReducers } from "redux";

import apps from "./appsReducer";
import appEdit from "./appEditReducer";
import addApp from "./addAppReducer";
import connectors from "./connectorsReducer";
import appRemove from "./appRemoveReducer";
import user from "./userReducer";
import branding from "./brandingReducer";
import account from "./accountReducer";
import extension from "./extensionReducer";
import environment from "./environmentReducer";
import appStore from "./appStoreReducer";
import featureFlags from "./featureFlagsReducer";
import notifications from "./notificationsReducer";
import fatalError from "./fatalErrorReducer";
import frequents from "./frequentsReducer";
import navigator from "./navigatorReducer";
import { intlReducer } from "react-intl-redux";

const rootReducer = combineReducers({
  appEdit,
  apps,
  addApp,
  connectors,
  branding,
  account,
  extension,
  user,
  environment,
  appStore,
  appRemove,
  featureFlags,
  notifications,
  fatalError,
  frequents,
  navigator,
  intl: intlReducer
});

export default rootReducer;
