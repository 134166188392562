import React, { Component } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import PropTypes from "prop-types";
import classNames from "classnames";
import { injectIntl, defineMessages } from "react-intl";
import { LinkButton } from "@onelogin/react-components";

import FormFieldWithLabel, { supportedTypes } from "./FormFieldWithLabel";
import { getCharactersLeft } from "../../utils/charactersLeft";

import "./FormField.css";

const messages = defineMessages({
  notSupported: {
    id: "notSupported",
    defaultMessage: `Not supported in your browser`
  },
  charactersLeftMessage: {
    id: "charactersLeftMessage",
    defaultMessage: `{count} characters left`
  },
  copy: {
    id: "copyValue",
    defaultMessage: "Copy value"
  }
});

const nonCopyableTypes = ["boolean", "list", "multi_select"];

export class FormField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      copied: undefined,
      info: undefined
    };
  }

  componentWillUnmount() {
    clearTimeout(this.copiedTimeoutId);
  }

  onCopy = () => {
    this.setState({
      copied: true
    });
    this.copiedTimeoutId = setTimeout(
      () => {
        this.setState({
          copied: false
        });
      },
      this.props.copyUnsupportedBrowser ? 2000 : 1000
    );
  };

  onChange = event => {
    const { field, showCharactersLeft } = this.props;
    let { value } = event;

    if (showCharactersLeft && field.maxLength) {
      const charactersLeft = getCharactersLeft({
        maxLength: field.maxLength,
        value
      });

      value = value.substring(0, field.maxLength);
      const info =
        (charactersLeft !== undefined &&
          this.props.intl.formatMessage(messages.charactersLeftMessage, {
            count: charactersLeft.toString()
          })) ||
        "";

      this.setState({
        info
      });
    }

    field.onChange &&
      field.onChange({
        id: field.id,
        value
      });
  };

  render() {
    let {
      field,
      showCopy,
      copyUnsupportedBrowser,
      markFieldInvalid,
      intl
    } = this.props;

    showCopy = showCopy && !nonCopyableTypes.includes(field.type);

    const unsupportedCopied = copyUnsupportedBrowser && this.state.copied;
    const inputClasses = classNames({
      "form-row-control": true,
      shrinkable: unsupportedCopied,
      copyable: showCopy,
      copied: this.state.copied && !copyUnsupportedBrowser && field.value
    });

    return (
      supportedTypes.includes(field.type) && (
        <div className="form-field" key={field.id}>
          <div className={inputClasses}>
            <FormFieldWithLabel
              formFieldProps={{
                ...field,
                onChange: this.onChange,
                className: "onelogin-input",
                autoComplete: "off",
                invalid: markFieldInvalid
              }}
              info={this.state.info}
            />
            {showCopy ? (
              <div
                className={classNames({
                  "form-row-icon": true,
                  copied:
                    this.state.copied && !copyUnsupportedBrowser && field.value,
                  expandable: unsupportedCopied
                })}
              >
                <CopyToClipboard text={field.value} onCopy={this.onCopy}>
                  <LinkButton
                    className={classNames({
                      copy: true,
                      unsupported: copyUnsupportedBrowser,
                      inactive: !field.value
                    })}
                    onClick={() => {}}
                    aria-label={intl.formatMessage(messages.copy)}
                    title={intl.formatMessage(messages.copy)}
                  >
                    {unsupportedCopied
                      ? intl.formatMessage(messages.notSupported)
                      : null}
                  </LinkButton>
                </CopyToClipboard>
              </div>
            ) : null}
          </div>
        </div>
      )
    );
  }
}

FormField.propTypes = {
  showCopy: PropTypes.bool.isRequired,
  field: PropTypes.object.isRequired, //these are the html-input attributes; except `type` which is out representation of html-input type
  copyUnsupportedBrowser: PropTypes.bool,
  markFieldInvalid: PropTypes.bool,
  showCharactersLeft: PropTypes.bool
};

export default injectIntl(FormField);
