import * as types from "../constants/ActionTypes";
import { map, find, propEq } from "ramda";

export const initialState = {
  entities: {},
  loaded: undefined,
  appsViewType: undefined,
  hasPersonalApps: false
};

const mapParameters = (currentParameters, newParameters) => {
  return map(
    saveParam =>
      Object.assign(
        {},
        find(propEq("id", saveParam.id), currentParameters),
        saveParam
      ),
    newParameters
  );
};

function apps(state = initialState, action) {
  switch (action.type) {
    case types.SET_APPS_VIEW_TYPE:
      return {
        ...state,
        appsViewType: action.viewType
      };

    case types.SET_FREQUENTS: //this also holds frequents map
    case types.FREQUENTS_AVAILABLE_IN_LS: //just a notifier
      return {
        ...state,
        appsViewType: state.appsViewType || types.AppsViewType.FAVORITE_ONLY
      };

    case types.FREQUENTS_NOT_AVAILABLE:
      return {
        ...state,
        appsViewType: state.appsViewType || types.AppsViewType.COMPANY
      };

    case types.GET_APPS_DATA_RECEIVED:
      return {
        ...state,
        entities: map(
          app => Object.assign({}, state.entities[app.id], app),
          action.entities
        ),
        tabIds: action.tabIds,
        hasPersonalApps: action.hasPersonalApps,
        loaded: true
      };

    case types.FETCH_APP_REQUEST:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.appId]: Object.assign({}, state.entities[action.appId], {
            fetching: true,
            error: undefined
          })
        }
      };

    case types.FETCH_APP_RECEIVED:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.appId]: Object.assign(
            {},
            state.entities[action.appId], //keep values of previous entity (fetched probably by app-list)
            action.app,
            {
              fetching: false,
              fetched: true
            }
          )
        }
      };

    case types.FETCH_APP_ERROR:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.appId]: Object.assign({}, state.entities[action.appId], {
            fetching: false,
            error: action.messageDescriptor
          })
        }
      };

    case types.SAVE_PERSONAL_APP_FINISHED:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.appId]: Object.assign({}, state.entities[action.appId], {
            name: action.name,
            description: action.description,
            parameters: mapParameters(
              state.entities[action.appId].parameters,
              action.parameters
            )
          })
        }
      };

    case types.SAVE_COMPANY_APP_FINISHED:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.appId]: Object.assign({}, state.entities[action.appId], {
            parameters: mapParameters(
              state.entities[action.appId].parameters,
              action.parameters
            )
          })
        }
      };

    case types.GET_PASSWORD_REQUEST:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.appId]: Object.assign({}, state.entities[action.appId], {
            passwordFetching: true,
            error: null
          })
        }
      };

    case types.GET_PASSWORD_RECEIVED:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.appId]: Object.assign({}, state.entities[action.appId], {
            password: action.password,
            passwordFetching: false,
            passwordFetched: true
          })
        }
      };

    case types.GET_PASSWORD_ERROR:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.appId]: Object.assign({}, state.entities[action.appId], {
            passwordFetching: false
          })
        }
      };

    default:
      return state;
  }
}

export default apps;
