import * as apiConstants from "../constants/Api";
import * as types from "../constants/ActionTypes";

import { parseInitials } from "../utils/initials";

function user(state = { loading: true }, action) {
  switch (action.type) {
    case types.FETCH_USER_RECEIVED:
      const { user } = action;
      const { userSettings } = user;
      const { passwordExpiration = {} } = user;

      return {
        ...state,
        loading: false,
        username: user.name,
        firstName: user.name.split(" ")[0],
        initials: parseInitials(user.name),
        email: user.email,
        profilePicture: user.profilePicture,
        isAdmin: user.userType === apiConstants.ADMIN_USER_TYPE,
        isAssumed: !!user.assumedBy,
        hasAnyDelegatedAdminPrivileges: !!user.hasAnyDelegatedAdminPrivileges,
        assumedByAdmin: user.assumedBy === apiConstants.ADMIN,
        assumedBySandbox: user.assumedBy === apiConstants.SANDBOX,
        assumedBySuperadmin: user.assumedBy === apiConstants.SUPERADMIN,
        allowAddPersonalApp: !!userSettings.allow_add_personal_app,
        allowAddCompanyApp: !!userSettings.allow_add_company_app,
        installExtensionPermitted: !!userSettings.policy_enables_extension,
        locale: user.locale,
        passwordExpires: passwordExpiration.expires,
        passwordExpirationDays: Math.max(passwordExpiration.days, 0),
        showPasswordExpiresNotification:
          passwordExpiration.show_expiration_notifications,
        privilegedAccessApplications: user.privilegedAccessApplications
      };
    case types.FETCH_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}

export default user;
