import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import "./LinkButton.css";

const LinkButton = ({ text, onClick, className, tabIndex }) => (
  <button
    className={classNames({ "link-button": true, [className]: !!className })}
    onClick={onClick}
    type="button"
    tabIndex={tabIndex === undefined ? 0 : tabIndex}
  >
    {text}
  </button>
);

LinkButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  tabIndex: PropTypes.string
};

export default LinkButton;
