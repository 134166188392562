import { Component } from "react";
import PropTypes from "prop-types";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI (nothing in our case).
    return { hasError: true };
  }

  componentDidUpdate(props, state) {
    //to be able to 'recover' from error state, compare errorChildren to new props.children
    if (state.hasError === true && props.children !== this.errorChildren) {
      this.setState({ hasError: false });
    }
  }

  componentDidCatch(error, info) {
    const { airbrake } = this.context;

    if (airbrake && airbrake.notify) {
      airbrake.notify(error, {
        origin: info
      });
    }
  }

  render() {
    if (this.state.hasError) {
      this.errorChildren = this.props.children;
      return null;
    }

    return this.props.children;
  }
}

ErrorBoundary.contextTypes = {
  airbrake: PropTypes.object
};

export default ErrorBoundary;
