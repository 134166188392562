import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import CopyToClipboard from "react-copy-to-clipboard";
import { injectIntl, defineMessages } from "react-intl";

import "./AppRow.css";
import copyOkIcon from "../../svg/checkmark.svg";
import { MISSING_CONNECTOR_ICON } from "../../constants/Apps";

import { commonMessages } from "../../translations/commonMessages";

const messages = defineMessages({
  copyOkIcon: {
    id: "copyOkIcon",
    defaultMessage: `Copy OK icon`
  },
  copyIcon: {
    id: "copyIcon",
    defaultMessage: `Copy icon`
  }
});

const renderCopyPassword = (password, onCopy, intl) => {
  if (password) {
    return (
      <CopyToClipboard text={password} onCopy={onCopy}>
        <div className="app-row-menu-item copy" />
      </CopyToClipboard>
    );
  }

  // don't show copy password button if password is not available
  return null;
};

const renderCopyOk = intl => (
  <div className="app-row-menu-item copy-ok">
    <img src={copyOkIcon} alt={intl.formatMessage(messages.copyOkIcon)} />
  </div>
);

class AppRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      useFallback: undefined
    };
    this.ref = React.createRef();
  }

  componentDidMount() {
    if (this.props.isSelected) {
      this.doFocus();
    }
  }

  componentDidUpdate() {
    if (this.props.isSelected) {
      this.doFocus();
    }
  }

  onClick = event => {
    this.props.onAppClick({ event, app: this.props.app });
  };

  onEditClick = () => {
    this.props.onEditClick(this.props.app.id);
  };

  getIcon = () => {
    const { app, intl } = this.props;

    const iconSrc =
      app.icons.square_48x48 ||
      app.icons.square_24x24 ||
      MISSING_CONNECTOR_ICON;

    if (this.state.useFallback || iconSrc.includes(MISSING_CONNECTOR_ICON)) {
      return (
        <div className="sr-fallback-image">
          {app.name.charAt(0).toUpperCase()}
        </div>
      );
    }

    return (
      <img
        className="app-row-img"
        src={iconSrc}
        alt={intl.formatMessage(commonMessages.appIcon)}
        onError={() => {
          this.setState({ useFallback: true });
        }}
      />
    );
  };

  doFocus = () => {
    this.props.focusEl(this.ref.current);
  };

  render() {
    const {
      app,
      showMenu,
      onMenuClick,
      needAttention,
      showCopyOK,
      onCopyPassword,
      onFocus,
      intl
    } = this.props;

    const appRowClasses = classNames({
      "app-row": true,
      "app-row-need-attention": needAttention
    });

    return (
      <div className={appRowClasses}>
        <a
          className={classNames({
            "app-row-link": true,
            "menu-active": showMenu
          })}
          href={app.startUrl}
          target={!app.gdtRequired && "_blank"}
          rel={!app.gdtRequired && "noopener noreferrer"}
          onClick={this.onClick}
          onFocus={onFocus}
          tabIndex={0}
          ref={this.ref}
        >
          <div className="app-row-img-container">{this.getIcon()}</div>
          <div className="app-row-description">
            <div className="app-row-appname">{app.name}</div>
          </div>
        </a>
        {!app.isEditable ? null : (
          <div
            className={classNames({
              "app-row-control": true,
              "menu-active": showMenu
            })}
          >
            {showMenu && (
              <div className="app-row-menu-content">
                {showCopyOK
                  ? renderCopyOk(intl)
                  : renderCopyPassword(app.password, onCopyPassword, intl)}
                <div
                  className="app-row-menu-item edit"
                  onClick={this.onEditClick}
                />
              </div>
            )}
            <div className="app-row-menu-toggle" onClick={onMenuClick} />
          </div>
        )}
      </div>
    );
  }
}

AppRow.propTypes = {
  app: PropTypes.object.isRequired,
  onMenuClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  showMenu: PropTypes.bool.isRequired,
  onAppClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  needAttention: PropTypes.bool.isRequired,
  showCopyOK: PropTypes.bool.isRequired,
  onCopyPassword: PropTypes.func.isRequired,
  focusEl: PropTypes.func.isRequired
};

export default injectIntl(AppRow);
