import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, defineMessages } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PortalRedirect from "./PortalRedirect";
import { Button } from "@onelogin/react-components";
import styled from "styled-components";
import FormValidationHelper from "./appForm/FormValidationHelper";
import FormField from "./appForm/FormField";
import { submitHelpRequestThenGotoApps } from "../actions/otherActions";
import { gotoApps } from "../actions/navigatorActions";
import ErrorBoundary from "./ErrorBoundary";
import ResponsiveBlock from "./ResponsiveBlock";

import "./GetHelpDialog.css";

import { commonMessages } from "../translations/commonMessages";

const ReactMarkdown = require("react-markdown");

const SUPPORT_FIELD = "supportRequest";

const messages = defineMessages({
  supportRequestHeader: {
    id: "supportRequestHeader",
    defaultMessage: "Support Request"
  },
  supportDefaultDescription: {
    id: "supportDefaultDescription",
    defaultMessage:
      "Please describe the issue you are experiencing and send it to your OneLogin administrator."
  },
  requestField: {
    id: "requestField",
    defaultMessage: "Describe the issue you're having"
  }
});

const ButtonWrap = styled.div`
  text-align: right;
  padding-top: 15px;
  padding-bottom: 15px;
`;

export class GetHelpDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        supportRequest: { value: "" }
      },
      submitted: undefined,
      formValidity: false
    };

    this.formValidationHelper = new FormValidationHelper();
  }

  componentDidMount() {
    this.formValidationHelper.register({
      id: "supportRequest",
      value: "",
      validationObject: { required: true }
    });
  }

  onFieldChange = (id, value, formValidity) => {
    var formData = { ...this.state.formData };
    formData[id].value = value;
    this.setState({ formData });
    this.setState({ formValidity: formValidity });
  };

  handleSubmit = () => {
    //to blink on save button click when already submitted
    if (this.state.submitted) {
      this.setState({ submitted: false });
      this.submitTimeoutId = setTimeout(() => {
        this.setState({ submitted: true });
      }, 100);
    } else {
      this.setState({ submitted: true });
    }

    if (this.state.formValidity) {
      this.props.onHelpSubmit(this.state.formData.supportRequest);
    }
  };

  onCancel = () => {
    //when user is navigating through application, history.length grows, so user can actually go back when he clicks 'cancel'
    //when there's no history (user clicked bookmark or email-link) navigate him to apps instead of 'nothing' or 'browser empty page'
    if (this.props.history.length > 1) {
      this.props.history.goBack();
    } else {
      this.props.gotoApps();
    }
  };

  validateOnFieldChange = event => {
    const { id, value } = event;
    this.formValidationHelper.updateValidity({ id, value });
    this.onFieldChange(id, value, !this.formValidationHelper.isInvalid());
  };

  render() {
    const { account, intl, redirectPath } = this.props;

    if (redirectPath) {
      return <PortalRedirect path={redirectPath} />;
    }

    return (
      <ErrorBoundary>
        <div className="help-dialog">
          <ResponsiveBlock>
            <div className="help-header">
              <div className="help-header-left">
                {intl.formatMessage(messages.supportRequestHeader)}
              </div>
            </div>
          </ResponsiveBlock>
          <div className="help-content">
            <ResponsiveBlock>
              <div className="two-column-container">
                <div className="left-column-container">
                  <div className="support-request-description">
                    <ReactMarkdown
                      source={
                        account.helpInstruction ||
                        intl.formatMessage(messages.supportDefaultDescription)
                      }
                    />
                  </div>
                </div>
                <div className="right-column-container">
                  {
                    <FormField
                      markFieldInvalid={
                        this.formValidationHelper.getValidity(SUPPORT_FIELD)
                          .invalid && this.state.submitted
                      }
                      key={"supportRequest"}
                      field={{
                        id: "supportRequest",
                        name: "supportRequest",
                        label: intl.formatMessage(messages.requestField),
                        type: "textarea",
                        value: this.state.formData[SUPPORT_FIELD].value || "",
                        autoFocus: true,
                        onChange: this.validateOnFieldChange,
                        required: true,
                        readOnly: false,
                        rows: 20,
                        maxLength: 2000
                      }}
                      showCopy={false}
                    />
                  }
                </div>
              </div>
            </ResponsiveBlock>
            <div className="footer-buttons">
              <ResponsiveBlock>
                <ButtonWrap>
                  <Button onClick={this.onCancel}>
                    {intl.formatMessage(commonMessages.cancel)}
                  </Button>
                  <Button onClick={this.handleSubmit} look="primary">
                    {intl.formatMessage(commonMessages.submit)}
                  </Button>
                </ButtonWrap>
              </ResponsiveBlock>
            </div>
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}

GetHelpDialog.propTypes = {
  account: PropTypes.object
};

const mapStateToProps = (state, ownProps) => {
  return {
    account: state.account,
    redirectPath: state.navigator.redirectPath
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onHelpSubmit: formData => {
    dispatch(
      submitHelpRequestThenGotoApps({
        supportRequest: formData.value
      })
    );
  },
  gotoApps: () => {
    dispatch(gotoApps());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withRouter(GetHelpDialog)));
