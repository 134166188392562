import client from "./httpClient";
import { SERVER_URL } from "../constants/Api";
import { normalizeError } from "./apiErrorHandling";

export const fetchAllConnectors = async () => {
  try {
    return await client.get(`${SERVER_URL}/apps-service/connectors/all`).json();
  } catch (error) {
    throw await normalizeError(error);
  }
};

export const fetchFormBasedConnectors = async () => {
  try {
    return await client.get(`${SERVER_URL}/apps-service/connectors`).json();
  } catch (error) {
    throw await normalizeError(error);
  }
};

export const fetchGenericConnector = async () => {
  try {
    return await client
      .get(`${SERVER_URL}/apps-service/connectors/generic`)
      .json();
  } catch (error) {
    throw await normalizeError(error);
  }
};
