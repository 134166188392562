//TODO: styles

import React from "react";
import PropTypes from "prop-types";
import { injectIntl, defineMessages, FormattedMessage } from "react-intl";

const messages = defineMessages({
  editSite: {
    id: "editSite",
    defaultMessage: `Edit Site`
  },
  addSite: {
    id: "addSite",
    defaultMessage: `Add {appName}`
  },
  privateSite: {
    id: "privateSite",
    defaultMessage: `This is for your private use`
  },
  companySite: {
    id: "companySite",
    defaultMessage: `This is for {companyName}`
  }
});

export const AppFormHeader = ({
  isAddApp,
  appName,
  isPrivate,
  companyName,
  intl
}) => {
  return (
    <div className="form-header">
      <div className="form-header-left">
        {isAddApp ? (
          <FormattedMessage
            id="addSite"
            values={{
              appName: <span className="form-header-left-name">{appName}</span>
            }}
          />
        ) : (
          intl.formatMessage(messages.editSite)
        )}
      </div>

      <div className="form-header-right">
        {isPrivate
          ? intl.formatMessage(messages.privateSite)
          : intl.formatMessage(messages.companySite, { companyName })}
      </div>
    </div>
  );
};

AppFormHeader.propTypes = {
  isAddApp: PropTypes.bool,
  isPrivate: PropTypes.bool,
  companyName: PropTypes.string,
  appName: PropTypes.string
};

export default injectIntl(AppFormHeader);
