import React, { useLayoutEffect } from "react";
import PropTypes from "prop-types";

import NoExtensionWarning from "./NoExtensionWarning";
import InstallExtensionDialog from "./InstallExtensionDialog";

export const NoExtension = ({
  isTouchDevice,
  onNoExtensionOk,
  onExtensionInstall,
  onExtensionCancel,
  togglePageFocus,
  installExtensionPermitted
}) => {
  useLayoutEffect(() => {
    togglePageFocus({ focus: false });

    return () => {
      togglePageFocus({ focus: true });
    };
  });

  return isTouchDevice ? (
    <NoExtensionWarning onOk={onNoExtensionOk} />
  ) : (
    <InstallExtensionDialog
      installExtensionPermitted={installExtensionPermitted}
      onInstall={onExtensionInstall}
      onCancel={onExtensionCancel}
    />
  );
};

NoExtension.propTypes = {
  isTouchDevice: PropTypes.bool,
  onNoExtensionOk: PropTypes.func.isRequired,
  onExtensionInstall: PropTypes.func.isRequired,
  onExtensionCancel: PropTypes.func.isRequired,
  togglePageFocus: PropTypes.func.isRequired,
  installExtensionPermitted: PropTypes.bool
};
