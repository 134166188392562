import * as types from "../constants/ActionTypes";
import * as formServiceApi from "../api/formServiceApi";
import { gotoApps } from "../actions/navigatorActions";
import * as eventsEmitter from "../utils/eventsEmitter";
import { defineMessages } from "react-intl";

const messages = defineMessages({
  removeFailed: {
    id: "removeFailed",
    defaultMessage: `Removing failed. Please try again later.`
  }
});

const removeApp = ({ appId }) => (dispatch, getState) => {
  dispatch({
    type: types.REMOVE_APP_REQUEST
  });

  return formServiceApi
    .removeApp({
      appId
    })
    .then(
      result => {
        dispatch({
          type: types.REMOVE_APP_FINISHED,
          result
        });

        eventsEmitter.appsChanged();

        return true;
      },
      error => {
        dispatch({
          type: types.REMOVE_APP_ERROR,
          messageDescriptor: messages.removeFailed
        });

        return false;
      }
    );
};

export const removeAppThenGotoApps = config => (dispatch, getState) => {
  dispatch(removeApp(config)).then(result => {
    if (result) {
      dispatch(gotoApps());
    }
  });
};

export const resetForm = () => (dispatch, getState) => {
  dispatch({
    type: types.RESET_APP_FORM
  });
};

export const resetRemoveApp = () => (dispatch, getState) => {
  dispatch({
    type: types.RESET_APP_REMOVE
  });
};
