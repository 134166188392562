import React, { Fragment } from "react";
import styled from "styled-components";
import { Icon } from "@onelogin/react-components";

const MainMessage = styled.div`
  font-size: 16px;
  color: #3f4040;
  margin-top: 16px;
`;

const AlertDialogContent = ({ children }) => (
  <Fragment>
    <Icon name="Alert" size="48px" />
    <MainMessage>{children}</MainMessage>
  </Fragment>
);

export default AlertDialogContent;
