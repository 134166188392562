const CHARACTERS_LEFT_TRIGGER = 25;

export const getCharactersLeft = ({ maxLength, value, intl }) => {
  if (maxLength) {
    const charactersLeft = Math.max(maxLength - value.length, 0);

    if (charactersLeft <= CHARACTERS_LEFT_TRIGGER) {
      return charactersLeft;
    }
  }
};
