import { fetchNewCsrfCookie } from "./csrfServiceApi";
const X_CSRF_TOKEN = "x-csrf-token";

export const getCsrfCookie = async () => {
  let cookieValue = findCookieValue(X_CSRF_TOKEN);
  if (!cookieValue) {
    await fetchNewCsrfCookie();
    cookieValue = findCookieValue(X_CSRF_TOKEN);
  }

  return cookieValue;
};

const findCookieValue = name => {
  const cookie = {};

  document.cookie.split(";").forEach(function (el) {
    let [k, v] = el.split("=");
    cookie[k.trim()] = v;
  });

  return cookie[name];
};
