import * as types from "../constants/ActionTypes";

const initialState = {
  connectors: [],
  topConnectors: [],
  loading: false,
  error: undefined
};

function appStore(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_CONNECTORS_RECEIVED:
      return {
        ...state,
        loading: false,
        connectors: action.connectors,
        topConnectors: action.topConnectors
      };
    case types.FETCH_CONNECTORS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.messageDescriptor
      };
    case types.FETCH_CONNECTORS_REQUEST:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}

export default appStore;
