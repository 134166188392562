import React, { Component } from "react";
import PropTypes from "prop-types";
import AppRow from "./AppRow";
import "./MobileAppsList.css";

class MobileAppsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appIdWithMenu: -1,
      lastScrollIndex: -1,
      appIdWithCopiedIcon: -1
    };
  }

  getOnPasswordCopied = appId => () => {
    this.setState({
      appIdWithCopiedIcon: appId
    });

    setTimeout(() => {
      this.setState({ appIdWithCopiedIcon: -1 });
    }, 750);
  };

  getOnMenuClick = appId => () => {
    if (appId === this.state.appIdWithMenu) {
      this.setState({ appIdWithMenu: -1 });
    } else {
      this.setState({ appIdWithMenu: appId });
      this.props.fetchPassword(appId);
    }
  };

  render() {
    const {
      apps,
      selectedIndex,
      onEditClick,
      onAppClick,
      resolveAttentionNeeded,
      focusEl,
      onSelectedIndexChange
    } = this.props;

    return (
      <div className="mobile-apps-list">
        {apps.map((app, index) => {
          const showMenu = app.id === this.state.appIdWithMenu;
          return (
            <AppRow
              key={app.id}
              app={app}
              isSelected={index === selectedIndex}
              showMenu={showMenu}
              onAppClick={onAppClick}
              onMenuClick={this.getOnMenuClick(app.id)}
              onEditClick={onEditClick}
              onFocus={() => {
                onSelectedIndexChange(index);
              }}
              needAttention={resolveAttentionNeeded(app)}
              showCopyOK={this.state.appIdWithCopiedIcon === app.id}
              onCopyPassword={this.getOnPasswordCopied(app.id)}
              focusEl={focusEl}
            />
          );
        })}
      </div>
    );
  }
}

MobileAppsList.propTypes = {
  apps: PropTypes.arrayOf(PropTypes.object).isRequired,
  onAppClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  selectedIndex: PropTypes.number,
  resolveAttentionNeeded: PropTypes.func.isRequired,
  onSelectedIndexChange: PropTypes.func.isRequired,
  fetchPassword: PropTypes.func.isRequired,
  focusEl: PropTypes.func.isRequired
};

MobileAppsList.defaultProps = {
  selectedIndex: -1
};

export default MobileAppsList;
