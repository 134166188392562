import * as types from "../constants/ActionTypes";

const initialState = {
  saving: undefined
};

//TODO: in next phase of form refactoring, this could be merged to appFormReducer and used for both addApp and appEdit
function appEdit(state = initialState, action) {
  switch (action.type) {
    case types.RESET_APP_FORM:
      return {
        ...state,
        ...initialState
      };
    case types.SAVE_APP_REQUEST:
      return {
        ...state,
        saving: true
      };

    case types.SAVE_PERSONAL_APP_FINISHED:
    case types.SAVE_COMPANY_APP_FINISHED:
      return {
        ...state,
        saving: false
      };

    case types.SAVE_APP_ERROR:
      return {
        ...state,
        saving: false
      };

    default:
      return state;
  }
}

export default appEdit;
