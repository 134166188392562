import React from "react";
import * as types from "../constants/ActionTypes";
import * as formServiceApi from "../api/formServiceApi";
import { gotoApps } from "../actions/navigatorActions";
import { notify, notifyError } from "../actions/notificationsActions";
import * as eventsEmitter from "../utils/eventsEmitter";
import * as apiConstants from "../constants/Api";
import { map } from "ramda";
import { defineMessages, FormattedMessage } from "react-intl";

const messages = defineMessages({
  saveFailed: {
    id: "saveFailed",
    defaultMessage: `App was not saved. Please try again later.`
  },
  createFailed: {
    id: "createFailed",
    defaultMessage: `App was not created. Please try again later.`
  },
  createSuccessful: {
    id: "createSuccessful",
    defaultMessage: `{appName} was sucessfully added.`
  }
});

const APP_ADDED_TIMEOUT = 2000;

export const removeParameterIdPrefix = parameters => {
  if (!parameters) {
    return parameters;
  }

  return map(
    parameter => ({
      ...parameter,
      id: parameter.id.replace(apiConstants.PARAMETER_ID_PREFIX, "")
    }),
    parameters
  );
};

const saveApp = ({ dispatch, requestConfig, type }) => {
  dispatch({
    type: types.SAVE_APP_REQUEST
  });

  return formServiceApi
    .saveApp({
      ...requestConfig,
      body: {
        ...requestConfig.body,
        parameters: removeParameterIdPrefix(requestConfig.body.parameters)
      }
    })
    .then(
      result => {
        dispatch({
          appId: requestConfig.appId,
          name: requestConfig.body.name, //in case of company save type name is empty; reducer based on type ignores the name completely in the following step
          description: requestConfig.body.description,
          parameters: requestConfig.body.parameters,
          type,
          result
        });

        eventsEmitter.appsChanged();

        return true;
      },
      error => {
        dispatch({
          type: types.SAVE_APP_ERROR
        });

        dispatch(
          notifyError({
            message: error.message ? (
              error.message
            ) : (
              <FormattedMessage {...messages.saveFailed} />
            )
          })
        );

        return false;
      }
    );
};

export const savePersonalApp = ({ name, parameters, description, appId }) => (
  dispatch,
  getState
) => {
  return saveApp({
    requestConfig: {
      appId,
      body: {
        name,
        description,
        parameters
      }
    },
    type: types.SAVE_PERSONAL_APP_FINISHED,
    dispatch
  });
};

export const saveCompanyApp = ({ parameters, appId }) => (
  dispatch,
  getState
) => {
  return saveApp({
    requestConfig: {
      appId,
      body: {
        parameters
      }
    },
    type: types.SAVE_COMPANY_APP_FINISHED,
    dispatch
  });
};

const createApp = ({
  name,
  parameters,
  description,
  isPrivate,
  connectorId
}) => (dispatch, getState) => {
  dispatch({
    type: types.CREATE_APP_REQUEST
  });

  return formServiceApi
    .createApp({
      body: {
        name,
        description,
        isPrivate,
        connectorId,
        parameters: removeParameterIdPrefix(parameters)
      }
    })
    .then(
      result => {
        dispatch({
          type: types.CREATE_APP_FINISHED,
          name
        });

        return true;
      },
      error => {
        dispatch({
          type: types.CREATE_APP_ERROR
        });

        dispatch(
          notifyError({
            message: error.message ? (
              error.message
            ) : (
              <FormattedMessage {...messages.createFailed} />
            )
          })
        );

        return false;
      }
    );
};

export const createAppThenGotoApps = config => (dispatch, getState) => {
  dispatch(createApp(config)).then(result => {
    if (result) {
      dispatch(gotoApps());
      dispatch(
        notify({
          message: (
            <FormattedMessage
              {...messages.createSuccessful}
              values={{
                appName: config.name
              }}
            />
          ),
          dismissDelay: APP_ADDED_TIMEOUT
        })
      );
    }
  });
};

export const savePersonalAppThenGotoApps = config => (dispatch, getState) => {
  dispatch(savePersonalApp(config)).then(result => {
    if (result) {
      dispatch(gotoApps());
    }
  });
};

export const saveCompanyAppThenGotoApps = config => (dispatch, getState) => {
  dispatch(saveCompanyApp(config)).then(result => {
    if (result) {
      dispatch(gotoApps());
    }
  });
};
