import { defineMessages } from "react-intl";

export const commonMessages = defineMessages({
  ok: {
    id: "ok",
    defaultMessage: `Ok`
  },
  cancel: {
    id: "cancel",
    defaultMessage: `Cancel`
  },
  editIcon: {
    id: "editIcon",
    defaultMessage: `Edit icon`
  },
  appIcon: {
    id: "appIcon",
    defaultMessage: `App icon`
  },
  menuIcon: {
    id: "menuIcon",
    defaultMessage: `Menu icon`
  },
  personal: {
    id: "personal",
    defaultMessage: `Personal`
  },
  company: {
    id: "company",
    defaultMessage: `Company`
  },
  logout: {
    id: "logout",
    defaultMessage: `Log out`
  },
  installNow: {
    id: "installNow",
    defaultMessage: `Install now`
  },
  submit: {
    id: "submit",
    defaultMessage: `Submit`
  },
  close: {
    id: "close",
    defaultMessage: `Close`
  },
  browserExtensionRequired: {
    id: "browserExtensionRequired",
    defaultMessage: "Browser Extension Required"
  }
});
