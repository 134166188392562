import { defineMessages, injectIntl } from "react-intl";

import FormFieldLabel from "../appForm/FormFieldLabel";
import PropTypes from "prop-types";
import React from "react";
import { TextField } from "@onelogin/react-components";
import { selectUnit } from "../../utils/selectUnit";
import styled from "styled-components";

const messages = defineMessages({
  lastModified: {
    id: "lastModified",
    defaultMessage: `Modified {date}`
  },
  created: {
    id: "created",
    defaultMessage: `Created {date}`
  },
  history: {
    id: "history",
    defaultMessage: `History`
  }
});

const StyledTextField = styled(TextField)`
  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const HistoryField = ({ updatedAt, createdAt, intl }) => {

  const formatRelative = (date) => {
    if (date) {
      const { value, unit } = selectUnit(new Date(date) - 48 * 3600 * 1000);
      return intl.formatRelativeTime(value, unit);
    }
    return "";
  };

  return (
    <div>
      {(updatedAt || createdAt) && (
        <FormFieldLabel label={intl.formatMessage(messages.history)} />
      )}
      {updatedAt && (
        <StyledTextField>
          {intl.formatMessage(messages.lastModified, {
            date: formatRelative(updatedAt)
          })}
        </StyledTextField>
      )}
      {createdAt && (
        <StyledTextField>
          {intl.formatMessage(messages.created, {
            date: formatRelative(createdAt)
          })}
        </StyledTextField>
      )}
    </div>
  );
};

HistoryField.propTypes = {
  updatedAt: PropTypes.string,
  createdAt: PropTypes.string
};

export default injectIntl(HistoryField);
