const sendGAEvent = event => {
  window.ga && window.ga("send", "event", event);
};

export const appsViewFilterSwitched = () => {
  sendGAEvent({
    eventCategory: "Portal.AppsList",
    eventAction: "viewFilterSwitched"
  });
};

export const appUsed = (trigger, serchTerm) => {
  sendGAEvent({
    eventCategory: "Portal.AppsList",
    eventAction: "appUsed",
    eventLabel: trigger,
    eventValue: serchTerm ? serchTerm.length : 0
  });
};

export const enteredAppEdit = serchTerm => {
  sendGAEvent({
    eventCategory: "Portal.AppsList",
    eventAction: "enteredAppEdit",
    eventLabel: "click",
    eventValue: serchTerm ? serchTerm.length : 0
  });
};

export const portalLoaded = () => {
  sendGAEvent({
    eventCategory: "Portal",
    eventAction: "portalLoaded"
  });
};

// user pressed install extension button
export const installExtension = () => {
  sendGAEvent({
    eventCategory: "Portal",
    eventAction: "installExtension"
  });
};
