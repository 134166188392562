import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { pick, omit, keys, defaultTo, forEachObjIndexed, equals } from "ramda";
import { injectIntl, defineMessages } from "react-intl";
import classNames from "classnames";

import FormValidationHelper from "./FormValidationHelper";
import AppTileLogo from "../apps/AppTileLogo";

import FormField from "./FormField";
import AppFormHeader from "./AppFormHeader";
import ExtensionBar from "./ExtensionBar";
import AppFormButtons from "./AppFormButtons";
import HistoryField from "../appEdit/HistoryField";
import ResponsiveBlock from "../ResponsiveBlock";

import "./AppForm.css";

const DESCRIPTION_FIELD_LIMIT = 200;

const APP_ID = "name";
const DESCRIPTION_ID = "description";
const PRIVACY_SELECTOR_ID = "isPrivate";

const NON_VALIDABLE_TYPES = ["boolean", "privacySelector"];

const messages = defineMessages({
  name: {
    id: "name",
    defaultMessage: `Name`
  },
  description: {
    id: "description",
    defaultMessage: `Description`
  }
});

export class AppForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {},
      changedFields: {},
      submitted: undefined
    };

    this.formValidationHelper = new FormValidationHelper();
  }

  componentDidMount() {
    //set initial state when formData ready (on edit page reload)
    this.initForm(this.props);
  }

  componentWillUnmount() {
    clearTimeout(this.submitTimeoutId);
  }

  componentDidUpdate(prev) {
    //while saving, state in redux store is obsolete (new values are written in successful callback);
    //do not initialize the form with these obsolete values
    if (this.props.saving) {
      return;
    }

    //set initial state when formData are received and rest of form is already rendered (e.g. on enter from app-list)
    this.initForm(this.props);
  }

  initForm(props) {
    this.setColumnItems(props);
    const formDataObject = this.getFormDataObject(props);
    const formData = {
      ...this.state.formData, //form is reinited on submit, but we want to keep state values, otherwise the validator goes to validate the redux-store values (original ones)
      ...omit(keys(this.state.changedFields), formDataObject)
    };

    if (!equals(formData, this.state.formData)) {
      this.setState({ formData });
      this.initFieldsValidity(formData);
    }
  }

  setColumnItems(props) {
    const {
      isPrivate,
      isAddApp,
      allowAddPersonalApp,
      allowAddCompanyApp,
      intl
    } = props;
    this.leftColumnFields = [
      {
        input: {
          required: isAddApp || isPrivate,
          label: intl.formatMessage(messages.name),
          id: APP_ID,
          type: !isAddApp && !isPrivate ? "infoText" : "text"
        }
      }
    ];

    if (isPrivate || isAddApp) {
      this.leftColumnFields.push({
        input: {
          id: DESCRIPTION_ID,
          label: intl.formatMessage(messages.description),
          type: "textarea",
          maxLength: DESCRIPTION_FIELD_LIMIT
        },
        showCharactersLeft: true
      });
    }

    this.rightColumnFields = [];

    if (isAddApp) {
      let isPrivateDefaultValue = true;

      if (allowAddCompanyApp && !allowAddPersonalApp) {
        isPrivateDefaultValue = false;
      }
      this.rightColumnFields.push({
        input: {
          id: PRIVACY_SELECTOR_ID,
          type: "privacySelector"
        },
        defaultValue:
          isPrivate !== undefined ? isPrivate : isPrivateDefaultValue,
        showCopy: false,
        visible: allowAddPersonalApp && allowAddCompanyApp
      });
    }

    this.rightColumnFields = [
      ...this.rightColumnFields,
      ...(props.app.parameters || []).map(parameter => ({
        input: { ...parameter },
        isParameter: true
      }))
    ];
  }

  //TODO: AppFormData
  getFormDataObject(props) {
    //array of app fields reduced to object for better handling in form
    const initialLeftColumntFormData = this.leftColumnFields.reduce(
      (accumulator, { input }) => {
        accumulator[input.id] = {
          ...input,
          value: props.app[input.id]
        };
        return accumulator;
      },
      {}
    );

    //array of params reduced to object for better handling in form + isPrivacy selector for addApp
    const initialRightColumnFormData = this.rightColumnFields.reduce(
      (accumulator, { input, defaultValue }) => {
        accumulator[input.id] = {
          ...input,
          value: input.value === undefined ? defaultValue : input.value
        };
        return accumulator;
      },
      {}
    );

    return {
      ...initialLeftColumntFormData,
      ...initialRightColumnFormData
    };
  }

  //TODO: move to validation helper?
  initFieldsValidity(formData) {
    forEachObjIndexed((param, key) => {
      if (NON_VALIDABLE_TYPES.includes(param.type)) {
        return;
      }
      this.formValidationHelper.register({
        id: key,
        value: param.value,
        validationObject: pick(["required", "format"], param)
      });
    }, formData);
  }

  updateFieldValidity = ({ id, value }) => {
    this.formValidationHelper.updateValidity({ id, value });
  };

  onFieldChange = ({ id, value }) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [id]: {
          ...this.state.formData[id],
          value
        }
      },
      changedFields: {
        ...this.state.changedFields,
        [id]: true
      }
    });

    this.updateFieldValidity({ id, value });
  };

  onFieldBlur = ({ id, value }) => {
    this.updateFieldValidity({ id, value });
  };

  onSubmit = event => {
    event.preventDefault();

    //to blink on save button click when already submitted
    if (this.state.submitted) {
      this.setState({ submitted: false });
      this.submitTimeoutId = setTimeout(() => {
        this.setState({ submitted: true });
      }, 100);
    } else {
      this.setState({ submitted: true });
    }

    if (!this.formValidationHelper.isInvalid()) {
      const toSubmit = {};
      //TODO: AppFormData.getValues()?
      [...this.leftColumnFields, ...this.rightColumnFields].forEach(
        ({ input, isParameter }) => {
          if (input.type === "infoText") {
            return;
          }

          const stateValue = this.state.formData[input.id].value;

          const value =
            stateValue !== null && stateValue !== undefined ? stateValue : "";

          if (isParameter) {
            toSubmit.parameters = toSubmit.parameters || [];
            toSubmit.parameters.push({
              id: input.id,
              value: value
            });
          } else {
            toSubmit[input.id] = value;
          }
        }
      );

      this.props.onSubmit(toSubmit);
    }
  };

  onCancel = event => {
    event.preventDefault();
    this.props.onCancel();
  };

  onRemove = event => {
    event.preventDefault();
    this.props.onRemove();
  };

  renderFields = (fields, showCopy) => {
    if (!fields) {
      return null;
    }
    return fields.map(
      ({ input, visible, showCopy: fieldShowCopy, ...fieldData }) => {
        if (visible === false) {
          return null;
        }
        fieldShowCopy = fieldShowCopy !== undefined ? fieldShowCopy : true;
        if (input && input.type === "password" && !this.props.editable) {
          fieldShowCopy = false;
        }
        return (
          <FormField
            key={input.id}
            field={{
              ...input,
              value: defaultTo("", this.state.formData[input.id].value),
              onChange: this.onFieldChange,
              onBlur: this.onFieldBlur,
              disabled: !this.props.editable
            }}
            {...fieldData}
            markFieldInvalid={
              this.formValidationHelper.getValidity(input.id).invalid &&
              this.state.submitted
            }
            showCopy={showCopy !== undefined ? showCopy : fieldShowCopy}
            copyUnsupportedBrowser={this.props.copyUnsupportedBrowser}
          />
        );
      }
    );
  };

  render() {
    const {
      saving,
      app,
      editable,
      isPrivate,
      isAddApp,
      branding,
      extension,
      companyName,
      isTouchDevice,
      allowAddPersonalApp
    } = this.props;

    const rightColumnClasses = classNames({
      "form-container": true,
      shrinkable: true,
      "shifted-top": !isAddApp
    });

    return (
      <form onSubmit={this.onSubmit} className="app-form">
        <ResponsiveBlock>
          <AppFormHeader
            isPrivate={
              isPrivate ||
              (isAddApp &&
                allowAddPersonalApp &&
                this.state.formData[PRIVACY_SELECTOR_ID] &&
                this.state.formData[PRIVACY_SELECTOR_ID].value)
            }
            companyName={companyName}
            appName={app.name}
            isAddApp={isAddApp}
          />
        </ResponsiveBlock>

        <div className="app-form-content">
          {app.extensionRequired ? (
            <ExtensionBar
              shouldInstall={extension.shouldExtensionBeInstalled}
              shouldUpgrade={extension.shouldExtensionBeUpdgraded}
              extensionUrl={extension.browserExtensionUrl}
              isTouchDevice={isTouchDevice}
            />
          ) : null}

          <ResponsiveBlock>
            <div className="form-two-columns-wrap">
              <div className="form-container narrow">
                <div className="app-icon-wrap">
                  <AppTileLogo icons={app.icons} appName={app.name} />
                </div>
                {this.renderFields(this.leftColumnFields, false)}
                {!isAddApp ? (
                  <HistoryField
                    createdAt={app.createdAt}
                    updatedAt={app.updatedAt}
                  />
                ) : null}
              </div>

              <div className={rightColumnClasses}>
                {this.renderFields(this.rightColumnFields)}
              </div>
            </div>
          </ResponsiveBlock>

          <AppFormButtons
            showRemoveButton={!isAddApp && isPrivate}
            onRemove={this.onRemove}
            editable={editable}
            onSubmit={this.onSubmit}
            saving={saving}
            branding={branding}
            onCancel={this.onCancel}
          />
        </div>
      </form>
    );
  }
}

AppForm.propTypes = {
  saving: PropTypes.bool,
  isAddApp: PropTypes.bool,
  editable: PropTypes.bool.isRequired,
  isPrivate: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  branding: PropTypes.object,
  extension: PropTypes.object.isRequired,
  app: PropTypes.object,
  companyName: PropTypes.string,
  allowAddPersonalApp: PropTypes.bool,
  allowAddCompanyApp: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => {
  const app = ownProps.app;

  return {
    onCancel: ownProps.onCancel,
    onRemove: ownProps.onRemove,
    editable: ownProps.editable,
    isPrivate: app.isPrivate,
    extension: state.extension,
    saving: ownProps.saving || state.appEdit.saving, //TODO: this needs to be refactored
    branding: state.branding,
    allowAddPersonalApp: state.user.allowAddPersonalApp,
    allowAddCompanyApp: state.user.allowAddCompanyApp,
    companyName: state.account.name,
    copyUnsupportedBrowser: state.environment.copyUnsupportedBrowser,
    isTouchDevice: state.environment.isTouchDevice
  };
};

export default connect(mapStateToProps)(injectIntl(AppForm));
