import Bowser from "bowser";

import * as types from "../constants/ActionTypes";
import * as store from "../storage/localStorage";
import * as extensionConsts from "../constants/Extension";
import * as pluginVersionValidator from "../utils/PluginVersionValidator";

const getBrowserExtensionUrl = () => {
  const browser = Bowser.getParser(window.navigator.userAgent);
  switch (true) {
    case browser.is("chrome", true):
      return extensionConsts.CHROME_EXTENSION_DOWNLOAD_LINK;
    case browser.is("safari", true):
      return extensionConsts.SAFARI_EXTENSION_DOWNLOAD_LINK;
    case browser.is("firefox", true):
      return extensionConsts.FIREFOX_EXTENSION_DOWNLOAD_LINK;
    case browser.is("edge", true):
      return extensionConsts.EDGE_EXTENSION_DOWNLOAD_LINK;
    case browser.is("ie", true):
      return extensionConsts.IE_EXTENSION_DOWNLOAD_LINK;
    default:
      return "";
  }
};

const initialState = {
  detected: store.getExtensionDetected(),
  installationSkipped: store.getExtensionInstallSkipped(),
  browserExtensionUrl: getBrowserExtensionUrl(),
  compatibleVersion: undefined,
  version: undefined,
  shouldExtensionBeInstalled: undefined,
  shouldExtensionBeUpgraded: undefined
};

function extension(state = initialState, action) {
  switch (action.type) {
    case types.EXTENSION_DETECTED:
      const isCompatible = pluginVersionValidator.compatibleVersion(
        action.version
      );
      return {
        ...state,
        detected: true,
        installationSkipped: false,
        version: action.version,
        compatibleVersion: isCompatible,
        shouldExtensionBeInstalled: false,
        shouldExtensionBeUpgraded: !isCompatible
      };
    case types.EXTENSION_NOT_DETECTED:
      return {
        ...state,
        detected: false,
        shouldExtensionBeInstalled: true
      };
    case types.EXTENSION_SKIP_INSTALL:
      return {
        ...state,
        installationSkipped: true,
        shouldExtensionBeInstalled: true
      };
    default:
      return state;
  }
}

export default extension;
