import { map, isNil, omit } from "ramda";
import {
  computeSearchKeywords,
  normalizeIcons,
  normalizeId,
  normalizeParams
} from "./commonNormalizer";

const oneloginConnectorDescription = ({
  connectorType,
  provisioningEnabled,
  browserExtension
}) => {
  let description = connectorType;
  if (browserExtension) {
    description = description + ", browser extension";
  }
  if (provisioningEnabled) {
    description = description + ", provisioning";
  }
  return description;
};

//TODO: remove this temporary (compatability ensuring) function once apps-service will return connectorTypeCode
export const getIsAddEnabled = ({ connectorType, connectorTypeCode }) => {
  if (isNil(connectorTypeCode)) {
    return connectorType === "Form-based auth";
  } else {
    return connectorTypeCode === 0;
  }
};

export const normalizeConnector = connector => {
  return omit(["_id"], {
    ...connector,
    id: normalizeId(connector),
    icons: normalizeIcons(connector),
    parameters: normalizeParams(connector)
  });
};

export const normalizeAllConnectors = (connectors, commonWords) => {
  return map(
    connector => ({
      ...connector,
      searchKeywords: computeSearchKeywords(connector, commonWords),
      icons: normalizeIcons(connector),
      description: oneloginConnectorDescription(connector),
      addEnabled: getIsAddEnabled(connector)
    }),
    connectors
  );
};

export const normalizeConnectors = (connectors, commonWords) => {
  return map(
    connector => ({
      ...connector,
      searchKeywords: computeSearchKeywords(connector, commonWords),
      icons: normalizeIcons(connector),
      addEnabled: true
    }),
    connectors
  );
};
