import React, { useState } from "react";
import PropTypes from "prop-types";
import YouTube from "react-youtube";
import { injectIntl, defineMessages } from "react-intl";
import styled from "styled-components";
import bowser from "bowser";
import { LinkButton } from "@onelogin/react-components";

import installExtension from "./install-extension.png";
import * as trackEvents from "../actionsLog";

const Header = styled.div`
  font-size: 24px;
  font-weight: 500;
`;

const messages = defineMessages({
  header: {
    id: "header",
    defaultMessage: `Add OneLogin to your browser`
  },
  extensionDescription: {
    id: "extensionDescription",
    defaultMessage: `OneLogin lets you sign in to your accounts and save new passwords.`
  },
  addExtension: {
    id: "addExtension",
    defaultMessage: `Add OneLogin extension`
  },
  skip: {
    id: "skip",
    defaultMessage: `skip`
  }
});

const InstallButton = styled.a`
  border-radius: 2px;
  height: 38px;
  padding: 11px 16px;
  white-space: nowrap;
  line-height: 1;
  font-size: 14px;
  margin-left: 16px;
  display: inline-block;

  color: white;
  background-color: #00779e;

  margin-top: 35px;
  margin-bottom: 15px;

  &:hover {
    color: white;
    background-color: #3392b1;
  }

  &:active {
    color: white;
    background-color: #006384;
  }

  @media screen and (max-height: 700px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const Container = styled.div`
  width: 50%;
  text-align: center;
  margin: auto;

  @media screen and (max-width: 767px) {
    width: 100%;
    padding: 16px;
  }
`;

const Placeholder = styled.img`
  position: absolute;
  cursor: pointer;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

//styles copy&pasted from semantic-ui-react `Embed` component
const VideoWrap = styled.div`
  position: relative;
  max-width: 100%;
  height: 0;
  overflow: hidden;
  background: #dcddde;
  padding-bottom: 56.25%;

  iframe {
    position: absolute;
    border: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
  }
`;

const Cover = styled.i`
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: radial-gradient(rgba(0, 0, 0, 0) 45%, rgba(0, 0, 0, 0.3));
  opacity: 0.5;
  transition: opacity 0.5s ease;

  &:hover {
    background: radial-gradient(rgba(0, 0, 0, 0) 45%, rgba(0, 0, 0, 0.3));
    opacity: 1;
  }
`;

const CenteredContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

  @media screen and (max-height: 700px) {
    justify-content: flex-start;
    padding: 20px;
  }
`;

const InstallExtensionDescription = styled.p`
  margin-top: 15px;
  margin-bottom: 35px;

  @media screen and (max-height: 700px) {
    margin-top: 0;
    margin-bottom: 10px;
  }
`;

const GetExtension = ({ onSkip, browserExtensionUrl, intl }) => {
  const [showVideo, setShowVideo] = useState(false);
  return (
    <CenteredContent>
      <Container>
        <Header size="large">{intl.formatMessage(messages.header)}</Header>
        <InstallExtensionDescription>
          {intl.formatMessage(messages.extensionDescription)}
        </InstallExtensionDescription>
        <VideoWrap>
          {showVideo ? (
            <YouTube
              videoId="VmCTlUvvNA0"
              placeholder={installExtension}
              opts={{
                height: "100%",
                width: "100%",
                playerVars: { autoplay: 1 }
              }}
            />
          ) : (
            <>
              <Placeholder src={installExtension} width={640} height={360} />
              <Cover onClick={() => setShowVideo(true)} />
            </>
          )}
        </VideoWrap>
        {/* firefox from version ~58 blocks installing of extension opened in new tab */}
        <InstallButton
          id="install-extension-btn"
          onClick={trackEvents.installExtension}
          href={browserExtensionUrl}
          target={bowser.firefox ? "_self" : "_blank"}
          rel="noopener noreferrer"
        >
          {intl.formatMessage(messages.addExtension)}
        </InstallButton>
        <p>
          <LinkButton onClick={onSkip} data-testid="skip-extension">
            {intl.formatMessage(messages.skip)}
          </LinkButton>
        </p>
      </Container>
    </CenteredContent>
  );
};

GetExtension.propTypes = {
  onSkip: PropTypes.func.isRequired,
  browserExtensionUrl: PropTypes.string.isRequired
};

export default injectIntl(GetExtension);
