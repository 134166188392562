import * as types from "../constants/ActionTypes";

const initialState = {
  entities: {},
  genericConnector: {}
};

function connectors(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_CONNECTOR_REQUEST:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.connectorId]: {
            ...(state.entities[action.connectorId] || {}),
            fetching: true
          }
        }
      };

    case types.FETCH_CONNECTOR_RECEIVED:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.connectorId]: {
            ...state.entities[action.connectorId],
            ...action.connector,
            fetching: false
          }
        }
      };

    case types.FETCH_GENERIC_CONNECTOR_RECEIVED:
      return {
        ...state,
        genericConnector: {
          ...action.connector
        }
      };

    default:
      return state;
  }
}

export default connectors;
