import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { defineMessages } from "react-intl";
import { commonMessages } from "../../../translations/commonMessages";
import { MISSING_CONNECTOR_ICON } from "../../../constants/Apps";

import plusIcon from "../../../svg/plus.svg";
import "./StoreRow.css";

const messages = defineMessages({
  add: {
    id: "add",
    defaultMessage: `Add`
  },
  addApp: {
    id: "addApp",
    defaultMessage: `Add {appName}`
  }
});

class StoreRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      useFallback: undefined
    };
  }

  onClick = () => {
    this.props.onClick(this.props.id);
  };

  getIcon = () => {
    const { icons, name, intl } = this.props;
    const iconSrc =
      icons.square_48x48 || icons.square_24x24 || MISSING_CONNECTOR_ICON;

    if (this.state.useFallback || iconSrc.includes(MISSING_CONNECTOR_ICON)) {
      return (
        <div className="sr-fallback-image">{name.charAt(0).toUpperCase()}</div>
      );
    }

    return (
      <img
        className="sr-icon"
        src={iconSrc}
        alt={intl.formatMessage(commonMessages.appIcon)}
        onError={() => {
          this.setState({ useFallback: true });
        }}
      />
    );
  };

  render() {
    const {
      name,
      description,
      focusEl,
      isSelected,
      isAddEnabled,
      onFocus,
      intl
    } = this.props;

    return (
      <button
        className={classNames({ "store-row": true })}
        ref={input => {
          this.input = input;
          if (isSelected) {
            focusEl(input);
          }
        }}
        onClick={isAddEnabled ? this.onClick : null}
        aria-label={intl.formatMessage(messages.addApp, {
          appName: name
        })}
        onFocus={onFocus}
      >
        <div className="sr-icon-wrapper">{this.getIcon()}</div>
        <div className="sr-name">{name}</div>
        <div className="sr-description">{description}</div>
        {isAddEnabled ? (
          <div className="sr-action">
            <img
              className="sr-action-button"
              src={plusIcon}
              alt={intl.formatMessage(messages.add)}
            />
          </div>
        ) : (
          <div className="sr-action" />
        )}
      </button>
    );
  }
}

StoreRow.propTypes = {
  icons: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isAddEnabled: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  refHandler: PropTypes.func,
  intl: PropTypes.object.isRequired,
  onFocus: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  focusEl: PropTypes.func.isRequired
};

export default StoreRow;
