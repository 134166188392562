import * as types from "../constants/ActionTypes";

import { ALL_APPS_CUSTOM_TAB_ID } from "../constants/Apps";
import { isNil } from "ramda";

export const initialState = {
  secureNotes: false,
  name: "Company Name",
  cookieManagementEnabled: false,
  tabs: [],
  brand: {},
  customTabId: ALL_APPS_CUSTOM_TAB_ID,
  defaultTabId: undefined,
  loaded: undefined
};

const getDefaultTabId = ({ defaultTabId, cachedTabId, firstTabId }) => {
  if (!isNil(defaultTabId)) {
    return defaultTabId;
  } else if (!isNil(cachedTabId)) {
    return cachedTabId;
  } else {
    return firstTabId || ALL_APPS_CUSTOM_TAB_ID;
  }
};

function account(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_ACCOUNT_RECEIVED:
      const {
        name,
        cookieManagementEnabled,
        secureNotes,
        tabs,
        brand,
        companyTabName,
        isCacheLoad,
        enableOnePAM
      } = action.data;

      return {
        ...state,
        // customTabId defers to user settings, localStorage, then first admin-set tab
        customTabId: getDefaultTabId({
          defaultTabId: action.defaultTabId,
          cachedTabId: action.cachedTabId,
          //tabs are already sorted by position
          firstTabId: tabs.length > 0 ? tabs[0].id : undefined
        }),
        secureNotes,
        helpEnabled: brand.custom_support_enabled,
        helpInstruction: brand.support_instructions,
        name,
        cookieManagementEnabled,
        tabs,
        companyTabName,
        enableOnePAM,
        //app needs to wait for account data to decide whether to show browser warning or not, so keep initial loaded state until real request arrives
        loaded: isCacheLoad ? initialState.loaded : true
      };

    case types.FETCH_ACCOUNT_ERROR:
      return {
        ...state,
        loaded: true
      };

    case types.SET_CUSTOM_TAB_ID:
      return {
        ...state,
        customTabId: action.tabId
      };

    case types.FETCH_USER_RECEIVED:
      const defaultTabId = action.user.userSettings.default_tab_id;
      return {
        ...state,
        defaultTabId,
        customTabId: getDefaultTabId({
          defaultTabId,
          firstTabId: state.tabs.length > 0 ? state.tabs[0].id : undefined
        })
      };

    default:
      return state;
  }
}

export default account;
