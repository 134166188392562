import * as types from "../constants/ActionTypes";

const initialState = {
  redirectPath: ""
};

function navigatorReducer(state = initialState, action) {
  switch (action.type) {
    case types.NAVIGATE_TO:
      return { ...state, redirectPath: action.path };
    case types.NAVIGATE_TO_RESET:
      return { ...state, redirectPath: "" };
    default:
      return state;
  }
}

export default navigatorReducer;
