export const isEnabledForDomain = (flagValue, currentSubdomain) => {
  if (!flagValue) {
    return false;
  }

  if (flagValue === "*") {
    return true;
  }

  return flagValue.split(" ").includes(currentSubdomain);
};
