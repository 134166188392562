import client from "./httpClient";
import { SERVER_URL } from "../constants/Api";
import { getErrorHandler, normalizeMonorailError } from "./apiErrorHandling";

const errorHandler = getErrorHandler({});

const handleMonorailError = async error => {
  const normalized = await normalizeMonorailError(error);
  errorHandler(normalized, () => {
    // we can't stop execution even if error was handled so
    throw normalized;
  });
};

export const postHelpRequest = ({ body }) => {
  return client
    .post(`${SERVER_URL}/api/v3/send_help_email`, {
      json: body
    })
    .json()
    .catch(handleMonorailError);
};
