import * as eventsEmitter from "../utils/eventsEmitter";
import * as userSelectors from "../selectors/userSelectors";

import { CommonLayout, FatalError, Loading } from "@onelogin/react-components";
import { Route, Switch, withRouter } from "react-router-dom";
import {
  extensionSkipInstall,
  proceedWithUnsupportedBrowser
} from "../actions/otherActions";
import styled, { ThemeProvider } from "styled-components";

import { APP_STORE_PATH } from "../constants/NavigationConstants";
import AddApp from "./addApp/AddApp";
import AppEdit from "./appEdit/AppEdit";
import AppsView from "./apps/AppsView";
import AssumedUserNotificationBanner from "./AssumedUserNotificationBanner";
import ErrorBoundary from "./ErrorBoundary";
import GetExtension from "./GetExtension";
import GetHelpDialog from "./GetHelpDialog";
import { NARROW_VIEW_LIMIT } from "../constants/Env";
import OneTrust from "./OneTrust";
import PasswordNotificationBanner from "./PasswordNotificationBanner";
import PropTypes from "prop-types";
import React from "react";
import ScreenSizeWatcher from "./ScreenSizeWatcher";
import StoreView from "./apps/store/StoreView";
import UnsupportedBrowser from "./UnsupportedBrowser";
import { clearNotification } from "../actions/notificationsActions";
import { connect } from "react-redux";
import { gotoApps } from "../actions/navigatorActions";
import { injectIntl } from "react-intl";
import { setScreenSize } from "../actions/environmentActions";

//TODO: move pw related stuff to separate component
const PW_NOTIFICATION_DISPLAY_DAYS = 5;

//TODO: duplicated between userSelector and here
const goLogout = () => {
  eventsEmitter.userLogout();
  window.location.replace("/logout");
};

const PasswordNotificationWrapper = styled.div`
  flex: 0 0 auto;
`;

const FatalErrorWrapper = styled.div`
  width: 100%;
`;

export const MainApp = class MainApp extends React.Component {
  render() {
    const {
      user,
      branding,
      cookieManagementEnabled,
      extension,
      match,
      onSkipExtInstall,
      isTouchDevice,
      isSupportedBrowser,
      isAccountLoaded,
      notifications,
      clearDisplayedNotification,
      myBrowser,
      proceedUnsupportedBrowser,
      onProceedWithUnsupportedBrowser,
      isDeprecatedBrowser,
      fatalErrorMessage,
      screenWidth,
      screenHeight,
      breakpoint,
      setScreenSize,
      menus,
      intl
    } = this.props;

    if (fatalErrorMessage) {
      return (
        <FatalErrorWrapper>
          <FatalError message={intl.formatMessage(fatalErrorMessage)} />\
        </FatalErrorWrapper>
      );
    }

    if (user.loading || !branding.loaded || extension.detected === null) {
      return <Loading />;
    }

    let nodes;
    if (!isSupportedBrowser && !proceedUnsupportedBrowser && isAccountLoaded) {
      //TODO: unsupported is not handled for browser back button -> should be loaded either on new url or unloadManager should logout user synchronously
      nodes = (
        <UnsupportedBrowser
          data-testid="unsupported-browser"
          myBrowser={myBrowser}
          onOk={() => onProceedWithUnsupportedBrowser(isDeprecatedBrowser)}
          onCancel={goLogout}
        />
      );
    } else if (
      !isTouchDevice &&
      extension.detected === false &&
      !extension.installationSkipped &&
      user.installExtensionPermitted === true
    ) {
      nodes = (
        <GetExtension
          onSkip={onSkipExtInstall}
          browserExtensionUrl={extension.browserExtensionUrl}
        />
      );
    } else {
      nodes = (
        <Switch>
          <Route exact path={`${match.path}/apps`} component={AppsView} />
          <Route
            exact
            path={`${match.path}/apps/add/:connectorId`}
            component={AddApp}
          />
          <Route
            exact
            path={`${match.path}/apps/add/:connectorId/:searchName`}
            component={AddApp}
          />
          <Route path={`${match.path}/apps/edit/:appId`} component={AppEdit} />
          {!user.isAssumed && (
            <Route path={`${match.path}/store`} component={StoreView} />
          )}
          <Route path={`${match.path}/help`} component={GetHelpDialog} />
          <Route component={AppsView} />
        </Switch>
      );
    }

    const notificationVisible =
      user.passwordExpires &&
      user.passwordExpirationDays <= PW_NOTIFICATION_DISPLAY_DAYS &&
      user.showPasswordExpiresNotification === true;

    return (
      <OneTrust cookieManagementEnabled={cookieManagementEnabled}>
        <ThemeProvider theme={branding}>
          <ErrorBoundary>
            <ScreenSizeWatcher
              width={screenWidth}
              height={screenHeight}
              setSize={setScreenSize}
            />
            <CommonLayout
              menus={menus}
              projectRoot="portal"
              navbarMaxWidth={breakpoint}
              mobileBreakpoint={NARROW_VIEW_LIMIT}
              notifications={notifications}
              clearDisplayedNotification={clearDisplayedNotification}
              flatifyMobileMenus
            >
              {notificationVisible && (
                <PasswordNotificationWrapper data-testid="password-notification-banner">
                  <PasswordNotificationBanner
                    passwordExpirationDays={user.passwordExpirationDays}
                  />
                </PasswordNotificationWrapper>
              )}
              {user.isAssumed && <AssumedUserNotificationBanner />}
              {nodes}
            </CommonLayout>
          </ErrorBoundary>
        </ThemeProvider>
      </OneTrust>
    );
  }
};

MainApp.propTypes = {
  user: PropTypes.object.isRequired,
  branding: PropTypes.object,
  extension: PropTypes.object,
  translations: PropTypes.object,
  onSkipExtInstall: PropTypes.func.isRequired,
  isTouchDevice: PropTypes.bool.isRequired,
  isSupportedBrowser: PropTypes.bool.isRequired,
  myBrowser: PropTypes.string.isRequired,
  notifications: PropTypes.arrayOf(PropTypes.object),
  clearDisplayedNotification: PropTypes.func.isRequired,
  screenWidth: PropTypes.number.isRequired,
  setScreenSize: PropTypes.func.isRequired
};

export default connect(
  (state, { location }) => {
    //TODO: solve this other way -> through redux? (when store is displayed save it to redux & check here or in getMenus)
    const isOnAppStore =
      location &&
      location.pathname &&
      location.pathname.startsWith(APP_STORE_PATH);
    const menus = userSelectors.getMenus(state, isOnAppStore);

    return {
      user: state.user,
      branding: state.branding,
      cookieManagementEnabled: state.account.cookieManagementEnabled,
      extension: state.extension,
      isAccountLoaded: state.account.loaded,
      isSupportedBrowser: state.environment.isSupportedBrowser,
      isDeprecatedBrowser: state.environment.isDeprecatedBrowser,
      isTouchDevice: state.environment.isTouchDevice,
      screenWidth: state.environment.screenWidth,
      screenHeight: state.environment.screenHeight,
      breakpoint: state.environment.breakpoint,
      proceedUnsupportedBrowser: state.environment.proceedUnsupportedBrowser,
      myBrowser: state.environment.myBrowser,
      notifications: state.notifications.notificationsQueue,
      fatalErrorMessage: state.fatalError.fatalErrorMessage,
      menus
    };
  },
  dispatch => ({
    gotoApps: () => {
      dispatch(gotoApps());
    },
    onSkipExtInstall: () => {
      dispatch(extensionSkipInstall());
    },
    clearDisplayedNotification: notificationId => {
      dispatch(clearNotification(notificationId));
    },
    onProceedWithUnsupportedBrowser: isDeprecatedBrowser => {
      dispatch(proceedWithUnsupportedBrowser(isDeprecatedBrowser));
    },
    setScreenSize: (width, height) => {
      dispatch(setScreenSize(width, height));
    }
  })
)(injectIntl(withRouter(MainApp)));
