import { postMessage } from "./extensionMessenger";

export const getExtensionVersion = callback => {
  postMessage({
    type: "getExtensionVersion",
    callback
  });
};

export const appsChanged = () => {
  postMessage({
    type: "appsChanged"
  });
};

export const appsFetched = () => {
  postMessage({
    type: "appsFetched"
  });
};

export const userInitialized = () => {
  postMessage({
    type: "userInitialized"
  });
};

export const userLogout = () => {
  postMessage({
    type: "userLogout"
  });
};
