import React from "react";
import { injectIntl, defineMessages } from "react-intl";
import { join, keys, map } from "ramda";
import PropTypes from "prop-types";
import { Button, ModalDialog } from "@onelogin/react-components";
import styled from "styled-components";

import { MIN_SUPPORTED_BROWSERS } from "../constants/Env";
import { commonMessages } from "../translations/commonMessages";
import AlertDialogContent from "./AlertDialogContent";

const messages = defineMessages({
  unsupportedBrowserHeading: {
    id: "unsupportedBrowserHeading",
    defaultMessage: `OneLogin Portal may not work properly in your browser`
  },
  proceedAnyway: {
    id: "proceedAnyway",
    defaultMessage: `Proceed anyway`
  },
  yourBrowser: {
    id: "yourBrowser",
    defaultMessage: `Your browser: {browser}`
  },
  supportedBrowsers: {
    id: "supportedBrowsers",
    defaultMessage: `Supported browsers: {browsers}`
  },
  unsupportedBrowser: {
    id: "unsupportedBrowser",
    defaultMessage: "Unsupported Browser"
  }
});

const BROWSER_NAMES = {
  ie: "Microsoft Internet Explorer",
  edge: "Microsoft Edge",
  firefox: "Firefox",
  chrome: "Chrome",
  safari: "Safari"
};

const Instruction = styled.div`
  margin-top: 16px;
`;

export const UnsupportedBrowser = ({
  myBrowser,
  onOk,
  onCancel,
  intl,
  "data-testid": dataTestId
}) => {
  const supportedBrowsers = join(
    ", ",
    map(browser => {
      return `${BROWSER_NAMES[browser]} ${MIN_SUPPORTED_BROWSERS[
        browser
      ].replace(/[<>=]/g, "")}+`;
    }, keys(MIN_SUPPORTED_BROWSERS))
  );

  const message = (
    <div>
      {intl.formatMessage(messages.yourBrowser, {
        browser: myBrowser
      })}
      <br />
      {intl.formatMessage(messages.supportedBrowsers, {
        browsers: supportedBrowsers
      })}
    </div>
  );

  return (
    <ModalDialog data-testid={dataTestId}>
      <ModalDialog.Header>
        {intl.formatMessage(messages.unsupportedBrowser)}
      </ModalDialog.Header>
      <ModalDialog.Content>
        <AlertDialogContent>
          {intl.formatMessage(messages.unsupportedBrowserHeading)}
        </AlertDialogContent>
        <Instruction>{message}</Instruction>
      </ModalDialog.Content>
      <ModalDialog.Actions>
        <Button onClick={onCancel}>
          {intl.formatMessage(commonMessages.logout)}
        </Button>
        <Button onClick={onOk} look="primary">
          {intl.formatMessage(messages.proceedAnyway)}
        </Button>
      </ModalDialog.Actions>
    </ModalDialog>
  );
};

UnsupportedBrowser.propTypes = {
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  myBrowser: PropTypes.string.isRequired
};

export default injectIntl(UnsupportedBrowser);
