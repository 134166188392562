export const charSet = "!@#$^*()~,.{}[]:<>*-_abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVXY0123456789".split(
  ""
);

const randomChar = () => {
  return charSet[Math.floor(Math.random() * charSet.length)];
};

export const generatePassword = () => {
  return [...Array(14)].map(randomChar).join("");
};
