import { values } from "ramda";

//regexes defined in monorail; this is just a copy of the values to be consistent in user reporting
const emailRegexp = new RegExp(
  "^(([^@\\s]+)@((?:[-a-z0-9]+\\.)+[a-z]{2,}))?$",
  "i"
);
const subdomainRegexp = new RegExp(/^[A-Za-z0-9-]*$/);
const usernameRegexp = new RegExp(/^[\w\-.]*$/);

export default class FormValidationHelper {
  constructor() {
    this.validations = {};
    this.validity = {};
  }

  register({ id, value, validationObject }) {
    //TODO: check validationObject structure
    this.validations[id] = validationObject;
    this.updateValidity({ id, value });
  }

  getValidity(id) {
    return this.validity[id] || {};
  }

  updateValidity({ id, value }) {
    if (this.validations[id]) {
      this.validity[id] = this._getValidityInfo({
        ...this.validations[id],
        value
      });
    }
  }

  isInvalid() {
    return values(this.validity).some(validation => validation.invalid);
  }

  _getValidityInfo({ value, format, required }) {
    let invalid = false;

    if (required && !value) {
      invalid = true;
    } else {
      switch (format) {
        case "email":
          invalid = !emailRegexp.test(value);
          break;

        case "subdomain":
          invalid = !subdomainRegexp.test(value);
          break;

        case "username":
          invalid = !usernameRegexp.test(value);
          break;

        case "any":
        default:
          break;
      }
    }

    //I assume sooner or later there will be messages with validity description, therefore returning object
    return { invalid };
  }
}
