import { Route, BrowserRouter as Router } from "react-router-dom";

import AirbrakeProvider from "./AirbrakeProvider";
import ErrorBoundary from "./ErrorBoundary";
import MainApp from "./MainApp";
import { ProjectRootProvider } from "@onelogin/react-components";
import { Provider } from "react-intl-redux";
import { ROOT_PATH } from "../constants/NavigationConstants";
import React from "react";
import ReactDOM from "react-dom";

export const renderPortal = ({ store, airbrake }) => {
  ReactDOM.render(
    <ProjectRootProvider value="portal">
      <AirbrakeProvider airbrake={airbrake}>
        <ErrorBoundary>
          <Provider store={store}>
            <Router>
              <Route path={`${ROOT_PATH}`} component={MainApp} />
            </Router>
          </Provider>
        </ErrorBoundary>
      </AirbrakeProvider>
    </ProjectRootProvider>,
    document.getElementById("root")
  );
};
