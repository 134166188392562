import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import QueryConnectorData from "./QueryConnectorData";
import AppForm from "../appForm/AppForm";
import { createAppThenGotoApps } from "../../actions/appEditActions";
import { gotoAppStore } from "../../actions/navigatorActions";
import Mask from "../Mask";
import ErrorBoundary from "../ErrorBoundary";
import PortalRedirect from "../PortalRedirect";
import styled from "styled-components";

const AddAppWrap = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

export const AddApp = ({
  app,
  saving,
  connectorId,
  onCancel,
  onAppSave,
  redirectPath
}) => {
  if (redirectPath) {
    return <PortalRedirect path={redirectPath} />;
  }

  let content;
  if (!app || (app.fetching && !app.id)) {
    content = <Mask />;
  } else if (app.id) {
    content = (
      <AppForm
        app={app}
        editable
        isAddApp
        onSubmit={onAppSave}
        onCancel={onCancel}
        saving={saving}
      />
    );
  } else {
    content = null;
  }

  return (
    <ErrorBoundary>
      <AddAppWrap>
        <QueryConnectorData connectorId={connectorId} />
        {content}
      </AddAppWrap>
    </ErrorBoundary>
  );
};

AddApp.propTypes = {
  app: PropTypes.object,
  connectorId: PropTypes.string.isRequired,
  onAppSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  saving: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => {
  const { connectorId, searchName } = ownProps.match.params,
    connector = state.connectors.entities[connectorId];

  return {
    app: connector
      ? {
          ...connector,
          name: searchName || (connector && connector.name)
        }
      : {},
    connectorId,
    redirectPath: state.navigator.redirectPath
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppSave: formData => {
    const { connectorId } = ownProps.match.params;
    dispatch(
      createAppThenGotoApps({
        ...formData,
        connectorId
      })
    );
  },
  onCancel: () => dispatch(gotoAppStore())
});

export default connect(mapStateToProps, mapDispatchToProps)(AddApp);
