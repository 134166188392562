import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Dropdown } from "@onelogin/react-components";

const CustomTabSelectorWrap = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin: -1px -1px -2px -1px;
  padding: 0;
`;

class CustomTabsSelector extends Component {
  onChange = value => {
    this.props.onSelectionChange(value);
  };

  render() {
    const { tabs, selectedTab } = this.props;
    const tabsDropdownFormat = tabs.map(tab => ({
      text: tab.name,
      value: tab.id
    }));

    return (
      <CustomTabSelectorWrap>
        <Dropdown
          list={tabsDropdownFormat}
          value={selectedTab ? selectedTab.id : null}
          onChange={this.onChange}
        />
      </CustomTabSelectorWrap>
    );
  }
}

CustomTabsSelector.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  selectedTab: PropTypes.object.isRequired,
  onSelectionChange: PropTypes.func.isRequired
};

export default CustomTabsSelector;
