import "@formatjs/intl-pluralrules/locale-data/cs";
import "@formatjs/intl-pluralrules/locale-data/da";
import "@formatjs/intl-pluralrules/locale-data/de";
import "@formatjs/intl-pluralrules/locale-data/en";
import "@formatjs/intl-pluralrules/locale-data/es";
import "@formatjs/intl-pluralrules/locale-data/fi";
import "@formatjs/intl-pluralrules/locale-data/fr";
import "@formatjs/intl-pluralrules/locale-data/hu";
import "@formatjs/intl-pluralrules/locale-data/it";
import "@formatjs/intl-pluralrules/locale-data/ja";
import "@formatjs/intl-pluralrules/locale-data/ko";
import "@formatjs/intl-pluralrules/locale-data/ms";
import "@formatjs/intl-pluralrules/locale-data/nb";
import "@formatjs/intl-pluralrules/locale-data/nl";
import "@formatjs/intl-pluralrules/locale-data/pl";
import "@formatjs/intl-pluralrules/locale-data/pt";
import "@formatjs/intl-pluralrules/locale-data/ru";
import "@formatjs/intl-pluralrules/locale-data/sk";
import "@formatjs/intl-pluralrules/locale-data/sv";
import "@formatjs/intl-pluralrules/locale-data/th";
import "@formatjs/intl-pluralrules/locale-data/tr";
import "@formatjs/intl-pluralrules/locale-data/vi";
import "@formatjs/intl-pluralrules/locale-data/zh";
import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/cs";
import "@formatjs/intl-relativetimeformat/locale-data/da";
import "@formatjs/intl-relativetimeformat/locale-data/de";
import "@formatjs/intl-relativetimeformat/locale-data/en";
import "@formatjs/intl-relativetimeformat/locale-data/es";
import "@formatjs/intl-relativetimeformat/locale-data/fi";
import "@formatjs/intl-relativetimeformat/locale-data/fr";
import "@formatjs/intl-relativetimeformat/locale-data/hu";
import "@formatjs/intl-relativetimeformat/locale-data/it";
import "@formatjs/intl-relativetimeformat/locale-data/ja";
import "@formatjs/intl-relativetimeformat/locale-data/ko";
import "@formatjs/intl-relativetimeformat/locale-data/ms";
import "@formatjs/intl-relativetimeformat/locale-data/nb";
import "@formatjs/intl-relativetimeformat/locale-data/nl";
import "@formatjs/intl-relativetimeformat/locale-data/pl";
import "@formatjs/intl-relativetimeformat/locale-data/pt";
import "@formatjs/intl-relativetimeformat/locale-data/ru";
import "@formatjs/intl-relativetimeformat/locale-data/sk";
import "@formatjs/intl-relativetimeformat/locale-data/sv";
import "@formatjs/intl-relativetimeformat/locale-data/th";
import "@formatjs/intl-relativetimeformat/locale-data/tr";
import "@formatjs/intl-relativetimeformat/locale-data/vi";
import "@formatjs/intl-relativetimeformat/locale-data/zh";
import "@formatjs/intl-relativetimeformat/polyfill";

export const intlPolyfill = () => {
  if (!window.Intl) {
    require("intl");
    require("intl/locale-data/jsonp/cs");
    require("intl/locale-data/jsonp/da");
    require("intl/locale-data/jsonp/de");
    require("intl/locale-data/jsonp/en");
    require("intl/locale-data/jsonp/es");
    require("intl/locale-data/jsonp/fi");
    require("intl/locale-data/jsonp/fr");
    require("intl/locale-data/jsonp/hu");
    require("intl/locale-data/jsonp/it");
    require("intl/locale-data/jsonp/ja");
    require("intl/locale-data/jsonp/ko");
    require("intl/locale-data/jsonp/ms");
    require("intl/locale-data/jsonp/nb");
    require("intl/locale-data/jsonp/nl");
    require("intl/locale-data/jsonp/pl");
    require("intl/locale-data/jsonp/pt");
    require("intl/locale-data/jsonp/ru");
    require("intl/locale-data/jsonp/sk");
    require("intl/locale-data/jsonp/sv");
    require("intl/locale-data/jsonp/th");
    require("intl/locale-data/jsonp/tr");
    require("intl/locale-data/jsonp/vi");
    require("intl/locale-data/jsonp/zh");
  }
};