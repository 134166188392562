import isDevelopment from "../utils/environment";
import missingConnectorRect from "../images/missing_connector_rect_2x.png";
import missingConnectorSquare from "../images/missing_connector_square_2x.png";

let serverUrl = process.env.REACT_APP_SERVER_URL || "";
let brandingUrl = process.env.REACT_APP_BRANDING_BASE_URL;

if (isDevelopment && !process.env.REACT_APP_DEV_HOST) {
  serverUrl = `http://${window.location.hostname}:5050`;
  brandingUrl = serverUrl;
}

export const STATUS_EXPIRED_PASSWORD = "password_expired";
export const ADMIN_USER_TYPE = "admin";
export const SERVER_URL = serverUrl;
export const BRANDING_BASE_URL = brandingUrl;
export const UNAUTHORIZED_CODE = 401;
export const UNPROCESSABLE_ENTITY_CODE = 422;
export const INTERNAL_SERVER_ERROR = 500;
export const LOGIN_URL = "/login";
export const PASSWORD_CHANGE_URL = "/password";
export const NEW_PORTAL_OPTIONAL = "allowed";

export const ADMIN = "admin";
export const SANDBOX = "sandbox";
export const SUPERADMIN = "superadmin";

export const RECT_IMG_FALLBACK_URL = missingConnectorRect;
export const SQUARE_IMG_FALLBACK_URL = missingConnectorSquare;

export const PARAMETER_ID_PREFIX = "param_";
export const TRANSLATIONS_URL = `${process.env.PUBLIC_URL}/translations/`;
