import React from "react";
import PropTypes from "prop-types";
import "./Message.css";

const Message = ({ msg, detail }) => (
  <div className="message-container">
    {msg}
    <br />
    <small>{detail}</small>
  </div>
);

Message.propTypes = {
  msg: PropTypes.string.isRequired,
  detail: PropTypes.string
};

Message.defaultProps = {
  detail: ""
};

export default Message;
