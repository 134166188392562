import { isEnabledForDomain } from "../utils/featureFlags";

const REACT_APP_FF_PERSIST_STORE = "REACT_APP_FF_PERSIST_STORE";
const REACT_APP_FF_ALL_CONNECTORS_STORE = "REACT_APP_FF_ALL_CONNECTORS_STORE";
const REACT_APP_FF_APP_STORE_ENABLED = "REACT_APP_FF_APP_STORE_ENABLED";
const REACT_APP_FF_CUSTOM_TABS_ENABLED = "REACT_APP_FF_CUSTOM_TABS_ENABLED";

const mySubdomain = window.location.hostname.split(".")[0];

const isFeatureFlagEnabled = flagName => {
  return isEnabledForDomain(process.env[flagName], mySubdomain);
};

export const initialState = {
  persistStore: isFeatureFlagEnabled(REACT_APP_FF_PERSIST_STORE),
  allConnectorsStore: isFeatureFlagEnabled(REACT_APP_FF_ALL_CONNECTORS_STORE),
  appStoreEnabled: isFeatureFlagEnabled(REACT_APP_FF_APP_STORE_ENABLED),
  customTabsEnabled: isFeatureFlagEnabled(REACT_APP_FF_CUSTOM_TABS_ENABLED)
};

function featureFlags(state = initialState, action) {
  return state;
}

export default featureFlags;
