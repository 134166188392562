import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl, defineMessages } from "react-intl";
import { isEmpty } from "ramda";
import { Loading } from "@onelogin/react-components";

import * as trackEvents from "../../actionsLog";
import PortalRedirect from "../PortalRedirect";
import {
  NARROW_VIEW_LIMIT,
  MID_VIEW_LIMIT,
  WIDE_VIEW_LIMIT
} from "../../constants/Env";
import {
  setAppsViewType,
  fetchApps,
  getPassword,
  setCustomTabId
} from "../../actions/appsActions";
import {
  gotoAppEdit,
  gotoApps,
  openExtensionInstall
} from "../../actions/navigatorActions";
import { searchableAndSelectable } from "./hoc/searchableAndSelectable";
import {
  MAX_FREQUENTS_TO_SHOW,
  ALL_APPS_CUSTOM_TAB_ID
} from "../../constants/Apps";
import { selectSortedFilteredList } from "../../selectors/appsSelectors";
import * as userSelectors from "../../selectors/userSelectors";
import AppsContent from "./AppsContent";
import Message from "../Message";

import TabsSwitcher, { TAB_ITEM_CLS } from "./common/TabsSwitcher";
import { computeTabs } from "../../utils/appsTabs";
import { isStringWithValue } from "../../utils/isStringWithValue";
import { AppsViewType } from "../../constants/ActionTypes";

import CustomTabsSelector from "./portal/CustomTabsSelector";
import SearchField from "../SearchField";
import { commonMessages } from "../../translations/commonMessages";
import { NoExtension } from "./NoExtension";
import * as navigationConstants from "../../constants/NavigationConstants";
import ErrorBoundary from "../ErrorBoundary";
import ResponsiveBlock from "../ResponsiveBlock";

import "./AppsView.css";

const selectApps = selectSortedFilteredList(MAX_FREQUENTS_TO_SHOW);

const messages = defineMessages({
  searchNoMatch: {
    id: "searchNoMatch",
    defaultMessage: `Your search did not match any app.`
  },
  noAppsSet: {
    id: "noAppsSet",
    defaultMessage: `You don't have any apps set up.`
  },
  placeholder: {
    id: "placeholder",
    defaultMessage: `Search`
  },
  noFrequents: {
    id: "noFrequents",
    defaultMessage: `Frequents are currently unavailable.`
  },
  browseAppStore: {
    id: "browseAppStore",
    defaultMessage: `Browse App Store`
  },
  companyEverythingFilter: {
    id: "companyEverythingFilter",
    defaultMessage: `Everything`
  },
  companySelectedTab: {
    id: "companySelectedTab",
    defaultMessage: `{companyTabName}: {selectedTab}`
  }
});

const computeColumnsCount = width => {
  if (width > WIDE_VIEW_LIMIT) {
    return 5;
  } else if (width > MID_VIEW_LIMIT) {
    return 4;
  } else if (width > NARROW_VIEW_LIMIT) {
    return 3;
  } else {
    return 1;
  }
};

const injectAllAppsFilter = ({ tabs, apps, intl }) => {
  if (tabs && tabs.length > 0 && apps && apps.tabIds) {
    return [
      {
        id: ALL_APPS_CUSTOM_TAB_ID,
        position: 0,
        name: intl.formatMessage(messages.companyEverythingFilter)
      }
    ].concat(tabs.filter(customTab => apps.tabIds[customTab.id]));
  }

  return tabs;
};

export class AppsView extends Component {
  componentDidMount() {
    const { airbrake } = this.context;
    this.props.loadApps(airbrake);
    this.countCompanyTabWidth();
  }

  componentDidUpdate() {
    this.countCompanyTabWidth();
  }

  onAppStoreClick = () => {
    this.props.history.push("/portal/store");
  };

  countCompanyTabWidth = () => {
    if (this.tabsSwitcher) {
      this.tabsSwitcherWidth = this.tabsSwitcher.offsetWidth;
      const children = this.tabsSwitcher.children;

      let total = 0;
      let lastTabItem = {};
      if (children && children.length) {
        for (let i = 0; i < children.length; i++) {
          //spacer
          if (children[i].innerHTML === "") {
            continue;
          }
          children[i].style.maxWidth = `none`; //turn off previously set max width to count proper new width
          const width = children[i].offsetWidth;
          const { marginLeft, marginRight } = window.getComputedStyle(
            children[i]
          );
          const childTotalWidth =
            width +
            (parseInt(marginLeft, 10) || 0) +
            (parseInt(marginRight, 10) || 0);

          total += childTotalWidth;

          if (children[i].className.includes(TAB_ITEM_CLS)) {
            lastTabItem = {
              index: i,
              width
            };
          }
        }

        if (!isEmpty(lastTabItem)) {
          const maxWidth = lastTabItem.width - (total - this.tabsSwitcherWidth);
          children[lastTabItem.index].style.maxWidth = `${maxWidth}px`;
        }
      }
    }
  };

  setViewType = (newViewType, dontFocusApp) => {
    this.props.onFilterSwitch(newViewType);
    this.props.resetSearch();

    if (dontFocusApp) {
      this.props.onSelectedIndexChange(-1);
    }
    trackEvents.appsViewFilterSwitched();
  };

  getCompanyTabString = (showFullString = true) => {
    const { selectedCustomTab, intl, companyTabName } = this.props;

    const company = isStringWithValue(companyTabName)
      ? companyTabName
      : intl.formatMessage(commonMessages.company);

    if (isEmpty(selectedCustomTab) || !showFullString) {
      return company;
    } else {
      return intl.formatMessage(messages.companySelectedTab, {
        selectedTab: selectedCustomTab.name,
        companyTabName: company
      });
    }
  };

  getCustomTabsSelector = () => {
    const { customTabs, onCustomTabChange, selectedCustomTab } = this.props;

    return (
      <CustomTabsSelector
        tabs={customTabs}
        onSelectionChange={onCustomTabChange}
        selectedTab={selectedCustomTab}
      />
    );
  };

  gotoMissingExtension = extensionPermitted => {
    const hash = extensionPermitted
      ? navigationConstants.HASH_NO_EXTENSION
      : navigationConstants.HASH_EXTENSION_UNAVAILABLE;
    this.props.history.push(`${window.location.pathname}${hash}`);
  };

  onAppLaunch = ({ app, event }) => {
    this.doAppLaunch({
      app,
      trackClick: event.type === "click",
      openInThisTab: event.shiftKey,
      event
    });
  };

  doAppLaunch = ({ app, trackClick, openInThisTab, event }) => {
    //extensionInstalled not checked for value here by purpose - not detected OR not available means the same here -> app can't be logged into
    if (app.extensionRequired && !this.props.extensionInstalled) {
      event && event.preventDefault();
      this.gotoMissingExtension(
        this.props.installExtensionPermitted || app.isPrivate
      );
    } else {
      trackEvents.appUsed(
        trackClick ? "click" : "enter",
        this.props.searchTerm
      );
      // clear search term on an app launch
      this.props.resetSearch();
    }
  };

  onEditClickWithTracking = appId => {
    this.props.history.push(
      `${navigationConstants.ROOT_PATH}/apps/edit/${appId}`
    );
    trackEvents.enteredAppEdit(this.props.searchTerm);
  };

  resolveAttentionNeeded = app => {
    if (app.extensionRequired && this.props.extensionInstalled === false) {
      return true;
    }

    return false;
  };

  renderContent = () => {
    const {
      data,
      isTouchDevice,
      columnsCount,
      searchTerm,
      fetchPassword,
      viewType,
      onSelectedIndexChange,
      resetSearch,
      setSearchFocused,
      searchShouldHaveFocus,
      keysDisabled,
      selectedIndex,
      onExtensionInstall,
      onExtensionCancel,
      onNoExtensionOk,
      location,
      focusEl,
      intl
    } = this.props;

    if (!searchTerm && data.length === 0) {
      if (viewType === AppsViewType.FAVORITE_ONLY) {
        return <Message msg={intl.formatMessage(messages.noFrequents)} />;
      } else {
        return <Message msg={intl.formatMessage(messages.noAppsSet)} />;
      }
    }

    const showNoExtension =
      location.hash === navigationConstants.HASH_NO_EXTENSION ||
      location.hash === navigationConstants.HASH_EXTENSION_UNAVAILABLE;

    return (
      <>
        {searchTerm && data.length === 0 && (
          <Message msg={intl.formatMessage(messages.searchNoMatch)} />
        )}
        <AppsContent
          data={data}
          resolveAttentionNeeded={this.resolveAttentionNeeded}
          columnsCount={columnsCount}
          showMobileView={columnsCount === 1}
          keysDisabled={keysDisabled || searchShouldHaveFocus}
          selectedIndex={selectedIndex}
          onEditClick={this.onEditClickWithTracking}
          onAppLaunch={this.onAppLaunch}
          onSelectedIndexChange={onSelectedIndexChange}
          fetchPassword={fetchPassword}
          isTouchDevice={isTouchDevice}
          resetSearch={resetSearch}
          focusEl={focusEl}
          setSearchFocused={setSearchFocused}
        />
        {showNoExtension && (
          <NoExtension
            installExtensionPermitted={
              location.hash === navigationConstants.HASH_NO_EXTENSION
            }
            isTouchDevice={isTouchDevice}
            onNoExtensionOk={onNoExtensionOk}
            onExtensionInstall={onExtensionInstall}
            onExtensionCancel={onExtensionCancel}
            togglePageFocus={this.props.togglePageFocus}
          />
        )}
      </>
    );
  };

  setTabsSwitcherRef = tabsSwitcher => {
    this.tabsSwitcher = tabsSwitcher;
  };

  render() {
    const {
      loaded,
      isTouchDevice,
      columnsCount,
      searchTerm,
      viewType,
      onSearchTermChange,
      leaveSearchFieldIfPossible,
      canSeeAppStore,
      customTabsEnabled,
      redirectPath,
      userInitials,
      searchShouldHaveFocus,
      blurSearchField,
      selectFirstApp,
      intl,
      focusEl,
      setSearchFocused,
      showPersonalTab,
      customTabs,
      onCustomTabChange,
      screenHeight,
      isAssumed
    } = this.props;
    let tabs = [];

    if (redirectPath) {
      return <PortalRedirect path={redirectPath} />;
    }

    if (!loaded) {
      return <Loading />;
    }

    if (!searchTerm) {
      const showFullString = columnsCount > 1 && customTabsEnabled;
      tabs = computeTabs({
        userInitials,
        showPersonalTab,
        companyText: this.getCompanyTabString(showFullString),
        intl,
        customTabs,
        onCustomTabChange,
        customTabsEnabled
      });
    }

    //TODO: remove link from tabsswitcher, render it here alone; then: don't render tabswitcher when no tabs are available (in search mode)
    return (
      <ErrorBoundary>
        <div id="apps-view-container">
          <div className="search-and-tabs-wrap">
            <ErrorBoundary>
              <SearchField
                onChange={onSearchTermChange}
                leaveSearchFieldIfPossible={leaveSearchFieldIfPossible}
                onBlur={blurSearchField}
                searchTerm={searchTerm}
                isTouchDevice={isTouchDevice}
                shouldHaveFocus={searchShouldHaveFocus}
                onSearchEnter={selectFirstApp}
                placeholder={intl.formatMessage(messages.placeholder)}
                onFocus={setSearchFocused}
                focusEl={focusEl}
              />
            </ErrorBoundary>
            {columnsCount > 1 ? (
              <ErrorBoundary>
                <ResponsiveBlock>
                  <TabsSwitcher
                    setRef={this.setTabsSwitcherRef}
                    tabs={tabs}
                    onTabChange={this.setViewType}
                    activeTabType={viewType}
                    link={
                      canSeeAppStore
                        ? {
                            text: intl.formatMessage(messages.browseAppStore),
                            onClick: this.onAppStoreClick,
                            disabled: isAssumed
                          }
                        : null
                    }
                    isTouchDevice={isTouchDevice}
                    showMobileView={false}
                    screenHeight={screenHeight}
                  />
                </ResponsiveBlock>
              </ErrorBoundary>
            ) : null}
          </div>
          {customTabsEnabled &&
          viewType === AppsViewType.COMPANY &&
          columnsCount === 1 &&
          !searchTerm ? (
            <div className="custom-tabs-container">
              {this.getCustomTabsSelector()}
            </div>
          ) : null}
          <div className="scrollable-content">
            <ErrorBoundary>
              <ResponsiveBlock noPaddings>
                <div className="apps-container">{this.renderContent()}</div>
              </ResponsiveBlock>
            </ErrorBoundary>
          </div>
          {columnsCount === 1 ? (
            <ErrorBoundary>
              <TabsSwitcher
                tabs={tabs}
                onTabChange={this.setViewType}
                activeTabType={viewType}
                showMobileView
                isTouchDevice={isTouchDevice}
                screenHeight={screenHeight}
              />
            </ErrorBoundary>
          ) : null}
        </div>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    account,
    apps,
    featureFlags,
    user,
    frequents,
    environment,
    navigator
  } = state;
  const { intl, searchTerm } = ownProps;

  const userInitials = user.initials;
  const showPersonalTab = apps.hasPersonalApps;

  const customTabs = injectAllAppsFilter({
    tabs: account.tabs,
    apps,
    intl
  });
  const customTabsEnabled =
    featureFlags.customTabsEnabled && account.tabs.length > 0;
  const columnsCount = computeColumnsCount(environment.screenWidth);

  const selectedCustomTabId =
    customTabsEnabled &&
    customTabs.some(customTab => customTab.id === account.customTabId)
      ? account.customTabId
      : ALL_APPS_CUSTOM_TAB_ID;
  const selectedCustomTab = customTabs.filter(
    tab => tab.id === selectedCustomTabId
  );

  const companyTabName = account.companyTabName;

  const viewType = apps.appsViewType || AppsViewType.FAVORITE_ONLY;

  return {
    data: selectApps(
      apps,
      frequents.userFrequents,
      frequents.accountFrequents,
      searchTerm,
      selectedCustomTabId
    ),
    viewType,
    userInitials,
    showPersonalTab,
    loaded: apps.loaded && frequents.loaded && apps.appsViewType !== undefined,
    extensionInstalled: state.extension.detected,
    installExtensionPermitted: user.installExtensionPermitted,
    selectedCustomTab: selectedCustomTab.length ? selectedCustomTab[0] : {},
    columnsCount,
    customTabsEnabled,
    customTabs,
    companyTabName,
    canSeeAppStore: userSelectors.canSeeAppStore(state),
    isTouchDevice: environment.isTouchDevice,
    redirectPath: navigator.redirectPath,
    screenHeight: environment.screenHeight,
    isAssumed: user.isAssumed
  };
};

const mapDispatchToProps = dispatch => ({
  onEditClick: appId => {
    dispatch(gotoAppEdit(appId));
  },
  loadApps: airbrake => {
    dispatch(fetchApps(airbrake));
  },
  fetchPassword: appId => {
    dispatch(getPassword(appId));
  },
  onFilterSwitch: viewType => {
    dispatch(setAppsViewType(viewType));
  },
  onCustomTabChange: tabId => {
    dispatch(setCustomTabId(tabId));
  },
  onExtensionInstall: () => {
    dispatch(openExtensionInstall());
    dispatch(gotoApps());
  },
  onExtensionCancel: () => {
    dispatch(gotoApps());
  },
  onNoExtensionOk: () => {
    dispatch(gotoApps());
  }
});

AppsView.propTypes = {
  onEditClick: PropTypes.func.isRequired,
  loadApps: PropTypes.func.isRequired,
  onFilterSwitch: PropTypes.func.isRequired,
  fetchPassword: PropTypes.func.isRequired,
  onCustomTabChange: PropTypes.func.isRequired,
  onSearchTermChange: PropTypes.func.isRequired,
  onSelectedIndexChange: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  extensionInstalled: PropTypes.bool,
  installExtensionPermitted: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  viewType: PropTypes.string.isRequired,
  showPersonalTab: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  searchTerm: PropTypes.string.isRequired,
  columnsCount: PropTypes.number.isRequired,
  isTouchDevice: PropTypes.bool.isRequired,
  canSeeAppStore: PropTypes.bool.isRequired,
  customTabsEnabled: PropTypes.bool.isRequired,
  keysDisabled: PropTypes.bool.isRequired,
  searchShouldHaveFocus: PropTypes.bool.isRequired,
  selectedCustomTab: PropTypes.object.isRequired,
  launchSelectedApp: PropTypes.func.isRequired,
  leaveSearchFieldIfPossible: PropTypes.func.isRequired,
  togglePageFocus: PropTypes.func.isRequired,
  focusEl: PropTypes.func.isRequired,
  blurSearchField: PropTypes.func.isRequired,
  selectFirstApp: PropTypes.func.isRequired,
  customTabs: PropTypes.arrayOf(PropTypes.object).isRequired,
  companyTabName: PropTypes.string,
  isAssumed: PropTypes.bool.isRequired
};

AppsView.contextTypes = {
  airbrake: PropTypes.object
};

export default connect(state => ({
  isTouchDevice: state.environment.isTouchDevice
}))(
  searchableAndSelectable(
    withRouter(
      injectIntl(connect(mapStateToProps, mapDispatchToProps)(AppsView))
    )
  )
);
