import React from "react";
import classNames from "classnames";

import frequentsInactiveIcon from "../../../svg/clock-normal.svg";
import frequentsActiveIcon from "../../../svg/clock-active.svg";

import companyInactiveIcon from "../../../svg/briefcase-normal.svg";
import companyActiveIcon from "../../../svg/briefcase-active.svg";

import "./TabIcons.css";

export const favoritesIcon = personalIconAlt => isActive => (
  <div className={classNames({ "active-item": isActive })}>
    <img
      src={isActive ? frequentsActiveIcon : frequentsInactiveIcon}
      height="24"
      width="24"
      alt={personalIconAlt}
    />
  </div>
);

export const personalIcon = userInitials => isActive => (
  <div
    className={classNames({
      "text-in-shape": true,
      "shape-circle": true,
      "active-item": isActive
    })}
  >
    {userInitials}
  </div>
);

export const companyIcon = companyIconAlt => isActive => (
  <div className={classNames({ "active-item": isActive })}>
    <img
      src={isActive ? companyActiveIcon : companyInactiveIcon}
      height="24"
      width="24"
      alt={companyIconAlt}
    />
  </div>
);
