import { applyMiddleware, createStore, compose } from "redux";
import { filter } from "ramda";

import rootReducer from "../reducers/rootReducer";
import getInitialState from "./storeInitialState";

export const createReduxStore = ({ middlewares, isDevelopment }) => {
  let enhancers = applyMiddleware(
    ...filter(middleware => middleware !== undefined, middlewares)
  );

  if (isDevelopment) {
    const composeEnhancers =
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    enhancers = composeEnhancers(enhancers);
  }

  return createStore(rootReducer, getInitialState(), enhancers);
};
