import React from "react";
import PropTypes from "prop-types";
import { injectIntl, defineMessages } from "react-intl";
import { commonMessages } from "../../translations/commonMessages";
import ResponsiveBlock from "../ResponsiveBlock";
import { Button } from "@onelogin/react-components";

const messages = defineMessages({
  deleteSite: {
    id: "deleteSite",
    defaultMessage: `Delete site`
  },
  saving: {
    id: "saving",
    defaultMessage: `Saving…`
  },
  save: {
    id: "save",
    defaultMessage: `Save`
  }
});

export const AppFormButtons = ({
  showRemoveButton,
  onRemove,
  onSubmit,
  editable,
  saving,
  onCancel,
  intl
}) => {
  return (
    <div className="buttons-wrap">
      <ResponsiveBlock>
        <div className="buttons-wrap-inner">
          {showRemoveButton && (
            <div className="remove-btn-wrap">
              <Button onClick={onRemove} disabled={!editable}>
                {intl.formatMessage(messages.deleteSite)}
              </Button>
            </div>
          )}
          <div className="main-btn-wrap">
            <Button onClick={onCancel}>
              {intl.formatMessage(
                editable ? commonMessages.cancel : commonMessages.close
              )}
            </Button>
            <Button
              look="primary"
              type="submit"
              onClick={onSubmit}
              disabled={saving || !editable}
            >
              {intl.formatMessage(saving ? messages.saving : messages.save)}
            </Button>
          </div>
        </div>
      </ResponsiveBlock>
    </div>
  );
};

AppFormButtons.propTypes = {
  showRemoveButton: PropTypes.bool.isRequired,
  onRemove: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  saving: PropTypes.bool,
  onCancel: PropTypes.func.isRequired
};

export default injectIntl(AppFormButtons);
