import React, { useEffect } from "react";

import { OptAnonContent } from "./OptAnonContent";
import { bool } from "prop-types";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  overflow-y: scroll; 
`;

/**
 * Conditionally adds the OneTrust CDN script to the document
 * @param {boolean} cookieManagementEnabled - defines if CDN will be added
 * @returns {JSX.Element} a div wrapper containing the children passed
 * @example
 *   <OneTrust
 *     cookieManagementEnabled={company.cookieManagementEnabled}
 *   >
 *     <Children/>
 *   </OneTrust>
 * @type {{( props: { cookieManagementEnabled: boolean }): JSX.Element}}
 */
export const OneTrust = ({ cookieManagementEnabled, children }) => {
  useEffect(() => {
    const newScriptOne = document.createElement("script");
    newScriptOne.async = true;
    newScriptOne.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
    newScriptOne.type = "text/javascript";
    newScriptOne.id = "OneTrustCDN";
    newScriptOne.setAttribute(
      "data-domain-script",
      process.env.REACT_APP_ONETRUST_DATA_DOMAIN_SCRIPT_KEY
    );

    const newScriptTwo = document.createElement("script");
    newScriptTwo.async = true;
    newScriptTwo.id = "OneTrustWrapper";
    newScriptTwo.innerHTML = `function OptanonWrapper() {${OptAnonContent}}`;

    if (cookieManagementEnabled && !document.getElementById("OneTrustCDN")) {
      const metaEl = document.getElementsByTagName("meta")[0];
      metaEl.parentNode.insertBefore(newScriptTwo, metaEl);
      metaEl.parentNode.insertBefore(newScriptOne, newScriptTwo);
    } else if (
      !cookieManagementEnabled &&
      document.getElementById("OneTrustCDN")
    ) {
      const oldScriptOne = document.getElementById("OneTrustCDN");
      const oldScriptTwo = document.getElementById("OneTrustWrapper");
      oldScriptTwo.parentNode.removeChild(oldScriptOne);
      oldScriptTwo.parentNode.removeChild(oldScriptTwo);
    }
  }, [cookieManagementEnabled]);
  
  return <Wrapper data-name="OneTrust">{children}</Wrapper>;
};

OneTrust.propTypes = {
  cookieManagementEnabled: bool.isRequired
};

export default OneTrust;
