import PropTypes from "prop-types";

import styled, { css } from "styled-components";
import {
  NARROW_VIEW_LIMIT,
  MID_VIEW_LIMIT,
  WIDE_VIEW_LIMIT
} from "../constants/Env";

const DESKTOP_PADDING = 32;

//navbar breakpoints - in previous navbar there was a 32px padding, so remove it from the LIMIT (so it aligns with the rest of page content)
export const getBreakpoint = screenWidth => {
  if (screenWidth <= NARROW_VIEW_LIMIT) {
    return undefined;
  } else if (screenWidth <= MID_VIEW_LIMIT) {
    return NARROW_VIEW_LIMIT - DESKTOP_PADDING * 2;
  } else if (screenWidth <= WIDE_VIEW_LIMIT) {
    return MID_VIEW_LIMIT - DESKTOP_PADDING * 2;
  } else {
    return WIDE_VIEW_LIMIT - DESKTOP_PADDING * 2;
  }
};

const ResponsiveBlock = styled.div`
  margin: auto;
  width: ${WIDE_VIEW_LIMIT}px;
  position: relative;

  ${props =>
    props.noPaddings !== true &&
    css`
      padding: 0 ${DESKTOP_PADDING}px;
    `};

  @media screen and (max-width: ${WIDE_VIEW_LIMIT}px) {
    width: ${MID_VIEW_LIMIT}px;
  }

  @media screen and (max-width: ${MID_VIEW_LIMIT}px) {
    width: ${NARROW_VIEW_LIMIT}px;
  }

  @media screen and (max-width: ${NARROW_VIEW_LIMIT}px) {
    width: 100%;
    ${props =>
      props.noPaddings !== true &&
      css`
        padding: 0 20px;
      `};
  }
`;

ResponsiveBlock.propTypes = {
  noPaddings: PropTypes.bool
};

export default ResponsiveBlock;
