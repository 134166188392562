import React, { Component } from "react";
import PropTypes from "prop-types";
import { Loading } from "@onelogin/react-components";

import "./Mask.css";

class Mask extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hidden: true
    };
  }

  componentDidMount() {
    this.showTask = setTimeout(
      () => {
        this.setState({ hidden: false });
      },
      this.props.wait !== undefined ? this.props.wait : 500
    );
  }

  componentWillUnmount() {
    clearTimeout(this.showTask);
  }

  render() {
    const { msg } = this.props;

    return this.state.hidden ? null : (
      <div className="mask-container">
        <Loading msg={msg} />
      </div>
    );
  }
}

Mask.propTypes = {
  msg: PropTypes.string,
  wait: PropTypes.number
};

export default Mask;
