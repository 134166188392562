import React, { Component } from "react";
import PropTypes from "prop-types";
import { SearchField as Search } from "@onelogin/react-components";

import * as keyCodes from "../constants/keyCodes";

import "./SearchField.css";

export class SearchField extends Component {
  componentDidMount() {
    this.handleFocus(this.props.shouldHaveFocus);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchTerm !== this.props.searchTerm) {
      //mainly due to ESC which clears the searchTerm; onChange is not called in this case
    }

    if (prevProps.shouldHaveFocus !== this.props.shouldHaveFocus) {
      this.handleFocus(this.props.shouldHaveFocus);
    }
  }

  keyupHandler = event => {
    if (event.keyCode === keyCodes.ENTER_KEY_CODE) {
      this.props.onSearchEnter(event);
    }
  };

  keydownHandler = event => {
    if (event.keyCode === keyCodes.ESCAPE_KEY_CODE) {
      this.onSearchTermRemove();
    } else if (
      this.props.shouldHaveFocus &&
      event.keyCode === keyCodes.ARROW_DOWN_KEY_CODE
    ) {
      event.preventDefault();
      this.props.leaveSearchFieldIfPossible();
    }
  };

  onBlur = event => {
    this.props.onBlur(event);
  };

  onSearchTermRemove = () => {
    this.props.onChange("");
  };

  handleFocus = focus => {
    if (this.input && focus) {
      this.props.focusEl(this.input);
    }
  };

  onFocus = event => {
    if (event.target.selectionStart) {
      const index = this.input.value.length;
      this.input.setSelectionRange(index, index);
    }

    this.props.onFocus();
  };

  assignRef = input => {
    this.input = input;
  };

  onChange = event => {
    this.props.onChange(event.target.value);
  };

  render() {
    const { searchTerm, onClick, placeholder } = this.props;

    return (
      <div className="search-container" role="search">
        <Search
          id="search-input"
          data-testid="search-input"
          placeholder={placeholder}
          value={searchTerm}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onClick={onClick}
          onRemove={this.onSearchTermRemove}
          onBlur={this.onBlur}
          onKeyDown={
            !this.props.isTouchDevice ? this.keydownHandler : undefined
          }
          onKeyUp={!this.props.isTouchDevice ? this.keyupHandler : undefined}
          inputRef={this.assignRef}
          size="big"
          type="text"
          aria-label={placeholder}
        />
      </div>
    );
  }
}

SearchField.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  focusEl: PropTypes.func.isRequired,
  shouldHaveFocus: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
  isTouchDevice: PropTypes.bool.isRequired,
  leaveSearchFieldIfPossible: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onSearchEnter: PropTypes.func.isRequired
};

export default SearchField;
