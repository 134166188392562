import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter, Route } from "react-router-dom";

import AppForm from "../appForm/AppForm";

import { fetchApp } from "../../actions/appsActions";
import {
  savePersonalAppThenGotoApps,
  saveCompanyAppThenGotoApps
} from "../../actions/appEditActions";
import { resetForm } from "../../actions/appRemoveActions";
import { gotoApps } from "../../actions/navigatorActions";
import ConfirmAppRemove from "./ConfirmAppRemove";

import Mask from "../Mask";
import ErrorBoundary from "../ErrorBoundary";
import PortalRedirect from "../PortalRedirect";

import "./AppEdit.css";

export class AppEdit extends Component {
  componentDidMount() {
    this.props.fetchApp();
  }

  onSubmit = config => {
    return this.props.app.isPrivate
      ? this.props.onPersonalAppSubmit(config)
      : this.props.onCompanyAppSubmit(config);
  };

  showRemoveConfirm = () => {
    this.props.history.push(`${this.props.match.url}/delete`);
  };

  render() {
    const { app, editable, isAssumed, onCancel, children, redirectPath } =
      this.props;

    if (redirectPath) {
      return <PortalRedirect path={redirectPath} />;
    }

    if (!app || !app.id || (app.fetching && !app.fetched) || app.error) {
      return (
        <div className="app-edit">
          <Mask />
        </div>
      );
    }

    return (
      <ErrorBoundary>
        <div className="app-edit">
          <AppForm
            app={app}
            editable={editable && !isAssumed}
            onSubmit={this.onSubmit}
            onCancel={onCancel}
            onRemove={this.showRemoveConfirm}
          />
          {children}
        </div>

        <Route
          exact
          path={`${this.props.match.path}/delete`}
          component={ConfirmAppRemove}
        />
      </ErrorBoundary>
    );
  }

  componentWillUnmount() {
    this.props.resetForm();
  }
}

AppEdit.propTypes = {
  app: PropTypes.object,
  appId: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
  isAssumed: PropTypes.bool.isRequired,
  onPersonalAppSubmit: PropTypes.func.isRequired,
  onCompanyAppSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  children: PropTypes.object
};

const mapStateToProps = (state, ownProps) => {
  let appId = ownProps.match.params.appId,
    app = state.apps.entities[appId];

  return {
    app,
    children: ownProps.children,
    appId: ownProps.match.params.appId,
    editable: !!(app && app.isEditable),
    isAssumed: state.user.isAssumed,
    redirectPath: state.navigator.redirectPath
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchApp: () => dispatch(fetchApp(ownProps.match.params.appId)),
  onPersonalAppSubmit: formData =>
    dispatch(
      savePersonalAppThenGotoApps({
        ...formData,
        appId: ownProps.match.params.appId
      })
    ),
  onCompanyAppSubmit: formData =>
    dispatch(
      saveCompanyAppThenGotoApps({
        ...formData,
        appId: ownProps.match.params.appId
      })
    ),
  onCancel: () => dispatch(gotoApps()),
  resetForm: () => dispatch(resetForm())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppEdit)
);
