export const select = (connectors, topConnectors, searchTerm) => {
  if (searchTerm && searchTerm.length > 0) {
    const normKeywords = searchTerm.toLowerCase().split(" ");
    return connectors.filter(connector =>
      normKeywords.every(word => connector.searchKeywords.includes(word))
    );
  } else {
    return topConnectors;
  }
};
