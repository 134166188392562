export const APPS = "APPS";
export const BRANDING = "BRANDING";
export const TRANSLATIONS = "TRANSLATIONS";
export const LOCALE = "LOCALE";
export const ACCOUNT = "ACCOUNT";
export const PROCEED_UNSUPPORTED = "PROCEED_UNSUPPORTED";
export const NEXT_DEPRECATED_WARNING_DATE = "NEXT_DEPRECATED_WARNING_DATE";
export const FREQUENTS = "FREQUENTS";
export const CUSTOM_TAB = "CUSTOM_TAB";
export const USER_EMAIL = "USER_EMAIL";
export const DATA_VERSION = "DATA_VERSION";
export const DATA_CACHE = "DATA_CACHE";
