import React from "react";
import * as types from "../constants/ActionTypes";
import * as s3Api from "./../api/s3Api.js";
import * as formServiceApi from "./../api/formServiceApi.js";
import * as monorailApi from "./../api/monorailApi.js";
import * as store from "../storage/localStorage";
import { saveCache } from "../storage/localStorage";
import * as localStorageConstants from "../constants/localStorageConstants";
import { defineMessages, FormattedMessage } from "react-intl";
import { gotoApps } from "../actions/navigatorActions";
import { notify, notifyError } from "../actions/notificationsActions";

import { getCustomTabCacheState } from "../utils/storeInitialState";

const NOTIFICATION_TIME = 2000;

const messages = defineMessages({
  helpRequestFailed: {
    id: "helpRequestFailed",
    defaultMessage: `We couldn't submit your help request.`
  },

  helpRequestSuccessful: {
    id: "helpRequestSuccessful",
    defaultMessage: `Your support request has been sent to your OneLogin Admin.`
  }
});

export const fetchBranding = () => async (dispatch, getState) => {
  try {
    const data = await s3Api.fetchBranding();

    dispatch({
      type: types.FETCH_BRANDING_RECEIVED,
      data
    });

    const state = getState();
    if (state.featureFlags.persistStore) {
      saveCache({
        [localStorageConstants.BRANDING]: {
          data
        }
      });
    }
  } catch (error) {
    // TODO: here I believe we should report to airbrake
    dispatch({
      type: types.FETCH_BRANDING_ERROR
    });
  }
};

export const fetchAccount = () => (dispatch, getState) => {
  return formServiceApi.fetchAccount().then(
    data => {
      const state = getState();

      const dataWithSortedTabs = {
        data: {
          ...data,
          showBrowserWarnings: !data.ignoreUnsupportedBrowser,
          tabs: data.tabs.sort((appA, appB) => {
            return appA.position - appB.position;
          })
        }
      };

      const customTabCacheState = getCustomTabCacheState();

      dispatch({
        type: types.FETCH_ACCOUNT_RECEIVED,
        defaultTabId: state.account.defaultTabId,
        cachedTabId: customTabCacheState,
        ...dataWithSortedTabs
      });

      if (state.featureFlags.persistStore) {
        saveCache({
          [localStorageConstants.ACCOUNT]: { ...dataWithSortedTabs }
        });
      }
    },
    error => {
      dispatch({
        type: types.FETCH_ACCOUNT_ERROR
      });
    }
  );
};

export const submitHelpRequestThenGotoApps = helpFormInput => (
  dispatch,
  getState
) => {
  dispatch({
    type: types.HELP_SUBMIT_REQUEST
  });
  return monorailApi
    .postHelpRequest({
      body: {
        support_request: helpFormInput.supportRequest
      }
    })
    .then(
      result => {
        dispatch({
          type: types.HELP_SUBMIT_RECEIVED
        });
        dispatch(gotoApps());
        dispatch(
          notify({
            message: <FormattedMessage {...messages.helpRequestSuccessful} />,
            dismissDelay: NOTIFICATION_TIME
          })
        );
      },
      error => {
        dispatch(
          notifyError({
            message: error.normalized ? (
              error.normalized.body.message
            ) : (
              <FormattedMessage {...messages.helpRequestFailed} />
            )
          })
        );
      }
    );
};

export const extensionDetected = version => (dispatch, getState) => {
  dispatch({
    type: types.EXTENSION_DETECTED,
    version
  });

  return Promise.resolve(store.setExtensionDetected(true));
};

export const extensionNotDetected = () => (dispatch, getState) => {
  dispatch({
    type: types.EXTENSION_NOT_DETECTED
  });

  return Promise.resolve(store.setExtensionDetected(false));
};

export const extensionSkipInstall = () => (dispatch, getState) => {
  dispatch({
    type: types.EXTENSION_SKIP_INSTALL
  });

  return Promise.resolve(store.setExtensionInstallSkipped());
};

export const proceedWithUnsupportedBrowser = isDeprecatedBrowser => dispatch => {
  dispatch({
    type: types.PROCEED_UNSUPPORTED_BROWSER
  });

  return Promise.resolve(
    store.setProceedUnsupportedBrowser(isDeprecatedBrowser)
  );
};
