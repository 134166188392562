import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import { injectIntl } from "react-intl";
import LinkButton from "../LinkButton";
import "./FormFieldLabel.css";

export const FormFieldLabel = ({
  label,
  forId,
  action,
  isRequired,
  info,
  intl
}) => {
  const labelClasses = classNames({
    "form-field-label": true,
    required: isRequired
  });

  let actionContent;

  if (info) {
    actionContent = info;
  } else if (action && action.title) {
    actionContent = (
      <LinkButton
        tabIndex="-1"
        className="form-field-label-action-link"
        onClick={action.handler}
        text={action.title}
      />
    );
  }

  return (
    <div className="form-field-label-wrap">
      <label htmlFor={forId} className={labelClasses}>
        {label}
      </label>
      {actionContent ? (
        <div className="form-field-label-info">{actionContent}</div>
      ) : null}
    </div>
  );
};

FormFieldLabel.propTypes = {
  label: PropTypes.string.isRequired,
  forId: PropTypes.string,
  action: PropTypes.shape({
    title: PropTypes.string,
    handler: PropTypes.func
  }),
  isRequired: PropTypes.bool,
  info: PropTypes.string
};

export default injectIntl(FormFieldLabel);
