import { isNil } from "ramda";

import { getCache, getCustomTabId } from "../storage/localStorage";
import * as localStorageConstants from "../constants/localStorageConstants";
import { initialState as featureFlagsInitialState } from "../reducers/featureFlagsReducer";

const getInitialState = () => {
  if (!featureFlagsInitialState.persistStore) {
    return {};
  }

  return {
    intl: {
      defaultLocale: "en",
      locale: "en"
    }
  };
};

const readCachedData = key => {
  const data = getCache()[key];
  return isNil(data) ? {} : data;
};

export const getBrandingCacheState = () =>
  readCachedData(localStorageConstants.BRANDING);

export const getAppsCacheState = () =>
  readCachedData(localStorageConstants.APPS);

export const getFrequentsCacheState = () =>
  readCachedData(localStorageConstants.FREQUENTS);

export const getCustomTabCacheState = () => {
  const data = getCustomTabId();
  return isNil(data) ? null : data;
};

export const getAccountCacheState = () =>
  readCachedData(localStorageConstants.ACCOUNT);

export default getInitialState;
