import Bowser from "bowser";

import {
  MIN_SUPPORTED_BROWSERS,
  HTML5_COPY_SUPPORTED_BROWSERS,
  DEPRECATED_BROWSERS
} from "../constants/Env";
import * as types from "../constants/ActionTypes";
import {
  getProceedUnsupportedBrowser,
  getNextDeprecatedWarningDate
} from "../storage/localStorage";
import { getBreakpoint } from "../components/ResponsiveBlock";

const browser = Bowser.getParser(window.navigator.userAgent);
const browserInfo = browser.getBrowser();

//https://www.npmjs.com/package/bowser/v/1.9.4#bowserisunsupportedbrowserminversions-object--strictmode-boolean--ua-string--boolean
const isDeprecatedBrowser = !!browser.satisfies(DEPRECATED_BROWSERS);

const forceUnsupportedBrowserWarning = () => {
  const nextDeprecatedWarningDate = getNextDeprecatedWarningDate();

  return (
    isDeprecatedBrowser &&
    (nextDeprecatedWarningDate === null ||
      new Date(parseInt(nextDeprecatedWarningDate, 10)) <= new Date())
  );
};

const browserPlatform = browser.getPlatformType();

export const initialState = {
  isTouchDevice: !!(browserPlatform.mobile || browserPlatform.tablet),
  isSupportedBrowser: browser.satisfies(MIN_SUPPORTED_BROWSERS),
  copyUnsupportedBrowser: !browser.satisfies(HTML5_COPY_SUPPORTED_BROWSERS),
  isDeprecatedBrowser,
  myBrowser: `${browserInfo.name} ${
    browserInfo.version
      ? browserInfo.version.split(".", 2).join(".")
      : browserInfo.version
  }`,
  proceedUnsupportedBrowser:
    !forceUnsupportedBrowserWarning() && !!getProceedUnsupportedBrowser(),
  screenWidth: window.innerWidth,
  screenHeight: window.innerHeight,
  breakpoint: getBreakpoint(window.innerWidth)
};

function environment(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_ACCOUNT_RECEIVED:
      return {
        ...state,
        proceedUnsupportedBrowser:
          action.data.showBrowserWarnings === false
            ? true
            : initialState.proceedUnsupportedBrowser
      };

    case types.PROCEED_UNSUPPORTED_BROWSER:
      return {
        ...state,
        proceedUnsupportedBrowser: true
      };
    case types.SET_SCREEN_SIZE:
      return {
        ...state,
        screenWidth: action.width,
        screenHeight: action.height,
        breakpoint: getBreakpoint(action.width)
      };
    default:
      return state;
  }
}

export default environment;
