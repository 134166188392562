import * as types from "../constants/ActionTypes";

const initialState = {
  fatalErrorMessage: undefined
};

function fatalError(state = initialState, action) {
  switch (action.type) {
    case types.FATAL_NO_USER:
    case types.FATAL_NO_APPS:
      return {
        ...state,
        fatalErrorMessage: action.messageDescriptor
      };

    default:
      return state;
  }
}

export default fatalError;
