import React from "react";
import PropTypes from "prop-types";
import { injectIntl, defineMessages } from "react-intl";
import { Button, ModalDialog } from "@onelogin/react-components";

import { commonMessages } from "../../translations/commonMessages";
import AlertDialogContent from "../AlertDialogContent";

const messages = defineMessages({
  installExtensionPopup: {
    id: "installExtensionPopup",
    defaultMessage: `You need to install the OneLogin Browser Extension to use this site.`
  },
  extensionNotPermitted: {
    id: "extensionNotPermitted",
    defaultMessage: `This site requires the OneLogin browser extension. Please contact your administrator to make it available to you.`
  }
});

export const InstallExtensionDialog = ({
  onCancel,
  onInstall,
  installExtensionPermitted,
  intl
}) => (
  <ModalDialog onClose={onCancel}>
    <ModalDialog.Header>
      {intl.formatMessage(commonMessages.browserExtensionRequired)}
    </ModalDialog.Header>
    <ModalDialog.Content>
      <AlertDialogContent>
        {intl.formatMessage(
          installExtensionPermitted
            ? messages.installExtensionPopup
            : messages.extensionNotPermitted
        )}
      </AlertDialogContent>
    </ModalDialog.Content>
    <ModalDialog.Actions>
      <Button onClick={onCancel}>
        {intl.formatMessage(commonMessages.cancel)}
      </Button>
      {installExtensionPermitted && (
        <Button onClick={onInstall} look="primary">
          {intl.formatMessage(commonMessages.installNow)}
        </Button>
      )}
    </ModalDialog.Actions>
  </ModalDialog>
);

InstallExtensionDialog.propTypes = {
  installExtensionPermitted: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onInstall: PropTypes.func.isRequired
};

export default injectIntl(InstallExtensionDialog);
