import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl, defineMessages } from "react-intl";
import { Button, ModalDialog } from "@onelogin/react-components";

import {
  removeAppThenGotoApps,
  resetRemoveApp
} from "../../actions/appRemoveActions";
import { gotoAppEdit } from "../../actions/navigatorActions";
import ErrorBoundary from "../ErrorBoundary";
import AlertDialogContent from "../AlertDialogContent";
import { commonMessages } from "../../translations/commonMessages";

// TODO: this component is missing test:
// I was unable to write test for this component because of this issue
// https://gist.github.com/paulirish/1579671.
// I tried to bypass the issue using https://github.com/facebook/jest/issues/1644 but it didn't help.

const messages = defineMessages({
  deleteTitle: {
    id: "deleteTitle",
    defaultMessage: `Delete {appName}`
  },
  deleteMessage: {
    id: "deleteMessage",
    defaultMessage: `Do you want to delete {appName}?`
  },
  delete: {
    id: "delete",
    defaultMessage: `Delete`
  },
  deleting: {
    id: "deleting",
    defaultMessage: `Deleting…`
  }
});

export class ConfirmAppRemove extends Component {
  render() {
    const { error, appName, removing, onCancel, onRemove, intl } = this.props;

    return (
      <ErrorBoundary>
        <ModalDialog>
          <ModalDialog.Header>
            {intl.formatMessage(messages.deleteTitle, {
              appName
            })}
          </ModalDialog.Header>
          {error ? (
            <ModalDialog.Notification>
              {intl.formatMessage(error)}
            </ModalDialog.Notification>
          ) : (
            <></>
          )}
          <ModalDialog.Content>
            <AlertDialogContent>
              {intl.formatMessage(messages.deleteMessage, {
                appName
              })}
            </AlertDialogContent>
          </ModalDialog.Content>
          <ModalDialog.Actions>
            <Button onClick={onCancel}>
              {intl.formatMessage(commonMessages.cancel)}
            </Button>
            <Button onClick={onRemove} look="primary">
              {intl.formatMessage(
                removing ? messages.deleting : messages.delete
              )}
            </Button>
          </ModalDialog.Actions>
        </ModalDialog>
      </ErrorBoundary>
    );
  }

  componentWillUnmount() {
    this.props.onClose();
  }
}

ConfirmAppRemove.propTypes = {
  appName: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  error: PropTypes.object,
  removing: PropTypes.bool
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCancel: event => dispatch(gotoAppEdit(ownProps.match.params.appId)),
  onClose: event => dispatch(resetRemoveApp()),
  onRemove: event => {
    dispatch(removeAppThenGotoApps({ appId: ownProps.match.params.appId }));
  }
});

const mapStateToProps = (state, ownProps) => ({
  error: state.appRemove.error,
  removing: state.appRemove.removing,
  appName: state.apps.entities[ownProps.match.params.appId].name
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ConfirmAppRemove));
