import React from "react";
import PropTypes from "prop-types";

export const searchableAndSelectable = WrappedComponent => {
  const HocComponent = class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        searchShouldHaveFocus: false,
        selectedIndex: 0, //let's focus the first app in view; other option is to focus search box and have selectedIndex = -1
        searchTerm: "",
        keysDisabled: false
      };
      this.pageFocusEnabled = true;
    }

    onSearchTermChange = searchTerm => {
      this.setState({ searchTerm });
    };

    resetSearch = () => {
      this.setState({
        searchTerm: ""
      });
    };

    //TODO: is not used, but should be imo
    resetSelectedIndex = () => {
      this.setState({
        selectedIndex: 0,
        searchShouldHaveFocus: false
      });
    };

    onSelectedIndexChange = selectedIndex => {
      this.setState({ selectedIndex });
    };

    setSearchFocused = () => {
      this.setState({ searchShouldHaveFocus: true, selectedIndex: -1 });
    };

    blurSearchField = () => {
      this.setState({
        searchShouldHaveFocus: false
      });
    };

    selectFirstApp = () => {
      this.setState({
        selectedIndex: 0
      });
    };

    launchSelectedApp = isShift => {
      this.setState({
        doLaunchSelectedApp: true,
        isShift
      });
    };

    leaveSearchFieldIfPossible = () => {
      //this is just a flag saying: if there's an app on index 0, then focus it.
      //If there is no app, it cannot be focused, so search field stays focused
      this.selectFirstApp();
    };

    togglePageFocus = ({ focus }) => {
      this.pageFocusEnabled = focus;
      this.setState({
        keysDisabled: !focus
      });
    };

    focusEl = el => {
      if (this.pageFocusEnabled && el && el !== document.activeElement) {
        el.focus();
      }
    };

    render = () => (
      <WrappedComponent
        onSearchTermChange={this.onSearchTermChange}
        onSelectedIndexChange={this.onSelectedIndexChange}
        resetSearch={this.resetSearch}
        setSearchFocused={this.setSearchFocused}
        blurSearchField={this.blurSearchField}
        searchTerm={this.state.searchTerm}
        selectedIndex={this.state.selectedIndex}
        resetSelectedIndex={this.resetSelectedIndex}
        leaveSearchFieldIfPossible={this.leaveSearchFieldIfPossible}
        searchShouldHaveFocus={this.state.searchShouldHaveFocus}
        launchSelectedApp={this.launchSelectedApp}
        selectFirstApp={this.selectFirstApp}
        isShift={this.state.isShift}
        togglePageFocus={this.togglePageFocus}
        keysDisabled={this.state.keysDisabled}
        focusEl={this.focusEl}
        {...this.props}
      />
    );
  };

  HocComponent.propTypes = {
    isTouchDevice: PropTypes.bool.isRequired
  };

  return HocComponent;
};
