import { omit, type } from "ramda";
import { getUniqueId } from "./uniqueIdGenerator";
import { fetchApps } from "../actions/appsActions";

const CLEAR_CALLBACK_TIMEOUT = 5000;

export const getCallbackHandler = callback => extensionResponse => {
  let response;

  //due to firefox 'same origin policy', we need to pass JSON.stringified string between extension & portal
  if (extensionResponse) {
    if (type(extensionResponse.detail) === "String") {
      try {
        response = JSON.parse(extensionResponse.detail);
      } catch (err) {}
    } else if (type(extensionResponse.detail) === "Object") {
      //keep the previous functionality for extension sending object
      response = { ...extensionResponse.detail };
    }
  }

  callback(response);
};

export const postMessage = message => {
  let detail = {
    url: window.location.href
  };

  if (message.callback) {
    const messageId = getUniqueId();
    const callbackName = `onelogin.portalEventCallback.${messageId}`;

    detail = {
      ...detail,
      ...omit(["callback"], message),
      callbackName
    };

    const callbackHandler = getCallbackHandler(message.callback);
    window.addEventListener(callbackName, callbackHandler, false);

    setTimeout(() => {
      window.removeEventListener(callbackName, callbackHandler);
    }, CLEAR_CALLBACK_TIMEOUT);
  } else {
    detail = {
      ...detail,
      ...message
    };
  }

  window.dispatchEvent(
    new CustomEvent("onelogin.portalEvent", {
      detail
    })
  );
};

export const setNewAppsListener = ({ store, airbrake }) => {
  window.addEventListener("onelogin.addLogin", event => {
    store.dispatch(fetchApps(airbrake));
  });
};
