import React from "react";
import PropTypes from "prop-types";

import AppCell from "./AppCell";
import "./AppsList.css";

const AppsList = ({
  apps,
  selectedIndex,
  onEditClick,
  onAppClick,
  onSelectedIndexChange,
  focusEl,
  resolveAttentionNeeded,
  isTouchDevice
}) => {
  return (
    <div className="apps-list">
      {apps.map((app, index) => {
        return (
          <AppCell
            key={app.id}
            app={app}
            isSelected={index === selectedIndex}
            onAppClick={onAppClick}
            onEditClick={onEditClick}
            onFocus={() => {
              onSelectedIndexChange(index);
            }}
            focusEl={focusEl}
            needAttention={resolveAttentionNeeded(app)}
            isTouchDevice={isTouchDevice}
          />
        );
      })}
    </div>
  );
};

AppsList.propTypes = {
  apps: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedIndex: PropTypes.number,
  onEditClick: PropTypes.func.isRequired,
  onAppClick: PropTypes.func.isRequired,
  resolveAttentionNeeded: PropTypes.func.isRequired,
  onSelectedIndexChange: PropTypes.func.isRequired,
  isTouchDevice: PropTypes.bool.isRequired
};

AppsList.defaultProps = {
  selectedIndex: -1
};

export default AppsList;
