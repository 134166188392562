import React from "react";
import PropTypes from "prop-types";
import { injectIntl, defineMessages } from "react-intl";
import { Button, ModalDialog } from "@onelogin/react-components";
import styled from "styled-components";

import { commonMessages } from "../../translations/commonMessages";
import AlertDialogContent from "../AlertDialogContent";

const messages = defineMessages({
  unavailableOnMobile: {
    id: "unavailableOnMobile",
    defaultMessage: `You cannot use OneLogin to access this app using a mobile browser.`
  },
  useDesktop: {
    id: "useDesktop",
    defaultMessage: `Please use a desktop browser or the OneLogin mobile app.`
  }
});

const Instruction = styled.div`
  margin-top: 16px;
`;

export const NoExtensionWarning = ({ onOk, intl }) => (
  <ModalDialog>
    <ModalDialog.Header>
      {intl.formatMessage(commonMessages.browserExtensionRequired)}
    </ModalDialog.Header>
    <ModalDialog.Content>
      <AlertDialogContent>
        {intl.formatMessage(messages.unavailableOnMobile)}
      </AlertDialogContent>
      <Instruction>{intl.formatMessage(messages.useDesktop)}</Instruction>
    </ModalDialog.Content>
    <ModalDialog.Actions>
      <Button onClick={onOk} look="primary">
        {intl.formatMessage(commonMessages.ok)}
      </Button>
    </ModalDialog.Actions>
  </ModalDialog>
);

NoExtensionWarning.propTypes = {
  onOk: PropTypes.func.isRequired
};

export default injectIntl(NoExtensionWarning);
