import "react-app-polyfill/ie11"; //IE11: Promise, window.fetch, Object.assign, Symbol, Array.from (https://www.npmjs.com/package/react-app-polyfill)
import "core-js/es/symbol/iterator"; //IE11: Symbol.iterator; react-app-polyfill doesn't contain this one (https://github.com/zloirock/core-js/blob/v3.6.4/packages/core-js/modules/es.symbol.iterator.js)
import "core-js/features/promise"; //IE10 & IE11
import "core-js/features/map"; //IE10
import "core-js/features/weak-map"; //IE10
import "core-js/features/set"; //IE10
import "core-js/features/dom-collections/for-each"; //IE11 - for mutationsObserver used in navbar2
import "core-js/features/object/values"; //IE10 & IE11 - used in RC's Dropdown
import "core-js/features/object/entries"; //IE10 & IE11 - used by KY
import "core-js/features/reflect"; //IE10 & IE11 - used by KY
import "core-js/features/array/find"; //IE10 - used in RC's Dropdown
import "core-js/features/array/from"; //IE10 & IE11 - used in RC's Dropdown
import "core-js/features/symbol"; //IE10 & IE11 - used in react-youtube component (get extension screen)
import "core-js/es/string/starts-with"; //IE10 & IE11
import "core-js/es/array/includes"; //IE11
import "core-js/es/string/includes"; //IE11
import "raf/polyfill"; //IE10
import "./customEventPolyfill"; //IE11

import ErrorReporting from "./utils/errorReporting";
import { initPortal } from "./initPortal";
import { redirectIfNeeded } from "./utils/startupRedirect";
import isDevelopment from "./utils/environment";
import * as trackEvents from "./actionsLog";
import { loadAnalytics } from "./utils/googleAnalytics";
import { intlPolyfill } from "./translations";

//copy and pasted semantic-ui-css@2.3.3 so we can remove the dependency of semantic-ui stuff; but better is to use RC's checkbox including styles
//TODO: use RCs checkbox instead of this css file
import "./checkbox.min.css";
import "./index.css";

redirectIfNeeded({
  pathname: window.location.pathname,
  devHost: process.env.REACT_APP_DEV_HOST,
  isDevelopment
});

//support for older browsers (e.g. IE10), needs to be loaded before react-intl provider is initialized
intlPolyfill();

//catch errors and report to Airbrake
const errorReporting = new ErrorReporting({
  isEnabled: !isDevelopment,
  environment: process.env.REACT_APP_ENVIRONMENT || "development",
  projectId: process.env.REACT_APP_AIRBRAKE_PROJECT_ID,
  projectKey: process.env.REACT_APP_AIRBRAKE_PROJECT_KEY
});

initPortal({
  isDevelopment,
  errorReporting,
  dataVersion: process.env.REACT_APP_BUILD_TIME || "development"
});

loadAnalytics(
  process.env.REACT_APP_GA_MAIN_CODE,
  process.env.REACT_APP_GA_SEC_CODE
);
trackEvents.portalLoaded();
