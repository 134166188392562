import React, { Component } from "react";
import PropTypes from "prop-types";
import { defineMessages, injectIntl } from "react-intl";
import closeNotification from "../svg/close-notification.svg";
import styled from "styled-components";
import ResponsiveBlock from "./ResponsiveBlock";
import { commonMessages } from "../translations/commonMessages";

const messages = defineMessages({
  passwordExpiration: {
    id: "passwordExpiration",
    defaultMessage: `Your OneLogin password will expire {numberOfDays, plural,
                                  =0 {today}
                                  one {in {numberOfDays} day}
                                  other {in {numberOfDays} days}
                              }.`
  },
  changePasswordLink: {
    id: "changePasswordLink",
    defaultMessage: "Please change your password now."
  }
});

const NotificationContainer = styled.div`
  background-color: #016b91;
  min-height: 50px;
`;

const NotificationContent = styled.div`
  line-height: 1;
  display: flex;
  justify-content: space-between;
  color: white;
`;

const NotificationLink = styled.a`
  text-decoration: underline;
  color: #ffffff;
`;

NotificationLink.displayName = "NotificationLink";

const NotificationText = styled.div`
  align-self: center;
  font-size: 16px;
  padding: 17px 10px;
  text-align: center;
  width: calc(100% - 20px);
`;

NotificationText.displayName = "NotificationText";

const NotificationDismiss = styled.a`
  width: 20px;
  height: 20px;
  padding: 15px 0;
  cursor: pointer;
  display: block;
`;

NotificationDismiss.displayName = "NotificationDismiss";

export class PasswordNotificationBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isClosed: false
    };
  }

  onClearNotificationClick = () => {
    this.setState({
      isClosed: true
    });
  };

  render() {
    const { passwordExpirationDays, intl } = this.props;
    if (this.state.isClosed) {
      return null;
    }
    return (
      <NotificationContainer>
        <ResponsiveBlock>
          <NotificationContent>
            <NotificationText>
              {intl.formatMessage(messages.passwordExpiration, {
                numberOfDays: passwordExpirationDays
              })}
              &nbsp;
              <NotificationLink href="/profile2/password">
                {intl.formatMessage(messages.changePasswordLink)}
              </NotificationLink>
            </NotificationText>

            <NotificationDismiss>
              <img
                onClick={this.onClearNotificationClick}
                src={closeNotification}
                alt={intl.formatMessage(commonMessages.close)}
              />
            </NotificationDismiss>
          </NotificationContent>
        </ResponsiveBlock>
      </NotificationContainer>
    );
  }
}

PasswordNotificationBanner.propTypes = {
  passwordExpirationDays: PropTypes.number.isRequired
};

export default injectIntl(PasswordNotificationBanner);
