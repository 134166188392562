import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { injectIntl, defineMessages } from "react-intl";
import { COLORS } from "@onelogin/react-components";

import FormField from "../appForm/FormField";
import { commonMessages } from "../../translations/commonMessages";

const messages = defineMessages({
  personalPrivacyDescription: {
    id: "personalPrivacyDescription",
    defaultMessage: `Apps that you add are considered personal and are never shared with your employer.`
  },
  companyPrivacyDescription: {
    id: "companyPrivacyDescription",
    defaultMessage: `Company apps are added to the catalog and are eligible for your account admin to make available to other users in your organization.`
  }
});

const PERSONAL = "personal";
const COMPANY = "company";

const SelectorDescription = styled.div`
  color: ${COLORS.ONELOGIN_GRAY_COLORS.ONELOGIN_GRAY_MID};
  line-height: 1.5;

  margin-top: -24px;
  margin-bottom: 24px;
  padding-left: 26px;
`;

export class PrivacySelectorFormField extends Component {
  onPrivacyChange = event => {
    this.props.onChange({
      id: this.props.id,
      value: event.value === PERSONAL
    });
  };

  render() {
    const { intl, isPrivate } = this.props;

    //NOTE: selector is not designed to be readonly/disabled
    return (
      <>
        <FormField
          field={{
            name: "privacy",
            type: "radio",
            id: PERSONAL,
            label: intl.formatMessage(commonMessages.personal),

            value: PERSONAL,
            checked: isPrivate ? "checked" : "",
            onChange: this.onPrivacyChange,
            disabled: false,
            readOnly: false
          }}
          showCopy={false}
        />
        <SelectorDescription>
          {intl.formatMessage(messages.personalPrivacyDescription)}
        </SelectorDescription>
        <FormField
          field={{
            name: "privacy",
            type: "radio",
            id: COMPANY,
            label: intl.formatMessage(commonMessages.company),
            value: COMPANY,
            checked: isPrivate ? "" : "checked",
            onChange: this.onPrivacyChange,
            disabled: false,
            readOnly: false
          }}
          showCopy={false}
        />
        <SelectorDescription>
          {intl.formatMessage(messages.companyPrivacyDescription)}
        </SelectorDescription>
      </>
    );
  }
}

PrivacySelectorFormField.propTypes = {
  onChange: PropTypes.func.isRequired,
  isPrivate: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired
};

export default injectIntl(PrivacySelectorFormField);
