import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";

import { normalizeConnector } from "../utils/normalize/connectorNormalizer";
import * as types from "../constants/ActionTypes";
import { notifyError } from "../actions/notificationsActions";
import * as formServiceApi from "../api/formServiceApi";
import * as appServiceApi from "../api/appsServiceApi";

import { gotoAppStore } from "../actions/navigatorActions";

const messages = defineMessages({
  connectorLoadFailed: {
    id: "connectorLoadFailed",
    defaultMessage: `Loading of connector failed. Please try again later.`
  }
});

export const fetchConnector = connectorId => (dispatch, getState) => {
  //TODO: possible optimization here - connector probably don't change in time, so we can use cached connector here (confirm by tsoukup if we want this)
  //^ optimization probably not needed due to low frequency of adding new apps AND/OR adding of same apps twice or more times
  dispatch({
    type: types.FETCH_CONNECTOR_REQUEST,
    connectorId
  });

  return formServiceApi
    .fetchConnector({
      connectorId
    })
    .then(
      connector => {
        dispatch({
          type: types.FETCH_CONNECTOR_RECEIVED,
          connector: normalizeConnector(connector),
          connectorId
        });

        return connector;
      },
      error => {
        const { connectors } = getState();
        //there could be connector already in redux, so be silent in that case
        if (
          !connectors ||
          !connectors.entities[connectorId] ||
          !connectors.entities[connectorId].id
        ) {
          dispatch(
            notifyError({
              message: error.message ? (
                error.message
              ) : (
                <FormattedMessage {...messages.connectorLoadFailed} />
              )
            })
          );
          dispatch(gotoAppStore());
        }
      }
    );
};

export const fetchGenericConnector = async (dispatch, getState) => {
  try {
    const connector = await appServiceApi.fetchGenericConnector();
    dispatch({
      type: types.FETCH_GENERIC_CONNECTOR_RECEIVED,
      connector: normalizeConnector(connector)
    });
    return connector;
  } catch (error) {
    const { connectors } = getState();
    if (!connectors.genericConnector.id) {
      dispatch(
        notifyError({
          message: error.message ? (
            error.message
          ) : (
            <FormattedMessage {...messages.connectorLoadFailed} />
          )
        })
      );
      dispatch(gotoAppStore());
    }
  }
};
