import { defineMessages } from "react-intl";
import thunk from "redux-thunk";
import { isEmpty } from "ramda";

import * as types from "./constants/ActionTypes";
import { loadTranslations } from "./actions/translationActions";
import { fetchMe } from "./api/formServiceApi";
import * as eventsEmitter from "./utils/eventsEmitter";
import { getCache, saveCache, clearCache } from "./storage/localStorage";
import * as localStorageConstants from "./constants/localStorageConstants";
import { fetchBranding, fetchAccount } from "./actions/otherActions";

import { setupExtensionDetection } from "./utils/extensionDetection";

import { createReduxStore } from "./utils/store";
import {
  getBrandingCacheState,
  getAppsCacheState,
  getFrequentsCacheState,
  getCustomTabCacheState,
  getAccountCacheState
} from "./utils/storeInitialState";
import { renderPortal } from "./components/Portal";
import { setNewAppsListener } from "./utils/extensionMessenger";

const messages = defineMessages({
  failedToFetchUserInfo: {
    id: "failedToFetchUserInfo",
    defaultMessage: `We couldn’t load your user information.`
  }
});

export const initPortal = async ({
  isDevelopment,
  dataVersion,
  errorReporting
}) => {
  const store = createReduxStore({
    isDevelopment,
    middlewares: [errorReporting.getAirbrakeMiddleware(), thunk]
  });

  //render portal as soon as possible - show loading spinner
  renderPortal({
    store,
    airbrake: errorReporting.getAirbrakeClient()
  });

  setNewAppsListener({ store, airbrake: errorReporting.getAirbrakeClient() });
  setupExtensionDetection({ store });

  //fetchMe ensures redirection in case of invalid session
  let user = null;
  try {
    user = await fetchMe();
    // Some errors are caught but cause redirects, so they don't throw, they just return an empty user
    if (user === undefined) {
      return;
    }
  } catch (error) {
    store.dispatch({
      type: types.FATAL_NO_USER,
      messageDescriptor: messages.failedToFetchUserInfo
    });
    return;
  }

  const cache = getCache();
  if (
    user.email !== cache[localStorageConstants.USER_EMAIL] ||
    dataVersion !== cache[localStorageConstants.DATA_VERSION]
  ) {
    clearCache();
  }

  //eventsEmitter for OL extension
  eventsEmitter.userInitialized();

  //save user email and version for proper storeInitialState handling of next load
  saveCache({
    [localStorageConstants.USER_EMAIL]: user.email,
    [localStorageConstants.DATA_VERSION]: dataVersion
  });

  store.dispatch({
    type: types.FETCH_USER_RECEIVED,
    user
  });

  const brandingCacheState = getBrandingCacheState();
  if (!isEmpty(brandingCacheState)) {
    store.dispatch({
      type: types.FETCH_BRANDING_RECEIVED,
      ...brandingCacheState
    });
  }

  const appsCacheState = getAppsCacheState();
  if (!isEmpty(appsCacheState)) {
    store.dispatch({
      type: types.GET_APPS_DATA_RECEIVED,
      ...appsCacheState
    });
  }

  const frequentsCacheState = getFrequentsCacheState();
  if (!isEmpty(frequentsCacheState)) {
    store.dispatch({
      type: types.SET_FREQUENTS,
      ...frequentsCacheState
    });
  }

  const accountCacheState = getAccountCacheState();
  const customTabCacheState = getCustomTabCacheState();
  if (!isEmpty(accountCacheState)) {
    store.dispatch({
      type: types.FETCH_ACCOUNT_RECEIVED,
      defaultTabId: user.userSettings.default_tab_id,
      cachedTabId: customTabCacheState,
      isCacheLoad: true,
      ...accountCacheState
    });
  }

  store.dispatch(loadTranslations(user.locale));
  store.dispatch(fetchAccount());
  store.dispatch(fetchBranding());
};
