import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchConnector } from "../../actions/connectorsActions";

export class QueryConnectorData extends Component {
  componentDidMount() {
    this.props.dispatch(fetchConnector(this.props.connectorId));
  }

  render() {
    return null;
  }
}

QueryConnectorData.propTypes = {
  connectorId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired
};

export default connect()(QueryConnectorData);
