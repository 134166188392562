import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { findIndex } from "ramda";
import { MenuItem, LinkButton, Tooltip } from "@onelogin/react-components";

import styled from "styled-components";
import triangleDown from "../../../svg/triangle-down.svg";

import "./TabsSwitcher.css";

const MenuWrap = styled.div`
  background: url(${triangleDown}) right 12px top 27px no-repeat; //12px same as tab-item's right padding

  > div > div:nth-child(2) {
    margin-top: 3px;
  }
`;

const MenuItemsWrap = styled.div`
  max-width: 300px;
  min-width: 170px;
  margin: 6px 0;
  border-radius: 2px;
  max-height: ${props => props.maxHeight || "auto"};
  overflow: auto;
`;

const Spacer = styled.div`
  flex-grow: 1;
`;

const StyledMenuItem = styled(MenuItem)`
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TAB_ITEM_CLS = "tab-item";
const TOOLTIP_MARGIN = 16;
const TOOLTIP_SHADOW = 25;

const LinkButtonWithPaddings = styled(LinkButton)`
  padding: 13px 12px;
  color: ${({ disabled }) => disabled && "rgba(16, 16, 16, 0.3)"};
  &:disabled {
    color: ${({ disabled }) => disabled && "rgba(16, 16, 16, 0.3)"};
  }
`;

class TabsSwitcher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuItemOpen: undefined,
      tabsSwitcherBox: undefined
    };

    this.ref = React.createRef();
  }

  componentDidMount() {
    this.props.setRef && this.props.setRef(this.ref.current);

    this.setState(
      { tabsSwitcherBox: this.ref.current.getBoundingClientRect() },
      this.countMaxMenuHeight
    );
  }

  componentDidUpdate(props) {
    if (props.screenHeight !== this.props.screenHeight) {
      this.countMaxMenuHeight();
    }
  }

  countMaxMenuHeight = () => {
    //window height - switcher bottom - 16px (tooltip's margin to switcher) - 25px (tooltip's shadow size, so it looks always pretty)
    this.setState({
      maxHeight: `${
        this.props.screenHeight -
        this.state.tabsSwitcherBox.bottom -
        TOOLTIP_MARGIN -
        TOOLTIP_SHADOW
      }px`
    });
  };

  getActiveTabIndex = () => {
    const { tabs, activeTabType } = this.props;
    return findIndex(tab => tab.type === activeTabType, tabs);
  };

  render = () => {
    const { showMobileView, tabs, onTabChange, link } = this.props;

    const style = classNames({
      content: true,
      "tabs-view-switcher": true,
      "bottom-switcher": showMobileView,
      "top-switcher": !showMobileView
    });
    const activeTabIndex = this.getActiveTabIndex();

    return (
      <div className={style} ref={this.ref}>
        {tabs.map((tab, index) => {
          const isActive = activeTabIndex === index,
            onClick = () => onTabChange(tab.type, isActive),
            { menuItems } = tab,
            classes = classNames({
              [TAB_ITEM_CLS]: true,
              "active-item": isActive,
              "drop-down": isActive && menuItems && menuItems.length
            });

          if (showMobileView) {
            return (
              <div key={tab.type} className={classes} onClick={onClick}>
                {tab.icon(isActive)}
                <div className="filter-name">{tab.text}</div>
              </div>
            );
          } else {
            let item = (
              <button
                className="tab-item-content"
                onClick={onClick}
                tabIndex={tab.tabIndex || 0}
              >
                {tab.text}
              </button>
            );

            let content = item;

            if (isActive && menuItems && menuItems.length) {
              content = (
                <Tooltip
                  open={index === this.state.menuItemOpen}
                  dir="bottom"
                  lean="right"
                  onFocusLeave={() => {
                    this.setState({
                      menuItemOpen: undefined
                    });
                  }}
                  tooltip={() => (
                    <MenuItemsWrap maxHeight={this.state.maxHeight}>
                      {menuItems.map((menuItem, index) => {
                        return (
                          <StyledMenuItem
                            key={index}
                            onClick={event => {
                              menuItem.onClick(event);
                              this.setState({
                                menuItemOpen: undefined
                              });
                            }}
                            title={menuItem.text}
                          >
                            {menuItem.text}
                          </StyledMenuItem>
                        );
                      })}
                    </MenuItemsWrap>
                  )}
                >
                  <MenuWrap
                    onClick={() =>
                      this.setState({
                        menuItemOpen: index
                      })
                    }
                  >
                    {item}
                  </MenuWrap>
                </Tooltip>
              );
            }

            return (
              <div key={tab.type} className={classes}>
                {content}
              </div>
            );
          }
        })}
        {
          /* spacer helps to count proper max width of last tab-item div */
          !showMobileView && <Spacer />
        }
        {link && (
          <div className="tabs-link">
            <LinkButtonWithPaddings
              onClick={link.onClick}
              disabled={link.disabled}
            >
              {link.text}
            </LinkButtonWithPaddings>
          </div>
        )}
      </div>
    );
  };
}

TabsSwitcher.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      icon: PropTypes.func.isRequired,
      menuItems: PropTypes.arrayOf(
        PropTypes.shape({
          onClick: PropTypes.func.isRequired,
          text: PropTypes.string
        })
      )
    })
  ).isRequired,
  link: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    disabled: PropTypes.bool
  }),
  setRef: PropTypes.func,
  activeTabType: PropTypes.string.isRequired,
  showMobileView: PropTypes.bool.isRequired,
  onTabChange: PropTypes.func.isRequired,
  isTouchDevice: PropTypes.bool.isRequired,
  screenHeight: PropTypes.number
};

export default TabsSwitcher;
