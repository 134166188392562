import * as types from "../constants/ActionTypes";

const initialState = {
  removing: undefined,
  error: undefined
};

function appRemove(state = initialState, action) {
  switch (action.type) {
    case types.REMOVE_APP_REQUEST:
      return {
        ...state,
        removing: true,
        error: undefined
      };

    case types.REMOVE_APP_FINISHED:
      return {
        ...state,
        removing: false
      };

    case types.REMOVE_APP_ERROR:
      return {
        ...state,
        removing: false,
        error: action.messageDescriptor
      };

    case types.RESET_APP_REMOVE:
      return {
        ...initialState
      };

    default:
      return state;
  }
}

export default appRemove;
