import { Component } from "react";
import PropTypes from "prop-types";

import * as keyCodes from "../../constants/keyCodes";

class KeyboardNavigator extends Component {
  componentDidMount() {
    window.addEventListener("keydown", this._keydownHandler, true);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this._keydownHandler, true);
  }

  updateSelection = index => {
    this.props.onSelectionChange(index);
  };

  getRowForIndex(index, columns) {
    return Math.ceil((index + 1) / columns);
  }

  isPrintable(keyCode) {
    // it is only benevolent aproximation that is non-ASCI input friendly
    return (
      (keyCode >= 48 &&
        keyCode !== keyCodes.COMMAND_RIGTH_KEY_CODE &&
        keyCode !== keyCodes.COMMAND_LEFT_KEY_CODE) ||
      keyCode === keyCodes.BACKSPACE_KEY_CODE
    );
  }

  render() {
    return null;
  }

  _keydownHandler = event => {
    const {
      columnsCount,
      selectedIndex,
      setSearchFocused,
      handlerDisabled,
      itemsCount
    } = this.props;

    if (handlerDisabled) {
      return;
    }

    if (this.isPrintable(event.keyCode)) {
      setSearchFocused();
    }

    switch (event.keyCode) {
      case keyCodes.ESCAPE_KEY_CODE:
        this.props.setSearchFocused();
        this.props.resetSearch();
        break;

      case keyCodes.ARROW_DOWN_KEY_CODE: {
        const newPosition = selectedIndex + columnsCount;
        const lastIndex = itemsCount - 1;
        if (selectedIndex === -1) {
          this.updateSelection(0);
          event.preventDefault();
        } else if (newPosition < itemsCount) {
          this.updateSelection(newPosition);
          event.preventDefault();
        } else if (
          this.getRowForIndex(newPosition, columnsCount) >
          this.getRowForIndex(lastIndex, columnsCount)
        ) {
          // on last but one row, jump to very last item if no item is on last row in current column
          this.updateSelection(lastIndex);
          event.preventDefault();
        }
        break;
      }
      case keyCodes.ARROW_UP_KEY_CODE: {
        const newPosition = selectedIndex - columnsCount;
        if (newPosition >= 0) {
          this.updateSelection(newPosition);
          event.preventDefault();
        } else {
          setSearchFocused();
        }
        break;
      }
      case keyCodes.ARROW_LEFT_KEY_CODE: {
        if (selectedIndex % columnsCount !== 0) {
          // don't allow to jump from one side to another & don't allow init selection
          if (selectedIndex + 1 >= 0) {
            this.updateSelection(selectedIndex - 1);
          }
        }
        break;
      }
      case keyCodes.ARROW_RIGHT_KEY_CODE: {
        if ((selectedIndex + 1) % columnsCount !== 0) {
          // don't allow to jump from one side to another & don't allow init selection
          if (selectedIndex + 1 < itemsCount) {
            this.updateSelection(selectedIndex + 1);
          }
        }
        break;
      }

      default:
        break;
    }
  };
}

KeyboardNavigator.propTypes = {
  columnsCount: PropTypes.number.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  itemsCount: PropTypes.number.isRequired,
  handlerDisabled: PropTypes.bool,
  onSelectionChange: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired,
  setSearchFocused: PropTypes.func.isRequired
};

export default KeyboardNavigator;
