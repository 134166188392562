// https://en.wikipedia.org/wiki/Timeline_of_web_browsers
export const MIN_SUPPORTED_BROWSERS = {
  chrome: ">=54",
  firefox: ">=50",
  safari: ">=9.1",
  ie: ">=11",
  edge: ">=14.14393"
};

export const HTML5_COPY_SUPPORTED_BROWSERS = {
  ie: ">=9",
  edge: ">=12",
  firefox: ">=41",
  chrome: ">=42",
  safari: ">=10"
};

export const DEPRECATED_BROWSERS = {
  ie: "<=10"
};

export const NARROW_VIEW_LIMIT = 652;
export const MID_VIEW_LIMIT = 856;
export const WIDE_VIEW_LIMIT = 1060;
