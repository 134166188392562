import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { injectIntl } from "react-intl";
import { commonMessages } from "../../translations/commonMessages";
import { MISSING_CONNECTOR_ICON } from "../../constants/Apps";

import "./AppTileLogo.css";

class AppTileLogo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      useFallback: false
    };
  }

  render() {
    const { icons, appName, intl } = this.props,
      appImgStyle = classNames({
        "app-img": true,
        rectangle: icons.rectangle_264x100,
        square: !icons.rectangle_264x100
      });

    const iconSrc =
      icons.rectangle_264x100 ||
      icons.square_100x100 ||
      icons.square_48x48 ||
      icons.rectangle_2x ||
      icons.square_2x ||
      MISSING_CONNECTOR_ICON;

    if (this.state.useFallback || iconSrc.includes(MISSING_CONNECTOR_ICON)) {
      return (
        <div className="fallback-image">{appName.charAt(0).toUpperCase()}</div>
      );
    }

    return (
      <img
        className={appImgStyle}
        src={iconSrc}
        alt={intl.formatMessage(commonMessages.appIcon)}
        onError={() => {
          this.setState({ useFallback: true });
        }}
      />
    );
  }
}

AppTileLogo.propTypes = {
  icons: PropTypes.object.isRequired,
  appName: PropTypes.string.isRequired
};

export default injectIntl(AppTileLogo);
