import React from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import { resetRedirectPath } from "../actions/navigatorActions";

class PortalRedirect extends React.Component {
  componentDidMount() {
    this.props.dispatch(resetRedirectPath());
  }

  render() {
    const { path } = this.props;

    if (path) {
      return <Redirect to={path} />;
    }

    return null;
  }
}

PortalRedirect.propTypes = {
  path: PropTypes.string.isRequired
};

export default withRouter(connect()(PortalRedirect));
