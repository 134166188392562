export const IE_EXTENSION_DOWNLOAD_LINK =
  "https://app.onelogin.com/onelogin-downloads/extensions/ie/onelogin_ie.exe";
export const FIREFOX_EXTENSION_DOWNLOAD_LINK =
  "https://app.onelogin.com/onelogin-downloads/extensions/firefox/onelogin.xpi";
export const EDGE_EXTENSION_DOWNLOAD_LINK =
  "https://microsoftedge.microsoft.com/addons/detail/onelogin-for-edge/mpnjdddkcbccdhgahjdcjppglaecajdj";
export const SAFARI_EXTENSION_DOWNLOAD_LINK =
  "https://apps.apple.com/us/app/onelogin-for-safari/id1475824389?mt=12";
export const CHROME_EXTENSION_DOWNLOAD_LINK =
  "https://chrome.google.com/webstore/detail/onelogin-for-google-chrom/ioalpmibngobedobkmbhgmadaphocjdn";
